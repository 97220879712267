import { createContext } from 'react'
import io from 'socket.io-client'
import { extractHostFromUrl, extractPortFromUrl, extractProtocolFromUrl } from './utils'

const host = extractHostFromUrl(process.env.REACT_APP_API_URL!)
const port = extractPortFromUrl(process.env.REACT_APP_API_URL!)
const protocol = extractProtocolFromUrl(process.env.REACT_APP_API_URL!)
const socketProtocol = protocol === 'https:' ? 'wss' : 'ws'

export const socket = io(`${socketProtocol}://${host}:${port}`)
export const SocketContext = createContext(socket)
