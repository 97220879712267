import * as yup from 'yup'

export const validationSchema = yup.object().shape({
  room: yup
    .number()
    .required('Stateroom is required')
    .positive('Stateroom must be greater than zero')
    .typeError('Stateroom must be a number'),
  first_name: yup.string().required('First name is required'),
  last_name: yup.string().required('Last name is required'),
})

export const callbackValidationSchema = yup.object().shape({
  room: yup
    .number()
    .required('Stateroom is required')
    .positive('Stateroom must be greater than zero')
    .typeError('Stateroom must be a number'),
  first_name: yup.string().required('First name is required'),
  last_name: yup.string().required('Last name is required'),
  callback_date: yup
    .date()
    .required('Callback date is required')
    .typeError('A valid date is required'),
  callback_time: yup.string().required('Hour is required'),
  agent_id: yup.string().required('Agent is required'),
})
