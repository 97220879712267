import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import InputAdornment from '@mui/material/InputAdornment'
import { Box, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { IUpdateTargetFormProps } from './types'
import { validationSchema } from './validations'
import { ITargetPayload } from '../../types/cruises'
import { MAX_INTEGER } from '../../utils/constants'

const UpdateTargetForm: React.FC<IUpdateTargetFormProps> = ({
  isOpen,
  isLoading,
  initialValue,
  onAccept,
  onReject,
  title,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ITargetPayload>({
    resolver: yupResolver(validationSchema),
    defaultValues: { target: initialValue },
  })

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <Box component="form" onSubmit={handleSubmit(onAccept)} noValidate>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="normal"
            fullWidth
            id="target"
            label="Target"
            type="number"
            InputProps={{
              inputProps: { max: MAX_INTEGER, min: 1 },
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            error={!!errors.target}
            helperText={errors?.target?.message as string}
            {...register('target', { required: true })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onReject}>Cancel</Button>
          <LoadingButton type="submit" loading={isLoading}>
            Save
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default UpdateTargetForm
