import { useLazyGetMonthlyStatsQuery, useLazyGetStatsQuery } from '../../redux/api/stats'
import { useUpdateMonthlyTargetMutation, useUpdateTargetMutation } from '../../redux/api/cruises'
import { ITargetMonthlyPayload, ITargetPayload } from '../../types/cruises'

export const useDashboard = () => {
  const [lazyGetStats, { data, isFetching }] = useLazyGetStatsQuery()
  const [lazyGetMonthlyStats, { data: monthlyStats, isFetching: isMonthlyStatsFetching }] =
    useLazyGetMonthlyStatsQuery()
  const [putTarget] = useUpdateTargetMutation()
  const [putTargetMonthly] = useUpdateMonthlyTargetMutation()

  const updateTarget = async (
    values: ITargetPayload,
    onSaved?: (message: string) => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      const response = await putTarget(values).unwrap()
      if (onSaved) onSaved(response.message)
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  const updateTargetMonthly = async (
    values: ITargetMonthlyPayload,
    onSaved?: (message: string) => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      const response = await putTargetMonthly(values).unwrap()
      if (onSaved) onSaved(response.message)
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  return {
    statsData: data,
    isLoading: isFetching || isMonthlyStatsFetching,
    monthlyStatsData: monthlyStats,
    updateTarget,
    lazyGetStats,
    lazyGetMonthlyStats,
    updateTargetMonthly,
  }
}
