import * as yup from 'yup'

export const validationSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  userName: yup.string().required('User ID is required'),
  role: yup.string().required('Role is required'),
  password: yup.string().required('Password is required'),
})

export const editValidationSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  userName: yup.string().required('User ID is required'),
  role: yup.string().required('Role is required'),
})
