import Alert from '@mui/material/Alert'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@mui/material/IconButton'
import { useState } from 'react'
import { ITDCCardProps } from './types'
import { theme } from '../../styles/mui-theme'
import CardForm from '../CardForm'
import { IAppointmentCard } from '../../types/appointments'
import { ICardValues } from '../CardForm/types'
import { calculateDateToRender } from '../../utils/date'
import GridSpinner from '../GridSpinner'

const TDCCard: React.FC<ITDCCardProps> = ({
  cards,
  appointmentId,
  onEditCard,
  isLoading,
  portTimeZone,
}) => {
  const [isOpenCardForm, setIsOpenCardForm] = useState<boolean>(false)
  const [cardToEdit, setCardToEdit] = useState<ICardValues>()

  const onOpenFormCard = (card: IAppointmentCard) => {
    setIsOpenCardForm(true)
    if (card) {
      setCardToEdit({
        cardId: card.card_id,
        personName: card.person.name,
        personId: card.person.person_id,
        room: card.person.room || 0,
        appointmentId: card.appointment_id,
        status: card.card_status?.toLowerCase() || '',
        comment: card.card_comment?.trim() || '',
        transferredCard: !!card.transferred_to_folio_account,
      })
    }
  }

  const onSaveCardToEdit = (card: ICardValues) => {
    onEditCard(card)
    setIsOpenCardForm(false)
  }

  if (isLoading) return <GridSpinner />

  return cards.length === 0 ? (
    <Alert severity="info">No card for this appointment</Alert>
  ) : (
    <Paper elevation={3} style={{ marginBottom: 2, maxHeight: 240, overflow: 'auto' }}>
      {cards.map((card) => (
        <Card
          key={card.card_id}
          sx={{
            mb: 2,
            ml: 1,
            mr: 1,
            mt: 1,
            backgroundColor:
              appointmentId === card.appointment_id
                ? theme.palette.custom.backgroundGrey
                : theme.palette.custom.backgroundBlue,
          }}>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <Stack direction="row" justifyContent="center" alignItems="center" height={1}>
                  <Typography>{card.card_status}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={8} pl={0}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography>
                      <Typography fontWeight="bold" component="strong" fontSize={14}>
                        Guest:
                      </Typography>{' '}
                      {`${card.person?.name}`}
                    </Typography>
                    <Typography>
                      <Typography fontWeight="bold" component="strong" fontSize={14}>
                        Agent:
                      </Typography>{' '}
                      {`${card.agent?.first_name} ${card.agent?.last_name}`}
                    </Typography>
                    <Typography>
                      <Typography fontWeight="bold" component="strong" fontSize={14}>
                        Last update:
                      </Typography>{' '}
                      {calculateDateToRender(portTimeZone, card.card_status_date!)}
                    </Typography>
                    <Typography>
                      <Typography fontWeight="bold" component="strong" fontSize={14}>
                        Transferred to Folio Account:
                      </Typography>{' '}
                      {card.transferred_to_folio_account ? 'Yes' : 'No'}
                    </Typography>
                    {card.card_comment && (
                      <Typography>
                        <Typography fontWeight="bold" component="strong" fontSize={14}>
                          Comment:
                        </Typography>{' '}
                        {card.card_comment}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={2}>
                <Stack direction="row" justifyContent="center" alignItems="center" height={1}>
                  <IconButton onClick={() => onOpenFormCard(card)}>
                    <EditIcon />
                  </IconButton>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ))}
      {isOpenCardForm && (
        <CardForm
          isOpen={isOpenCardForm}
          onAccept={onSaveCardToEdit}
          onClose={() => setIsOpenCardForm(false)}
          card={cardToEdit}
        />
      )}
    </Paper>
  )
}

export default TDCCard
