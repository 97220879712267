import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import CloseIcon from '@mui/icons-material/Close'
import { Card, CardContent, DialogActions, Divider, Typography, Grid } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { useEffect } from 'react'
import { validationSchema } from './validation'
import { IAppointmentOtherForm, IOtherForm } from './types'
import { EOtherResult } from '../../types/appointments'
import { reasonOptions } from '../../utils/constants'

const resultArray = [
  { id: 1, name: 'Not Related to Future Cruise​', value: EOtherResult.NOTRELATED },
  {
    id: 2,
    name: 'General Future Cruise Question',
    value: EOtherResult.GENERAL_FUTURE_CRUISE_QUESTION,
  },
  { id: 3, name: 'Casino Program Question​', value: EOtherResult.CASINO_PROGRAM_QUESTION },
  { id: 4, name: 'Existing Quote Question​​', value: EOtherResult.EXISTING_QUOTE_QUESTION },
  {
    id: 5,
    name: 'Existing Booking – Made during the cruise​​',
    value: EOtherResult.EXISTING_BOOKING,
  },
  {
    id: 6,
    name: 'Existing Booking – Made prior to cruise​​​',
    value: EOtherResult.EXISTING_BOOKING_PRIOR_TO_CRUISE,
  },
]

const generalQuestionArray = [
  { id: 1, name: 'Book Later Question​', value: EOtherResult.BOOK_LATER_QUESTION },
  { id: 2, name: 'Promotion Question​​', value: EOtherResult.PROMOTION_QUESTION },
]

const existingBookingArray = [
  { id: 1, name: 'Confirmed Offered booking​​', value: EOtherResult.CONFIRMED_OFFERED_BOOKING },
  { id: 2, name: 'Question about booking​​', value: EOtherResult.QUESTION_ABOUT_BOOKING },
]

const AppointmentOtherForm: React.FC<IAppointmentOtherForm> = (props) => {
  const { isOpen, onClose, onSubmit, isLoading } = props
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    setValue,
    watch,
  } = useForm<IOtherForm>({
    resolver: yupResolver(validationSchema),
  })

  const result = watch('result')

  useEffect(() => {
    if (result !== 'GENERALFUTURECRUISEQUESTION') {
      setValue('generalQuestion', '')
      setValue('noBookReason', '')
    }
    if (result !== 'EXISTINGBOOKING') {
      setValue('existingBooking', '')
    }
  }, [result])

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <DialogContent>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography> Add a Other </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Divider sx={{ margin: '1rem 0' }} />
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="result"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        select
                        fullWidth
                        label="Result"
                        margin="normal"
                        error={!!errors.result}
                        helperText={errors?.result?.message as string}>
                        {resultArray.map((item) => (
                          <MenuItem key={`result-${item.id}`} value={item.value}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
                {result === 'GENERALFUTURECRUISEQUESTION' && (
                  <Grid item xs={12} sm={6}>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="generalQuestion"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          select
                          fullWidth
                          margin="normal"
                          error={!!errors.generalQuestion}
                          helperText={errors?.generalQuestion?.message as string}>
                          {generalQuestionArray.map((item) => (
                            <MenuItem key={`result-${item.id}`} value={item.value}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                )}
                {result === 'EXISTINGBOOKING' && (
                  <Grid item xs={12} sm={6}>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="existingBooking"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          select
                          fullWidth
                          margin="normal"
                          error={!!errors.existingBooking}
                          helperText={errors?.existingBooking?.message as string}>
                          {existingBookingArray.map((item) => (
                            <MenuItem key={`result-${item.id}`} value={item.value}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                )}
                {result === 'GENERALFUTURECRUISEQUESTION' && (
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="noBookReason"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          select
                          fullWidth
                          label="Why didn’t the guest book?"
                          margin="normal"
                          error={!!errors.noBookReason}
                          helperText={errors?.noBookReason?.message as string}>
                          {reasonOptions.map((item) => (
                            <MenuItem key={`result-${item}`} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextField
                    id="comment"
                    margin="normal"
                    fullWidth
                    multiline
                    rows={3}
                    label="Comment"
                    sx={{ width: '100%' }}
                    {...register('comment')}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <Stack
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            columnGap={1}
            sx={{ mb: '1.25rem', mr: 2 }}>
            <LoadingButton type="submit" loading={isLoading} color="success" variant="contained">
              Save
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
export default AppointmentOtherForm
