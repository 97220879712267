import * as yup from 'yup'

export const validationSchema = yup.object().shape({
  id: yup
    .number()
    .integer()
    .min(1, 'ID must be a positive integer')
    .required('ID is required')
    .typeError('ID must be a number'),
  name: yup.string().required('Name is required'),
})
