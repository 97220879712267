import { Box, Grid, MenuItem, Select, Stack, Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { useState } from 'react'
import { useConversionSettings } from './hook'
import { IChangeConfigPayload } from './types'
import SettingsLayout from '../../../components/SettingsLayout'
import DynamicDataGrid from '../../../components/DynamicDataGrid'
import { IGenericResponse } from '../../../types/generic'
import notify from '../../../utils/notify'
import ConfirmDialog from '../../../components/ConfirmDialog'

const SettingsConversion = () => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const [changeParams, setChangeParams] = useState<IChangeConfigPayload>()
  const { edit, refetchSettings, isLoadingSettings, qualifiedNonQualified, standardSupplemental } =
    useConversionSettings()

  const onUpdatedFlag = async (response: IGenericResponse) => {
    if (!response.success) {
      notify.error(response.message)
      return
    }

    await refetchSettings()
  }

  const handleOnChangeFlag = async (): Promise<void> => {
    setIsConfirmOpen(false)
    await edit({ id: changeParams?.elem.id!, flag: changeParams?.flag! }, onUpdatedFlag)
  }

  const confirmSettingChange = (params: IChangeConfigPayload) => {
    setChangeParams({ elem: params.elem, flag: params.flag })
    setIsConfirmOpen(true)
  }

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Appointment Type',
      flex: 1,
      cellClassName: 'conversion-cell',
      renderCell: ({ row }) => <Typography>{row.label}</Typography>,
    },
    {
      field: 'guest_category',
      headerName: 'Guest Category',
      flex: 1,
      cellClassName: 'conversion-cell',
      renderCell: ({ row }) => <Typography>{row.guest_category}</Typography>,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      cellClassName: 'conversion-cell',
      renderCell: ({ row }) => (
        <Typography sx={{ textTransform: 'lowercase' }}>{row.status}</Typography>
      ),
    },
    {
      field: 'actions',
      headerName: '',
      align: 'center',
      width: 200,
      sortable: false,
      renderCell: ({ row }) => (
        <Select
          size="small"
          sx={{ width: 150 }}
          value={row.flag}
          onChange={(e) => confirmSettingChange({ elem: row, flag: e.target.value })}>
          <MenuItem value="QUALIFIED">Qualified</MenuItem>
          <MenuItem value="NON-QUALIFIED">Non-Qualified</MenuItem>
        </Select>
      ),
    },
  ]

  const columnsStandard: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Appointment Type',
      flex: 1,
      cellClassName: 'conversion-cell',
      renderCell: ({ row }) => <Typography>{row.label}</Typography>,
    },
    {
      field: 'guest_category',
      headerName: 'Guest Category',
      flex: 1,
      cellClassName: 'conversion-cell',
      renderCell: ({ row }) => <Typography>{row.guest_category}</Typography>,
    },
    {
      field: 'actions',
      headerName: '',
      align: 'center',
      width: 200,
      sortable: false,
      renderCell: ({ row }) => (
        <Select
          size="small"
          sx={{ width: 150 }}
          value={row.flag}
          onChange={(e) => confirmSettingChange({ elem: row, flag: e.target.value })}>
          <MenuItem value="STANDARD">Standard</MenuItem>
          <MenuItem value="SUPPLEMENTAL">Supplemental</MenuItem>
        </Select>
      ),
    },
  ]

  return (
    <SettingsLayout>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack direction="row" sx={{ pb: 2 }}>
            <Typography variant="h4" color="primary" sx={{ display: { xs: 'none', md: 'flex' } }}>
              Qualified / Non-Qualified
            </Typography>
          </Stack>
          <Box>
            <DynamicDataGrid
              rows={qualifiedNonQualified}
              columns={columns}
              isLoading={isLoadingSettings}
              emptyMessage=""
              hideFooter
            />
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Stack direction="row" sx={{ pb: 2 }}>
            <Typography variant="h4" color="primary" sx={{ display: { xs: 'none', md: 'flex' } }}>
              Standard / Supplemental
            </Typography>
          </Stack>

          <Box>
            <DynamicDataGrid
              rows={standardSupplemental}
              columns={columnsStandard}
              isLoading={isLoadingSettings}
              emptyMessage=""
              hideFooter
            />
          </Box>
        </Grid>
      </Grid>
      <ConfirmDialog
        title="Conversion page set up configuration"
        message="Are you sure you want to change the settings for conversion calculation?"
        onAccept={handleOnChangeFlag}
        onReject={() => setIsConfirmOpen(false)}
        isOpen={isConfirmOpen}
      />
    </SettingsLayout>
  )
}

export default SettingsConversion
