import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from './baseQuery'
import { IEvidencesCountResponse, IEvidencesDownloadResponse } from '../../types/evidences'

export const evidencesApi = createApi({
  reducerPath: 'evidences',
  baseQuery,
  endpoints: (builder) => ({
    count: builder.query<IEvidencesCountResponse, void>({
      query: () => ({
        url: `/evidences/count`,
        method: 'GET',
      }),
    }),
    download: builder.query<IEvidencesDownloadResponse, void>({
      query: () => ({
        url: `/evidences/download`,
        method: 'GET',
      }),
    }),
  }),
})

export const { useCountQuery, useLazyDownloadQuery } = evidencesApi
