import TextField from '@mui/material/TextField'
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import CloseIcon from '@mui/icons-material/Close'
import { FC } from 'react'
import { ICardFormProps, ICardValues } from './types'
import { validationSchema } from './validations'
import GuestSelection from './GuestSelection'
import { getGuests } from '../../pages/Appointments/hook'

const CardForm: FC<ICardFormProps> = ({
  isOpen,
  onClose,
  onAccept,
  card: cardToEdit,
  room,
  current_cruise_id,
}) => {
  const defaultValues = cardToEdit
    ? {
        ...cardToEdit,
      }
    : {
        comment: '',
        transferredCard: false,
        status: '',
        room,
      }

  const {
    formState: { errors },
    control,
    register,
    reset,
    setValue,
    getValues,
    handleSubmit,
  } = useForm<ICardValues>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  })

  const { persons, isLoading } = getGuests(room || 0, current_cruise_id || 0)

  const onHandleSubmit = (values: ICardValues) => {
    onAccept(values)
  }

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm">
      <Box component="form" onSubmit={handleSubmit(onHandleSubmit)} noValidate>
        <DialogContent>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography>{`${cardToEdit ? 'Edit' : 'Add a '} Celebrity Visa Card`}</Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Divider sx={{ margin: '1rem 0' }} />
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  {cardToEdit ? (
                    <div>{cardToEdit.personName}</div>
                  ) : (
                    <GuestSelection
                      persons={persons}
                      isLoading={isLoading}
                      errors={errors}
                      register={register}
                      reset={reset}
                      setValue={setValue}
                      getValues={getValues}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    name="status"
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        margin="normal"
                        fullWidth
                        select
                        id="status"
                        label="Status"
                        type="text"
                        defaultValue=""
                        error={!!errors.status}
                        helperText={errors?.status?.message as string}>
                        <MenuItem key="Approved" value="approved">
                          Approved
                        </MenuItem>
                        <MenuItem key="Denied" value="bank denied">
                          Bank Denied
                        </MenuItem>
                        <MenuItem key="Pending" value="pending">
                          Pending
                        </MenuItem>
                        <MenuItem key="Guest Declined" value="guest declined">
                          Guest Declined
                        </MenuItem>
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="comment"
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        margin="normal"
                        fullWidth
                        multiline
                        id="comment"
                        label="Comment"
                        type="text"
                        rows={3}
                        error={!!errors.comment}
                        helperText={errors?.comment?.message as string}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="column" columnGap={1}>
                    <FormGroup>
                      <Controller
                        control={control}
                        name="transferredCard"
                        render={({ field: { value, ...field } }) => (
                          <FormControlLabel
                            control={<Checkbox id="transferredCard" checked={!!value} {...field} />}
                            label="Transferred Card to Folio Account"
                          />
                        )}
                      />
                    </FormGroup>
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </DialogContent>

        <DialogActions>
          <Stack
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            columnGap={1}
            sx={{ mb: '1.25rem', mr: 2 }}>
            <Button color="success" variant="contained" type="submit">
              Save
            </Button>
          </Stack>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default CardForm
