export const formatTime = (totalSeconds: number) => {
  const totalMinutes = Math.floor(totalSeconds / 60)

  const seconds = totalSeconds % 60
  const hours = Math.floor(totalMinutes / 60)
  const minutes = totalMinutes % 60
  let timeFormatted = ''
  if (hours > 0) {
    timeFormatted = `${hours >= 10 ? `${hours}` : `0${hours}`}:`
  }
  if (minutes > 0) {
    timeFormatted += `${minutes >= 10 ? `${minutes}` : `0${minutes}`}:`
  } else {
    timeFormatted += '00:'
  }
  timeFormatted += seconds >= 10 ? `${seconds}` : `0${seconds}`
  return timeFormatted
}

export const convertSecondsToMinutesSeconds = (totalSeconds: number) => {
  const minutes = Math.floor(totalSeconds / 60)

  const remainingSeconds = Math.floor(totalSeconds % 60)

  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`
}
