import { Alert } from '@mui/material'
import { StyledDataGrid } from './styles'
import { IDynamicDataGridProps } from './types'
import GridSpinner from '../GridSpinner'

const DynamicDataGrid: React.FC<IDynamicDataGridProps> = ({
  isLoading,
  columns,
  emptyMessage,
  rows,
  hideFooter = false,
  pageSize,
}) => {
  if (isLoading) return <GridSpinner />

  if (!rows || rows.length <= 0) return <Alert color="info">{emptyMessage}</Alert>

  return (
    <StyledDataGrid
      sx={{
        '& .product-cell': {
          whiteSpace: 'pre-wrap !important',
        },
        '& .conversion-cell': {
          whiteSpace: 'pre-wrap !important',
          minHeight: 70,
        },
      }}
      rows={rows || []}
      columns={columns}
      hideFooter={hideFooter}
      disableColumnFilter
      disableColumnMenu
      disableRowSelectionOnClick
      autoHeight
      getRowHeight={() => 'auto'}
      pageSizeOptions={pageSize ? [pageSize || 0, 100] : []}
    />
  )
}

export default DynamicDataGrid
