import { Alert, Button, TextField, Typography } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import DialogContent from '@mui/material/DialogContent'
import Stack from '@mui/material/Stack'
import { useEffect, useState } from 'react'
import moment from 'moment'
import { IMoveAppointmentModalProps } from './types'
import { useAppSelector } from '../../redux/hooks'
import { RootState } from '../../redux/store'
import { IPortSequence } from '../../pages/Settings/Voyages/types'

const MoveAppointmentModal: React.FC<IMoveAppointmentModalProps> = ({
  cruisesData,
  isOpen,
  onClose,
  appointment,
  onSelectedPort,
}) => {
  const activeCruiseId = useAppSelector((state: RootState) => state.auth.activeCruise?.cruise_id)
  const activeCruise = cruisesData.find((cruise) => cruise.cruise_id === activeCruiseId)

  const [selectedPort, setSelectedPort] = useState<IPortSequence | null>()
  const showWarning = appointment
    ? moment(appointment.queued_on, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD') !==
      moment(appointment.port!.arrival_date, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD')
    : false

  useEffect(() => {
    if (activeCruise && appointment) {
      activeCruise.port_sequence.forEach((port) => {
        if (port.cruise_port_id === appointment.port!.cruise_port_id) {
          setSelectedPort(port)
        }
      })
    }
  }, [activeCruise])

  return (
    <Dialog open={isOpen} fullWidth>
      <DialogContent>
        <Stack direction="column" justifyContent="center" paddingBottom={2}>
          <Typography>Move this appointment to port:</Typography>
          <Autocomplete
            sx={{ mt: 2, mb: 2 }}
            options={activeCruise?.port_sequence || []}
            onChange={(_, data) => {
              setSelectedPort(data)
            }}
            getOptionLabel={(port) =>
              `${port.name}${moment(port.arrival_date, 'YYYY-MM-DDTHH:mm:ss').format('MM/DD')} ${
                port.cruise_port_id === appointment.port!.cruise_port_id
                  ? '[Date of Appointment]'
                  : ''
              }`
            }
            value={selectedPort}
            renderOption={(props, option) => (
              <Box {...props} component="li" key={option.cruise_port_id} value={option.name}>
                {option.name}
                {moment(option.arrival_date, 'YYYY-MM-DDTHH:mm:ss').format('MM/DD')}
                {option.cruise_port_id === appointment.port!.cruise_port_id
                  ? '[Date of Appointment]'
                  : ''}
              </Box>
            )}
            renderInput={(params) => <TextField {...params} label="Ports" fullWidth />}
          />
          {showWarning && (
            <Alert severity="warning">
              {`Warning: The appointment date (${moment(
                appointment.queued_on,
                'YYYY-MM-DDTHH:mm:ss',
              ).format('MMM DD')}) is different to the port date (${
                appointment.port!.name
              } - ${moment(appointment.port!.arrival_date, 'YYYY-MM-DDTHH:mm:ss').format(
                'MMM DD',
              )})`}
            </Alert>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => onSelectedPort(appointment.appointment_id, selectedPort?.cruise_port_id)}>
          save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default MoveAppointmentModal
