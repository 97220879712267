import { IArchivePortPayload, IPortPayload } from './types'
import {
  useArchiveMutation,
  useCreateEditMutation,
  useGetQuery as useGetPorts,
  useRemoveMutation,
} from '../../../redux/api/ports'

export const usePorts = () => {
  const { isLoading, data: ports, refetch: refetchPorts } = useGetPorts()

  const [archivePort] = useArchiveMutation()
  const [removePort] = useRemoveMutation()
  const [createEditPort] = useCreateEditMutation()

  const createEdit = async (
    port: IPortPayload,
    onSaved?: () => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      await createEditPort(port).unwrap()
      if (onSaved) onSaved()
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  const archive = async (
    portArchive: IArchivePortPayload,
    onSaved?: (message?: string) => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      const response = await archivePort(portArchive).unwrap()
      if (onSaved) onSaved(response.message)
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  const remove = async (
    vesselId: number,
    onSaved?: (message?: string) => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      const response = await removePort(vesselId).unwrap()
      if (onSaved) onSaved(response.message)
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  return { isLoading, ports, archive, remove, refetchPorts, createEdit }
}
