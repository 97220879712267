import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const PrivateLayoutContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
}))

export const PublicLayoutContainer = styled('main')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  justifyContent: 'center',
  padding: theme.spacing(4),
  background: theme.palette.grey[100],
}))

export const Footer = styled('footer')(({ theme }) => ({
  backgroundColor: theme.palette.custom.main,
  color: theme.palette.background.default,
  textAlign: 'right',
  padding: theme.spacing(1),
  marginTop: 'auto',
}))
