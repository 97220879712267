import {
  useDeleteMutation,
  useGetQuery,
  useCreateMutation,
  useEditMutation,
  useGetEventsAndPresentersQuery,
} from '../../redux/api/events'
import { IEventPayload } from '../../types/events'
import { IGenericResponse } from '../../types/generic'

export const useEvents = () => {
  const { data, refetch, isFetching } = useGetQuery()
  const [deleteEvent] = useDeleteMutation()
  const [createEvent] = useCreateMutation()
  const [editEvent] = useEditMutation()

  const { data: eventsAndPresentersFromQuery } = useGetEventsAndPresentersQuery()

  const remove = async (
    eventId: number,
    onSaved?: (message?: string) => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      const response = await deleteEvent(eventId).unwrap()
      if (onSaved) onSaved(response.message)
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  const create = async (
    event: IEventPayload,
    onSaved?: (message: string) => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      const response: IGenericResponse = await createEvent(event).unwrap()
      if (onSaved) onSaved(response.message)
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  const edit = async (
    event: IEventPayload,
    onSaved?: (message: string) => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      const response: IGenericResponse = await editEvent(event).unwrap()
      if (onSaved) onSaved(response.message)
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  return {
    events: data,
    isFetching,
    destinations: eventsAndPresentersFromQuery?.destinations,
    users: eventsAndPresentersFromQuery?.users,
    remove,
    create,
    edit,
    refetch,
  }
}
