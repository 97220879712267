import * as yup from 'yup'

export const validationSchema = yup.object().shape({
  stateroom: yup
    .number()
    .required('Stateroom is required')
    .positive('Stateroom must be greater than zero')
    .typeError('Stateroom must be a number'),
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
})
