import moment from 'moment'
import numeral from 'numeral'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined'
import { Alert } from '@mui/material'
import { useParams } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import { useEffect } from 'react'
import { useReport } from './hook'
import { IReport } from './types'
import SettingsLayout from '../../../components/SettingsLayout'
import GridSpinner from '../../../components/GridSpinner'
import { headTableStyle } from '../../../styles/generic-styles'

const VoyageReport = () => {
  const { isLoading, data, onDownloadExcel, isFetchingDownload, refetchGetReport } = useReport()
  const { id } = useParams()
  useEffect(() => {
    refetchGetReport()
  }, [id])

  return (
    <SettingsLayout>
      <Stack direction="row" justifyContent="space-between" sx={{ pb: 2 }}>
        {!isLoading && !!data?.length && (
          <Box>
            <LoadingButton
              loading={isFetchingDownload}
              variant="contained"
              color="success"
              startIcon={<GetAppOutlinedIcon />}
              onClick={() => onDownloadExcel(Number(id))}>
              Export to Excel
            </LoadingButton>
          </Box>
        )}
        <Typography variant="h4" color="primary" sx={{ display: { xs: 'none', md: 'flex' } }}>
          Voyage Report
        </Typography>
      </Stack>

      {isLoading ? (
        <GridSpinner />
      ) : (
        <Paper elevation={3}>
          {data && data.length <= 0 ? (
            <Alert severity="info">No data has been generated for this voyage yet.</Alert>
          ) : (
            <TableContainer component={Paper} style={{ maxHeight: 'calc(100vh - 240px)' }}>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ ...headTableStyle, minWidth: 110 }}>SLOC CODE</TableCell>
                    <TableCell sx={{ ...headTableStyle, minWidth: 150 }}>NAME</TableCell>
                    <TableCell sx={{ ...headTableStyle, minWidth: 100, width: 100 }}>
                      ORIGINAL SAIL DATE
                    </TableCell>
                    <TableCell sx={{ ...headTableStyle, minWidth: 70 }} align="center">
                      ROOM
                    </TableCell>
                    <TableCell sx={{ ...headTableStyle, minWidth: 150 }}>GUEST NAME</TableCell>
                    <TableCell sx={{ ...headTableStyle, minWidth: 180 }}>VESSEL</TableCell>
                    <TableCell sx={{ ...headTableStyle, minWidth: 200 }}>PRODUCT</TableCell>
                    <TableCell sx={{ ...headTableStyle, minWidth: 180 }}>
                      QUOTED ST. CATEGORY
                    </TableCell>
                    <TableCell sx={{ ...headTableStyle, minWidth: 100, width: 100 }} align="center">
                      QUOTED SAIL DATE
                    </TableCell>
                    <TableCell sx={headTableStyle} align="center">
                      QUOTED $
                    </TableCell>
                    <TableCell sx={{ ...headTableStyle, minWidth: 180 }}>
                      BOOKING ST. CATEGORY
                    </TableCell>
                    <TableCell sx={{ ...headTableStyle, minWidth: 150 }}>BOOKING ORIGIN</TableCell>
                    <TableCell sx={{ ...headTableStyle, minWidth: 110 }}>BOOKING #</TableCell>
                    <TableCell sx={headTableStyle}>BOOKING $</TableCell>
                    <TableCell sx={{ ...headTableStyle, minWidth: 100, width: 100 }} align="center">
                      BOOKING SAIL DATE
                    </TableCell>
                    <TableCell sx={{ ...headTableStyle, minWidth: 170 }}>ACTIVITIES</TableCell>
                    <TableCell sx={{ ...headTableStyle, minWidth: 140, width: 140 }} align="center">
                      TOTAL VISIT TIME IN HH:MM
                    </TableCell>
                    <TableCell sx={{ ...headTableStyle, minWidth: 220 }}>
                      TIME OF ACTIVITY / PORT SEQUENCE
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!isLoading &&
                    data?.map((item: IReport, index: number) => (
                      <TableRow key={`row-${index}`}>
                        <TableCell>{item.sloc_code}</TableCell>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.original_sail_date}</TableCell>
                        <TableCell align="center">{item.room}</TableCell>
                        <TableCell>{item.guestName}</TableCell>
                        <TableCell>{item.vessel}</TableCell>
                        <TableCell>{item.product}</TableCell>
                        <TableCell>{item.quoted_stateroom_category || ''}</TableCell>
                        <TableCell align="center">
                          {item.quoted_sail_date
                            ? moment.utc(item.quoted_sail_date).format('MM/DD/YYYY')
                            : ''}
                        </TableCell>
                        <TableCell align="right">
                          {item.quoted_amount ? numeral(item.quoted_amount).format('$0,0.00') : ''}
                        </TableCell>
                        <TableCell>{item.booking_stateroom_category || ''}</TableCell>
                        <TableCell>{item.booking_origin || ''}</TableCell>
                        <TableCell>{item.booking_number || ''}</TableCell>
                        <TableCell align="right">
                          {item.booking_amount
                            ? numeral(item.booking_amount).format('$0,0.00')
                            : ''}
                        </TableCell>
                        <TableCell align="center">{item.booking_saildate || ''}</TableCell>
                        <TableCell sx={{ textTransform: 'capitalize' }}>
                          {item.activities.toLowerCase()}
                        </TableCell>
                        <TableCell align="center">
                          {moment
                            .utc(moment.duration(item.visitTime, 'minutes').asMilliseconds())
                            .format('HH:mm')}
                        </TableCell>
                        <TableCell>{item.port_date_name}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Paper>
      )}
    </SettingsLayout>
  )
}

export default VoyageReport
