import { useEffect, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Link,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { theme } from '../../styles/mui-theme'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { useGetWizardQuery } from '../../redux/api/utils'
import { setWizard } from '../../redux/features/auth'
import { IWizardResponse } from '../../types/utils'
import { EUserRole } from '../../types/users'

const Wizard: React.FC = () => {
  const dispatch = useAppDispatch()
  const loggedUser = useAppSelector((state) => state.auth.loggedUser)
  const [isExpanded, setIsExpanded] = useState(true)
  const { data } = useGetWizardQuery(undefined, {
    skip: loggedUser?.role === EUserRole.AGENT,
  })

  useEffect(() => {
    dispatch(setWizard(data))
  }, [data])

  // Do not display if data hasn't been returned or all values are true
  if (!data || Object.values(data).every((value) => value)) return null

  const step = Object.keys(data!).findIndex((item) => !data[item as keyof IWizardResponse])

  const steps = [
    {
      label: 'Create your first voyage',
      url: '/settings/voyages',
      isCompleted: data.areCruisesCreated,
    },
    {
      label: 'Create your first agent',
      url: '/settings/users',
      isCompleted: data.areAgentsCreated,
    },
    {
      label: 'Activate a voyage',
      url: '/settings/voyages',
      isCompleted: data.isThereActiveCruise,
    },
    {
      label: 'Assign agents to the voyage',
      url: '/settings/voyages',
      isCompleted: data.areAgentsAssigned,
    },
  ]

  return (
    <Box paddingY={2} maxWidth={theme.spacing(130)} marginX="auto">
      <Accordion
        elevation={5}
        disableGutters
        expanded={isExpanded}
        onChange={() => setIsExpanded(!isExpanded)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.secondary.contrastText }} />}
          id="wizard"
          sx={{
            background: theme.palette.secondary.main,
            borderBottom: `solid 1px ${theme.palette.divider}`,
            '.MuiAccordionSummary-content': { margin: 0 },
          }}>
          <InfoOutlinedIcon sx={{ color: theme.palette.secondary.contrastText }} />
          <Typography variant="button" marginLeft={1} color={theme.palette.secondary.contrastText}>
            Setup: {step} out of 4 steps completed
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingY: 4, background: theme.palette.grey[100] }}>
          <Stepper alternativeLabel activeStep={steps.findIndex((item) => !item.isCompleted)}>
            {steps.map(({ label, url, isCompleted }) => (
              <Step
                key={label}
                completed={isCompleted}
                sx={{
                  '& .MuiStepLabel-root .Mui-completed': {
                    color: theme.palette.success.main,
                  },
                }}>
                <StepLabel>
                  <Typography variant="button">
                    <Link href={url} sx={{ textDecoration: 'none' }}>
                      {label}
                    </Link>
                  </Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default Wizard
