import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined'
import { Alert } from '@mui/material'
import { useParams } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import { useEffect } from 'react'
import { useCardReport } from './hook'
import { ICardReport } from './types'
import SettingsLayout from '../../../components/SettingsLayout'
import GridSpinner from '../../../components/GridSpinner'
import { headTableStyle } from '../../../styles/generic-styles'

const VoyageCardReport = () => {
  const { isLoading, data, onDownloadExcel, isFetchingDownload, refetchGetReport } = useCardReport()
  const { id } = useParams()
  useEffect(() => {
    refetchGetReport()
  }, [id])

  return (
    <SettingsLayout>
      <Stack direction="row" justifyContent="space-between" sx={{ pb: 2 }}>
        {!isLoading && !!data?.length && (
          <Box>
            <LoadingButton
              loading={isFetchingDownload}
              variant="contained"
              color="success"
              startIcon={<GetAppOutlinedIcon />}
              onClick={() => onDownloadExcel(Number(id))}>
              Export to Excel
            </LoadingButton>
          </Box>
        )}
        <Typography variant="h4" color="primary" sx={{ display: { xs: 'none', md: 'flex' } }}>
          Royal Visa Card Report
        </Typography>
      </Stack>

      {isLoading ? (
        <GridSpinner />
      ) : (
        <Paper elevation={3}>
          {data && data.length <= 0 ? (
            <Alert severity="info">No cards data has been generated for this voyage yet.</Alert>
          ) : (
            <TableContainer component={Paper} style={{ maxHeight: 'calc(100vh - 240px)' }}>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ ...headTableStyle, minWidth: 150 }} align="center">
                      AGENT
                    </TableCell>
                    <TableCell sx={{ ...headTableStyle, minWidth: 180 }} align="center">
                      APPLICATIONS
                    </TableCell>
                    <TableCell sx={{ ...headTableStyle, minWidth: 150 }} align="center">
                      APPROVALS
                    </TableCell>
                    <TableCell sx={{ ...headTableStyle, minWidth: 150 }} align="center">
                      DENIED
                    </TableCell>
                    <TableCell sx={headTableStyle} align="center">
                      TRANSFERRED TO FOLIO ACCOUNT
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!isLoading &&
                    data?.map((item: ICardReport, index: number) => (
                      <TableRow key={`row-${index}`}>
                        <TableCell align="center">{item.agentName}</TableCell>
                        <TableCell align="center">{item.cardApplications || ''}</TableCell>
                        <TableCell align="center">{item.cardsApproved || ''}</TableCell>
                        <TableCell align="center">{item.cardsDenied || ''}</TableCell>
                        <TableCell align="center">{item.cardsTransferredToFolio || ''}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Paper>
      )}
    </SettingsLayout>
  )
}

export default VoyageCardReport
