import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from './baseQuery'
import { IGenericResponse } from '../../types/generic'
import { ICardPayload } from '../../types/cards'

export const cardsApi = createApi({
  reducerPath: 'cardsApi',
  baseQuery,
  endpoints: (builder) => ({
    createCard: builder.mutation<IGenericResponse, ICardPayload>({
      query: ({ appointmentId, ...rest }) => ({
        url: `/cards/${appointmentId}`,
        method: 'POST',
        body: rest,
      }),
    }),
    editCard: builder.mutation<IGenericResponse, ICardPayload>({
      query: ({ cardId, ...rest }) => ({
        url: `/cards/${cardId}`,
        method: 'PUT',
        body: rest,
      }),
    }),
    changeCardStatus: builder.mutation<IGenericResponse, ICardPayload>({
      query: ({ cardId, status }) => ({
        url: `/cards/${cardId}/status/${status}`,
        method: 'PUT',
      }),
    }),
    transferToFolio: builder.mutation<IGenericResponse, ICardPayload>({
      query: ({ cardId, transferredCard }) => ({
        url: `/cards/${cardId}/transferToFolio/${transferredCard}`,
        method: 'PUT',
      }),
    }),
  }),
})

export const {
  useCreateCardMutation,
  useEditCardMutation,
  useChangeCardStatusMutation,
  useTransferToFolioMutation,
} = cardsApi
