import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from './baseQuery'
import {
  IPersonsByRoomResponse,
  IPersonUpdatePayload,
  IGetPersonExtraInfoRecordsResponse,
  IDeletePersonExtraInfoPayload,
} from '../../types/persons'
import { IGenericResponse } from '../../types/generic'

export const personsApi = createApi({
  reducerPath: 'personsApi',
  baseQuery,
  endpoints: (builder) => ({
    getPersonsByRoom: builder.query<IPersonsByRoomResponse[], number>({
      query: (stateroom) => ({
        url: `/persons/${stateroom}/guests`,
        method: 'GET',
      }),
    }),
    update: builder.mutation<IGenericResponse, IPersonUpdatePayload>({
      query: ({ personId, ...rest }) => ({
        url: `/persons/${personId}`,
        method: 'PUT',
        body: rest,
      }),
    }),
    getExtraInfoByCruise: builder.query<IGetPersonExtraInfoRecordsResponse, number>({
      query: (id) => ({
        url: `/persons/extraInfo/${id}`,
        method: 'GET',
      }),
    }),
    uploadExtraInfo: builder.mutation<IGenericResponse, { file: (File | string)[] }>({
      query: (payload) => ({
        url: `/persons/extraInfo`,
        method: 'POST',
        body: payload,
      }),
    }),
    removeExtraInfo: builder.mutation<IGenericResponse, IDeletePersonExtraInfoPayload>({
      query: (payload) => ({
        url: `/persons/extraInfo`,
        method: 'DELETE',
        body: payload,
      }),
    }),
  }),
})

export const {
  useGetPersonsByRoomQuery,
  useUpdateMutation,
  useLazyGetPersonsByRoomQuery,
  useGetExtraInfoByCruiseQuery,
  useUploadExtraInfoMutation,
  useRemoveExtraInfoMutation,
} = personsApi
