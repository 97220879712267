import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useFieldArray, useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import moment from 'moment'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import { ICallbackTimeLimitsFormData, ICallbackTimeLimitsFormProps } from './types'
import NestedCallbackTimesForm from './NestedCallbackTimesForm'
import { callbackTimeLimitsFormValidationSchema } from './validation'
import { headTableStyle } from '../../styles/generic-styles'

const CallbackTimeLimitsForm: React.FC<ICallbackTimeLimitsFormProps> = ({
  ports,
  callbacks,
  isLoading,
  onSubmit,
  setStateCallbacks,
  goBack,
}) => {
  const { control, formState, setValue, handleSubmit, getValues } =
    useForm<ICallbackTimeLimitsFormData>({
      defaultValues: {
        callbacks,
      },
      resolver: yupResolver(callbackTimeLimitsFormValidationSchema),
    })

  const { fields } = useFieldArray({
    control,
    name: 'callbacks',
  })

  const setTimes = (
    index: number,
    itemIndex: number,
    openHourValue: string,
    closeHourValue: string,
  ) => {
    setStateCallbacks((oldData) => ({ ...oldData, callbacks: getValues().callbacks }))
    setValue(`callbacks.${index}.hours.${itemIndex}.openHour`, openHourValue, {
      shouldValidate: true,
    })
    setValue(`callbacks.${index}.hours.${itemIndex}.closeHour`, closeHourValue, {
      shouldValidate: true,
    })
  }

  const setCallbacksPerHour = (index: number, itemIndex: number, value: number) => {
    setStateCallbacks((oldData) => ({ ...oldData, callbacks: getValues().callbacks }))
    setValue(`callbacks.${index}.hours.${itemIndex}.callbacksPerHour`, value, {
      shouldValidate: true,
    })
  }

  const [hoursOptions, setHoursOptions] = useState<{ openHour: string; closeHour: string }[]>([])

  useEffect(() => {
    const array: { openHour: string; closeHour: string }[] = []

    new Array(24).fill('').forEach((_, index) => {
      array.push({
        openHour: moment({ hour: index }).format('hh:mm A'),
        closeHour: moment({ hour: index + 1 }).format('hh:mm A'),
      })
      if (index !== 23) {
        array.push({
          openHour: moment({ hour: index, minute: 15 }).format('hh:mm A'),
          closeHour: moment({ hour: index + 1, minute: 15 }).format('hh:mm A'),
        })
        array.push({
          openHour: moment({ hour: index, minute: 30 }).format('hh:mm A'),
          closeHour: moment({ hour: index + 1, minute: 30 }).format('hh:mm A'),
        })
        array.push({
          openHour: moment({ hour: index, minute: 45 }).format('hh:mm A'),
          closeHour: moment({ hour: index + 1, minute: 45 }).format('hh:mm A'),
        })
      } else {
        array.push({
          openHour: moment({ hour: index, minute: 15 }).format('hh:mm A'),
          closeHour: moment({ hour: 0, minute: 15 }).format('hh:mm A'),
        })
        array.push({
          openHour: moment({ hour: index, minute: 30 }).format('hh:mm A'),
          closeHour: moment({ hour: 0, minute: 30 }).format('hh:mm A'),
        })
        array.push({
          openHour: moment({ hour: index, minute: 45 }).format('hh:mm A'),
          closeHour: moment({ hour: 0, minute: 45 }).format('hh:mm A'),
        })
      }
    })

    setHoursOptions(array)
  }, [])

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
      <TableContainer sx={{ marginBottom: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={headTableStyle}>Date</TableCell>
              <TableCell align="center" sx={headTableStyle}>
                Port
              </TableCell>
              <TableCell colSpan={2} align="center" sx={headTableStyle}>
                Hour - Max. Callbacks
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((item, index) => (
              <TableRow key={item.id}>
                <TableCell>{moment(item.date).format('MM/DD/YYYY')}</TableCell>
                <TableCell align="center">
                  {ports.length > 0 && item.port
                    ? ports.filter((port) => port.port_id === item.port)[0].name
                    : ''}
                </TableCell>
                <NestedCallbackTimesForm
                  index={index}
                  control={control}
                  hoursOptions={hoursOptions}
                  formState={formState}
                  setTimes={setTimes}
                  setCallbacksPerHour={setCallbacksPerHour}
                />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <LoadingButton
        loading={isLoading}
        type="submit"
        variant="contained"
        sx={{ alignSelf: 'flex-start', marginRight: 1 }}>
        CONTINUE
      </LoadingButton>
      <Button type="button" onClick={goBack}>
        BACK
      </Button>
    </Box>
  )
}

export default CallbackTimeLimitsForm
