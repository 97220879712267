import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from './baseQuery'
import { IStatsResponse, IStatsPayload, IMonthlyStatsResponse } from '../../types/stats'

export const statsApi = createApi({
  reducerPath: 'statsApi',
  baseQuery,
  endpoints: (builder) => ({
    getStats: builder.query<IStatsResponse, IStatsPayload | void>({
      query: (payload) => ({
        url: `/stats${payload ? `?year=${payload.year}&month=${payload.month}` : ''}`,
        method: 'GET',
      }),
    }),
    getMonthlyStats: builder.query<IMonthlyStatsResponse, IStatsPayload | void>({
      query: (payload) => ({
        url: `/stats/monthly${payload ? `?year=${payload.year}&month=${payload.month}` : ''}`,
        method: 'GET',
      }),
    }),
  }),
})

export const { useLazyGetStatsQuery, useLazyGetMonthlyStatsQuery } = statsApi
