import { styled } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { theme } from '../../styles/mui-theme'

export const StyledDataGrid = styled(DataGrid)(() => ({
  backgroundColor: theme.palette.primary.contrastText,
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  '& .MuiDataGrid-container--top [role=row]': {
    background: 'inherit',
  },
  '& .MuiDataGrid-cell': {
    display: 'flex',
    alignItems: 'center',
  },
  '& .MuiDataGrid-columnHeaders svg': {
    color: 'white',
  },
  '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
}))
