export interface IConfirmDialogProps {
  title: string
  message: string
  onAccept: () => void
}

export interface IInitiativePayload {
  id: number
  type: string
  product: string
  shipId: number
  date: string
  isEdit: boolean
  cruiseId: number
}

export enum EConfirmInitiativeType {
  REMOVE_INITIATIVE,
}
