import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from './baseQuery'
import {
  IEvent,
  IEventByRoomResponse,
  IEventPayload,
  IEventPresenterAndNamesResponse,
  IEventByRoomPayload,
} from '../../types/events'
import { IGenericResponse } from '../../types/generic'

export const eventsApi = createApi({
  reducerPath: 'eventsApi',
  baseQuery,
  endpoints: (builder) => ({
    get: builder.query<IEvent[], void>({
      query: () => ({
        url: '/events',
        method: 'GET',
      }),
    }),
    delete: builder.mutation<IGenericResponse, number>({
      query: (id) => ({
        url: `/events/${id}`,
        method: 'DELETE',
      }),
    }),
    create: builder.mutation<IGenericResponse, IEventPayload>({
      query: (payload) => ({
        url: `/events`,
        method: 'POST',
        body: payload,
      }),
    }),
    edit: builder.mutation<IGenericResponse, IEventPayload>({
      query: (payload) => {
        const { id, ...rest } = payload
        return {
          url: `/events/${id}`,
          method: 'PUT',
          body: rest,
        }
      },
    }),
    getEventsAndPresenters: builder.query<IEventPresenterAndNamesResponse, void>({
      query: () => ({
        url: '/events/eventPresenterAndEventsNames',
        method: 'GET',
      }),
    }),
    getEventsByRoom: builder.query<IEventByRoomResponse[], IEventByRoomPayload>({
      query: ({ cruiseId, stateRoom }) => ({
        url: `/events/${cruiseId}/${stateRoom}`,
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useGetQuery,
  useCreateMutation,
  useDeleteMutation,
  useEditMutation,
  useGetEventsAndPresentersQuery,
  useLazyGetEventsByRoomQuery,
} = eventsApi
