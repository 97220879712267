import { useState } from 'react'
import { Alert, TextField, Stack, Grid } from '@mui/material'
import { ICardGuestFormProps } from '../types'
import GuestsTable from '../../AppointmentForm/GuestsTable'
import { IPersonsByRoomResponse } from '../../../types/persons'

const GuestSelection = (props: ICardGuestFormProps) => {
  const [rowSelected, setRowSelected] = useState<number>()
  const [isAddUser, setIsAddUser] = useState(false)

  const { persons, register, errors, setValue, getValues, reset } = props

  const changeRadio = (id: number) => setRowSelected(id)

  const onSelectPerson = (person: IPersonsByRoomResponse) => {
    setValue('first_name', person.first_name, {
      shouldValidate: true,
    })
    setValue('last_name', person.last_name, {
      shouldValidate: true,
    })
    setIsAddUser(false)
  }
  const onAddUser = () => {
    const formValues = getValues()
    reset({
      ...formValues,
      first_name: '',
      last_name: '',
    })
    setRowSelected(undefined)
    setIsAddUser(true)
  }

  const showTextFields = persons?.length === 0 || isAddUser

  return (
    <Stack>
      {!!persons && persons.length > 0 && (
        <GuestsTable
          persons={persons}
          onSelectPerson={onSelectPerson}
          onAddUser={onAddUser}
          rowSelected={rowSelected}
          changeRadio={changeRadio}
          isAddUser={isAddUser}
        />
      )}
      {showTextFields && (
        <>
          {persons?.length === 0 && (
            <Alert severity="warning">
              Guest information cannot be retrieved. Please validate manually.
            </Alert>
          )}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                size="small"
                autoFocus
                margin="normal"
                fullWidth
                id="first_name"
                label="First Name"
                error={!!errors.first_name}
                helperText={errors?.first_name?.message as string}
                {...register('first_name', { required: true })}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                margin="normal"
                fullWidth
                id="last_name"
                label="Last Name"
                error={!!errors.last_name}
                helperText={errors?.last_name?.message as string}
                {...register('last_name', { required: true })}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Stack>
  )
}

export default GuestSelection
