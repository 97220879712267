import { Box, Divider, Paper, Stack, Typography } from '@mui/material'

const ComputationDetails = () => (
  <Paper
    elevation={3}
    sx={{
      display: 'flex',
      flexDirection: 'column',
      rowGap: 1,
      padding: '1.5rem',
      marginY: '2rem',
    }}>
    <Box>
      <Typography>
        <Typography component="strong" fontWeight="bold">
          Appointments:
        </Typography>{' '}
        Number of appointments processed by agent.
      </Typography>
      <Typography>
        <Typography component="strong" fontWeight="bold">
          Sales:
        </Typography>{' '}
        Total dollar amount of all bookings marked as confirmed.
      </Typography>
      <Typography>
        <Typography component="strong" fontWeight="bold">
          Quotes:
        </Typography>{' '}
        Number of quotes generated by agent.
      </Typography>
      <Typography>
        <Typography component="strong" fontWeight="bold">
          {' '}
          Bookings Offered/Confirmed/Cancelled:
        </Typography>{' '}
        Number of bookings (either offered, confirmed or cancelled) generated by agent.
      </Typography>
      <Typography>
        <Typography component="strong" fontWeight="bold">
          Personal Contribution:
        </Typography>{' '}
        Sales / Voyage Target.
      </Typography>
    </Box>
    <Stack direction="row">
      <Typography fontWeight="bold">Conversion:</Typography>
      <Stack direction="column" justifyContent="center" paddingLeft="20px">
        <Typography align="center">BC</Typography>
        <Divider />
        <Typography>Total Appointments - BO - BCOO - BCO - O</Typography>
      </Stack>
    </Stack>
    <ul>
      <li>
        <Typography fontWeight="bold" display="inline-block" width="3rem">
          BC:
        </Typography>{' '}
        Appointments with Bookings Confirmed (Standard, Michael&apos;s Club)
      </li>
      <li>
        <Typography fontWeight="bold" display="inline-block" width="3rem">
          BO:
        </Typography>{' '}
        Appointments with Bookings Offered
      </li>
      <li>
        <Typography fontWeight="bold" display="inline-block" width="3rem">
          BCOO:
        </Typography>{' '}
        Appointments with Bookings Confirmed (Origin different from Standard or Michael&apos;s Club)
        + Other Result
      </li>
      <li>
        <Typography fontWeight="bold" display="inline-block" width="3rem">
          BCO:
        </Typography>{' '}
        Appointments with Bookings Confirmed (Origin different from Standard or Michael&apos;s Club)
        only
      </li>
      <li>
        <Typography fontWeight="bold" display="inline-block" width="3rem">
          O:
        </Typography>{' '}
        Appointments with Other Results only
      </li>
    </ul>
    <Divider />
    <Typography fontWeight="bold">Additional Notes:</Typography>
    <ul>
      <li>By clicking on an agent row, daily stats are shown for the voyage currently active.</li>
      <li>Daily stats are calculated the same way than agent stats.</li>
      <li>Bookings and quotes are accounted under the date when appointment was created.</li>
    </ul>
  </Paper>
)

export default ComputationDetails
