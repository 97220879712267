import RadioGroup from '@mui/material/RadioGroup'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import Radio from '@mui/material/Radio'
import Divider from '@mui/material/Divider'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import { Controller, useForm } from 'react-hook-form'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import { yupResolver } from '@hookform/resolvers/yup'
import CircularProgress from '@mui/material/CircularProgress'
import { useEffect, useState } from 'react'
import moment from 'moment'
import Stack from '@mui/material/Stack'
import { Card, CardContent, DialogActions, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { IAppointmentCallbackFormProps, IMarkAppointmentAsCallBackForm } from './types'
import { validationSchema } from './validation'
import { minDateCallback, maxDateCallback } from '../../utils/date'

const AppointmentCallbackForm: React.FC<IAppointmentCallbackFormProps> = ({
  isOpen,
  agents,
  appointment,
  officeHours,
  isFetchingOfficeHours,
  cruiseDates,
  onClose,
  onChangeDate,
  onSubmit,
}) => {
  const [selectedDate, setSelectedDate] = useState(
    appointment.callback?.date ? moment.utc(appointment.callback.date).format('MM/DD/YYYY') : null,
  )

  const [minDate, setMinDate] = useState('')
  const [maxDate, setMaxDate] = useState('')

  useEffect(() => {
    if (cruiseDates) {
      setMinDate(minDateCallback(cruiseDates.start_date))
      setMaxDate(maxDateCallback(cruiseDates.end_date))
    }
  }, [cruiseDates])

  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm<IMarkAppointmentAsCallBackForm>({
    defaultValues: {
      agent_id: appointment.agent?.agent_id,
      inPersonCallback: (appointment.inPersonCallback as unknown as number) === 1,
      callback_date: appointment.callback?.date
        ? moment.utc(appointment.callback.date).format('MM/DD/YYYY')
        : undefined,
      callback_time: appointment.callback?.time ? appointment.callback.time : '',
      comment: appointment.comment ?? '',
    },
    resolver: yupResolver(validationSchema),
  })

  const formattedDatesNotAvailable =
    cruiseDates?.dates_not_available.map((date) => moment.utc(date).format('YYYY-MM-DD')) || []

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <DialogContent>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Controller
              control={control}
              name="inPersonCallback"
              render={({ field }) => (
                <RadioGroup {...field} row>
                  <FormControlLabel value={false as boolean} control={<Radio />} label="Callback" />
                  <FormControlLabel
                    value={true as boolean}
                    control={<Radio />}
                    label="Appointment"
                  />
                </RadioGroup>
              )}
            />
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Divider sx={{ margin: '1rem 0' }} />
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Controller
                    control={control}
                    name="callback_date"
                    render={({ field: { onChange, value, ...field } }) => (
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          {...field}
                          label="Date"
                          minDate={minDate}
                          maxDate={maxDate}
                          shouldDisableDate={(date) =>
                            formattedDatesNotAvailable.includes(
                              moment.utc(date).format('YYYY-MM-DD'),
                            )
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={!!errors.callback_date}
                              helperText={errors?.callback_date?.message as string}
                            />
                          )}
                          renderDay={(
                            _day: string,
                            _selectedDays: Array<string | null>,
                            pickersDayProps: PickersDayProps<string>,
                          ) => (
                            <PickersDay
                              {...pickersDayProps}
                              sx={{
                                '&.MuiButtonBase-root.MuiPickersDay-root.Mui-selected.Mui-disabled':
                                  {
                                    color: 'rgba(255, 255, 255, 0.5)',
                                  },
                              }}
                            />
                          )}
                          value={selectedDate}
                          onChange={(newValue) => {
                            onChange(newValue)
                            if (newValue) onChangeDate(newValue)
                            setValue('callback_time', '')
                            setSelectedDate(newValue)
                          }}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {isFetchingOfficeHours ? (
                    <CircularProgress variant="indeterminate" size="1.2rem" />
                  ) : officeHours.length > 0 ? (
                    <Controller
                      control={control}
                      name="callback_time"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          sx={{ mt: 0 }}
                          select
                          margin="normal"
                          fullWidth
                          label="Hour"
                          error={!!errors.callback_time}
                          helperText={errors?.callback_time?.message as string}>
                          {officeHours.map((hour) => (
                            <MenuItem key={`key-hour-${hour.label}`} value={hour.value}>
                              {hour.value}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  ) : (
                    <Controller
                      control={control}
                      name="callback_time"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          sx={{ mt: 0 }}
                          margin="normal"
                          fullWidth
                          label="Hour"
                          error={!!errors.callback_time}
                          helperText={errors?.callback_time?.message as string}
                          disabled>
                          {appointment.callback?.time}
                        </TextField>
                      )}
                    />
                  )}
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    control={control}
                    name="agent_id"
                    render={({ field }) => (
                      <TextField {...field} label="Agent" fullWidth select>
                        {agents.map((agent) => (
                          <MenuItem key={`agent-${agent.id}`} value={agent.id}>
                            {`${agent.first_name} ${agent.last_name}`}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
              </Grid>
              <Divider sx={{ margin: '1rem 0' }} />
              <Controller
                control={control}
                name="comment"
                render={({ field }) => (
                  <TextField
                    fullWidth
                    multiline
                    maxRows={3}
                    minRows={3}
                    {...field}
                    placeholder="Comments"
                  />
                )}
              />
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <Stack
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            columnGap={1}
            sx={{ mb: '1.25rem', mr: 2 }}>
            <Button type="submit" variant="contained" color="success">
              Save
            </Button>
          </Stack>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default AppointmentCallbackForm
