import { useEditMutation, useGetQuery } from '../../../redux/api/conversionSettings'
import { IConversionSettingPayload } from '../../../types/conversionSettings'
import { IGenericResponse } from '../../../types/generic'

export const useConversionSettings = () => {
  const [editSetting] = useEditMutation()
  const {
    isLoading: isLoadingSettings,
    data: conversionSettings,
    refetch: refetchSettings,
  } = useGetQuery()

  const edit = async (
    payload: IConversionSettingPayload,
    onSaved?: (response: IGenericResponse) => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      const response = await editSetting(payload).unwrap()
      if (onSaved) onSaved(response)
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  const qualifiedNonQualified = conversionSettings?.filter(
    (cs) => cs.flag === 'QUALIFIED' || cs.flag === 'NON-QUALIFIED',
  )
  const standardSupplemental = conversionSettings?.filter(
    (cs) => cs.flag === 'STANDARD' || cs.flag === 'SUPPLEMENTAL',
  )

  return {
    edit,
    isLoadingSettings,
    qualifiedNonQualified,
    standardSupplemental,
    refetchSettings,
  }
}
