import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from './baseQuery'
import { IGenericResponse } from '../../types/generic'
import { IConversionSetting, IConversionSettingPayload } from '../../types/conversionSettings'

export const conversionSettingsApi = createApi({
  reducerPath: 'conversionSettingsApi',
  baseQuery,
  endpoints: (builder) => ({
    edit: builder.mutation<IGenericResponse, IConversionSettingPayload>({
      query: ({ id, flag }) => ({
        url: `/conversionSettings/${id}`,
        method: 'PUT',
        body: {
          flag,
        },
      }),
    }),
    get: builder.query<IConversionSetting[], void>({
      query: () => ({
        url: `/conversionSettings`,
        method: 'GET',
      }),
    }),
  }),
})

export const { useEditMutation, useGetQuery } = conversionSettingsApi
