import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment, { Moment } from 'moment'
import { useState } from 'react'
import { validationSchema } from './validations'
import { IInitiativeFormProps } from './types'
import { RootState } from '../../redux/store'
import { IInitiative } from '../../types/marketingInitiatives'
import { marketingInitiativesTypes } from '../../utils/constants'
import { useAppSelector } from '../../redux/hooks'

const InitiativeForm = (props: IInitiativeFormProps) => {
  const { initiative, vessels, isOpen, isLoading, onAccept, onReject } = props
  const [selectDate, setSelectDate] = useState<Moment | null>(
    initiative?.date ? moment.utc(initiative.date) : null,
  )

  const activeCruiseStartDate = useAppSelector(
    (state: RootState) => state.auth.activeCruise?.start_date,
  )
  const activeCruiseEndDate = useAppSelector(
    (state: RootState) => state.auth.activeCruise?.end_date,
  )

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IInitiative>({
    resolver: yupResolver(validationSchema),
    defaultValues: { ...initiative },
  })

  const title = initiative?.initiative_id ? 'Edit Initiative' : 'New Initiative'

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <Box component="form" onSubmit={handleSubmit(onAccept)} noValidate>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ marginBottom: 2 }}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="initiative_type"
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    inputRef={ref}
                    margin="normal"
                    fullWidth
                    select
                    id="initiative_type"
                    label="Initiative"
                    type="text"
                    defaultValue=""
                    error={!!errors.initiative_type}
                    helperText={errors?.initiative_type?.message as string}>
                    {marketingInitiativesTypes.map((type) => (
                      <MenuItem key={`initiative-type-key-${type}`} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="product"
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Product"
                    fullWidth
                    error={!!errors.product}
                    helperText={errors?.product?.message as string}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="ship_id"
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    inputRef={ref}
                    fullWidth
                    margin="normal"
                    select
                    id="ship_id"
                    label="Ship"
                    type="text"
                    defaultValue=""
                    error={!!errors.ship_id}
                    helperText={errors?.ship_id?.message as string}>
                    {vessels.map((vessel) => (
                      <MenuItem key={`vessel-key-${vessel.id}`} value={vessel.id}>
                        {vessel.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="date"
                render={({ field: { onChange, value, ...field } }) => (
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      label="Sail Date"
                      minDate={activeCruiseStartDate}
                      maxDate={activeCruiseEndDate}
                      value={selectDate}
                      onChange={(newValue) => {
                        setSelectDate(moment.utc(newValue))
                        onChange(newValue)
                      }}
                      renderInput={(params: any) => (
                        <TextField {...params} fullWidth error={!!errors.date} sx={{ mt: 2 }} />
                      )}
                      {...field}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onReject}>Cancel</Button>
          <LoadingButton type="submit" loading={isLoading}>
            Save
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default InitiativeForm
