import { useParams } from 'react-router-dom'
import { useGetCruiseQuery } from '../../../../redux/api/cruises'

export const useEditVoyage = () => {
  const { id } = useParams()
  const { data, isLoading, refetch } = useGetCruiseQuery(id!)

  return {
    data,
    isLoading,
    refetch,
  }
}
