import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { yupResolver } from '@hookform/resolvers/yup'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { Controller, useForm } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import { Moment } from 'moment'
import { useState } from 'react'
import { IconButton, InputAdornment, Typography } from '@mui/material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { DropzoneArea } from 'mui-file-dropzone'
import { validationSchema } from './validations'
import { IMailDropForm, IMailDropProps } from './types'

const UploadMailDropForm = (props: IMailDropProps) => {
  const { onAccept, onReject, isLoading, isOpen } = props
  const [mailDate, setMailDate] = useState<Moment | null>(null)
  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
  } = useForm<IMailDropForm>({
    resolver: yupResolver(validationSchema),
  })

  return (
    <Dialog open={isOpen} maxWidth="xs">
      <Box component="form" onSubmit={handleSubmit(onAccept)} noValidate>
        <DialogTitle>Upload Mail Drop</DialogTitle>
        <DialogContent>
          <Controller
            name="file"
            control={control}
            render={({ field: { onChange, value } }) => {
              if (value && value.length > 0)
                return (
                  <TextField
                    margin="normal"
                    fullWidth
                    label="Selected File"
                    type="text"
                    defaultValue={(value[0] as File).name}
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setValue('file', [])}>
                            <HighlightOffIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )
              return (
                <DropzoneArea
                  initialFiles={value as (File | string)[]}
                  filesLimit={1}
                  fileObjects={[]}
                  onChange={onChange}
                  acceptedFiles={[
                    '.csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values',
                  ]}
                  showAlerts={false}
                />
              )
            }}
          />
          {errors.file && (
            <Typography variant="caption" color="error.main" marginLeft={1.5}>
              {errors?.file?.message as string}
            </Typography>
          )}
          <TextField
            autoFocus
            margin="normal"
            fullWidth
            id="name"
            label="Name"
            error={!!errors.name}
            helperText={errors?.name?.message as string}
            {...register('name', { required: true })}
          />
          <Controller
            name="date"
            control={control}
            render={({ field: { onChange } }) => (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  label="Date"
                  inputFormat="MM/DD/YYYY"
                  value={mailDate}
                  onChange={(event) => {
                    onChange(event)
                    setMailDate(event)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      error={!!errors.date}
                      helperText={errors?.date?.message as string}
                    />
                  )}
                />
              </LocalizationProvider>
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onReject}>CANCEL</Button>
          <LoadingButton type="submit" loading={isLoading}>
            UPLOAD
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default UploadMailDropForm
