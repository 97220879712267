import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from './baseQuery'
import { IUpdateQueuePayload, IQueueResponse } from '../../types/queues'
import { IGenericResponse } from '../../types/generic'

export const queuesApi = createApi({
  reducerPath: 'queuesApi',
  baseQuery,
  endpoints: (builder) => ({
    getById: builder.query<IQueueResponse, number>({
      query: (id) => ({
        url: `/queues/${id}`,
        method: 'GET',
      }),
    }),
    update: builder.mutation<IGenericResponse, IUpdateQueuePayload>({
      query: ({ id, action }) => ({
        url: `/queues/${id}/${action}`,
        method: 'POST',
      }),
    }),
  }),
})

export const { useGetByIdQuery, useUpdateMutation } = queuesApi
