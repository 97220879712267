import {
  useUploadExtraInfoMutation,
  useGetExtraInfoByCruiseQuery,
  useRemoveExtraInfoMutation,
} from '../../../redux/api/persons'
import { useAppSelector } from '../../../redux/hooks'
import { IGenericResponse } from '../../../types/generic'

export const useExtraInfo = () => {
  const cruiseId = useAppSelector((state) => state.auth.activeCruise?.cruise_id || 0)

  const { data, isLoading, refetch } = useGetExtraInfoByCruiseQuery(cruiseId, { skip: !cruiseId })
  const [invokeUploadExtraInfoRecord] = useUploadExtraInfoMutation()
  const [invokeRemoveExtraInfoRecord] = useRemoveExtraInfoMutation()

  const uploadExtraInfoRecord = async (
    extraInfoRecordData: any,
    onSaved?: (message: string) => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      const response: IGenericResponse = await invokeUploadExtraInfoRecord(
        extraInfoRecordData,
      ).unwrap()
      if (onSaved) onSaved(response.message)
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  const removeExtraInfoRecord = async (
    fileName: string,
    onSaved?: (message: string) => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      const response: IGenericResponse = await invokeRemoveExtraInfoRecord({
        fileName,
        cruiseId,
      }).unwrap()
      if (onSaved) onSaved(response.message)
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  return { isLoading, data, refetch, uploadExtraInfoRecord, removeExtraInfoRecord }
}
