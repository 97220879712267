import { Box, Typography } from '@mui/material'
import { Footer, PrivateLayoutContainer, PublicLayoutContainer } from './styles'
import { LayoutProps } from './types'
import MainNavigation from '../MainNavigation'
import TimeAndLocationBar from '../TimeAndLocationBar'
import { FOOTER_BAR_HEIGHT_PX, VOYAGE_INFO_BAR_HEIGHT_PX } from '../../utils/constants'
import { useGetWizardQuery } from '../../redux/api/utils'
import { EUserRole } from '../../types/users'
import { useAppSelector } from '../../redux/hooks'
import { ILoggedUser } from '../../redux/features/auth'

const Layout: React.FC<LayoutProps> = ({ isProtected, component }) => {
  const loggedUser = useAppSelector((state) => state.auth.loggedUser) as ILoggedUser
  const { data: wizardData } = useGetWizardQuery(undefined, {
    skip: loggedUser?.role === EUserRole.AGENT,
  })
  const isActiveVoyage = wizardData && wizardData.isThereActiveCruise
  const isPendingVoyageSetup = !(!wizardData || Object.values(wizardData).every((value) => value))

  if (isProtected) {
    return (
      <PrivateLayoutContainer>
        <MainNavigation />
        <TimeAndLocationBar />
        <Box
          component="main"
          sx={{
            minHeight: `calc(100vh - ${FOOTER_BAR_HEIGHT_PX}px - ${VOYAGE_INFO_BAR_HEIGHT_PX}px)`,
            display: 'flex',
            marginTop: isPendingVoyageSetup && !isActiveVoyage ? 6 : 0,
          }}>
          {component}
        </Box>
        <Footer>
          <Typography variant="caption">{`Version ${process.env.REACT_APP_VERSION}`}</Typography>
        </Footer>
      </PrivateLayoutContainer>
    )
  }

  return <PublicLayoutContainer>{component}</PublicLayoutContainer>
}

export default Layout
