/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ICruiseResponse } from '../../types/cruises'
import { IWizardResponse } from '../../types/utils'
import { EUserRole } from '../../types/users'
import { decodeJWT } from '../../utils/utils'

export interface ILoggedUser {
  firstName: string
  lastName: string
  role: EUserRole
  userId: string
}

interface AuthState {
  token?: string
  csrfToken?: string
  loggedUser?: ILoggedUser
  activeCruise?: ICruiseResponse
  wizard?: IWizardResponse
}

const initialState: AuthState = {}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoginToken: (state, action: PayloadAction<Pick<AuthState, 'token' | 'csrfToken'>>) => {
      state.token = action.payload.token
      state.csrfToken = action.payload.csrfToken
      state.loggedUser = decodeJWT(action.payload.token) as ILoggedUser
    },
    doLogout: (state) => {
      state.token = undefined
      state.csrfToken = undefined
      state.loggedUser = undefined
    },
    setActiveCruise: (state, action: PayloadAction<ICruiseResponse | undefined>) => {
      state.activeCruise = action.payload
    },
    setWizard: (state, action: PayloadAction<IWizardResponse | undefined>) => {
      state.wizard = action.payload
    },
  },
})

export const { setLoginToken, doLogout, setActiveCruise, setWizard } = authSlice.actions
export default authSlice.reducer
