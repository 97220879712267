import { UseFormTrigger } from 'react-hook-form'
import { IGetDaysNotAvailableResponse, IHours } from '../../pages/Settings/Voyages/types'
import { IAppointment } from '../../types/appointments'
import { IPersonsByRoomResponse } from '../../types/persons'
import { ICruiseAgent } from '../../types/users'

export interface IAppointmentFormProps {
  assignedAgents: ICruiseAgent[]
  officeHours: IHours[]
  isOnlyCallbacks: boolean
  isLoading: boolean
  onChangeCallbackDate: React.Dispatch<React.SetStateAction<string>>
  onChangeAppointmentType: React.Dispatch<React.SetStateAction<string>>
  appointmentType: string
  persons?: IPersonsByRoomResponse[]
  isOpen: boolean
  cruiseDates?: IGetDaysNotAvailableResponse
  onLookup: (stateroom: number, trigger: UseFormTrigger<IAppointment>) => void
  onAccept: (data: IAppointment) => void
  onReject: () => void
}

export enum EAppointmentType {
  GET_IN_LINE = 'Get in Line',
  CALLBACK = 'Callback',
  APPOINTMENT = 'Appointment',
}

export interface IGuestsTableProps {
  isAddUser: boolean
  rowSelected?: number
  persons: IPersonsByRoomResponse[]
  onSelectPerson: (person: IPersonsByRoomResponse) => void
  onAddUser: () => void
  changeRadio: (id: number) => void
}
