import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import DialogContent from '@mui/material/DialogContent'
import { Box, Button, IconButton, InputAdornment, TextField } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { useState } from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { validationSchema } from './validations'
import { IPassword, IResetPasswordFormProps } from './types'

const ResetPasswordForm = (props: IResetPasswordFormProps) => {
  const { isOpen, isLoading, onAccept, onReject } = props
  const [showPassword, setShowPassword] = useState(false)

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<IPassword>({
    resolver: yupResolver(validationSchema),
  })

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <Box component="form" onSubmit={handleSubmit(onAccept)} noValidate>
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
          <DialogContentText>Please provide a new password for this user.</DialogContentText>
          <TextField
            margin="normal"
            fullWidth
            id="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            error={!!errors.password}
            helperText={errors?.password?.message as string}
            {...register('password', { required: true })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={() => setShowPassword(!showPassword)}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onReject}>Cancel</Button>
          <LoadingButton type="submit" loading={isLoading}>
            Set Password
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default ResetPasswordForm
