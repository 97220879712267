import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  Box,
} from '@mui/material'
import TableContainer from '@mui/material/TableContainer'
import moment from 'moment'
import { IGuestStatsTableProps, IGuestStats } from './types'
import { parseDateToString } from '../../../../utils/date'
import { headTableStyle } from '../../../../styles/generic-styles'
import { cardStatusOptions } from '../../../../utils/constants'
import { EStatusCard } from '../../../../types/cards'
import { useAppSelector } from '../../../../redux/hooks'

const GuestCardStatsTable: React.FC<IGuestStatsTableProps> = ({
  stats,
  handleOnChangeStatus,
  handleOnTransferred,
}) => {
  const loggedUser = useAppSelector((state) => state.auth.loggedUser)
  const currentAgent =
    loggedUser?.role === 'superadmin' || loggedUser?.role === 'admin'
      ? stats
      : stats.filter((agent) => agent.agentId === loggedUser?.userId)

  return (
    <TableContainer>
      {currentAgent && currentAgent.length > 0 ? (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={headTableStyle}>GUEST</TableCell>
              <TableCell sx={headTableStyle} align="center">
                STATEROOM
              </TableCell>
              <TableCell sx={headTableStyle}>AGENT</TableCell>
              <TableCell sx={headTableStyle}>PORT</TableCell>
              <TableCell sx={headTableStyle} align="center">
                STATUS
              </TableCell>
              <TableCell sx={headTableStyle} align="center">
                TRANSFERRED TO FOLIO
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentAgent.map((stat: IGuestStats, index) => (
              <TableRow key={`row-${index}`}>
                <TableCell>{stat.guestName}</TableCell>
                <TableCell align="center">{stat.stateroom}</TableCell>
                <TableCell>{stat.agentName}</TableCell>
                <TableCell>
                  {stat.portName}
                  <br />
                  {moment.utc(parseDateToString(stat.date)).format('MM/DD/YYYY')}
                </TableCell>
                <TableCell align="center">
                  <Select
                    size="small"
                    sx={{ width: 150 }}
                    value={stat.cardStatus}
                    onChange={(e) => handleOnChangeStatus(stat, e.target.value as EStatusCard)}>
                    {cardStatusOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell align="center">
                  <Select
                    size="small"
                    sx={{ width: 150 }}
                    value={stat.cardTransferredToFolio ? 1 : 0}
                    onChange={(e) => handleOnTransferred(stat, !!e.target.value)}>
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                  </Select>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Box>No stats to display.</Box>
      )}
    </TableContainer>
  )
}

export default GuestCardStatsTable
