import {
  useCreateUserMutation,
  useEditUserMutation,
  useRemoveMutation,
  useGetQuery,
  useChangePasswordMutation,
} from '../../../redux/api/users'
import { IGenericResponse } from '../../../types/generic'
import { IChangePasswordPayload, IUserPayload } from '../../../types/users'

export const useUser = () => {
  const [invokeCreateUser] = useCreateUserMutation()
  const [invokeEditUser] = useEditUserMutation()
  const [invokeRemoveUser] = useRemoveMutation()
  const [invokeChangePassword] = useChangePasswordMutation()
  const { data: users, isLoading: isLoadingUsersData, refetch } = useGetQuery()

  const createUser = async (
    user: IUserPayload,
    onSaved?: (message: string) => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      const response: IGenericResponse = await invokeCreateUser(user).unwrap()
      if (onSaved) onSaved(response.message)
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  const editUser = async (
    user: IUserPayload,
    onSaved?: (message: string) => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      const response: IGenericResponse = await invokeEditUser(user).unwrap()
      if (onSaved) onSaved(response.message)
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  const removeUser = async (
    id: string,
    onSaved?: (message: string) => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      const response: IGenericResponse = await invokeRemoveUser(id).unwrap()
      if (onSaved) onSaved(response.message)
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  const changePassword = async (
    password: IChangePasswordPayload,
    onSaved?: (message: string) => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      const response: IGenericResponse = await invokeChangePassword(password).unwrap()
      if (onSaved) onSaved(response.message)
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  return {
    createUser,
    editUser,
    removeUser,
    changePassword,
    users,
    isLoadingUsersData,
    refetch,
  }
}
