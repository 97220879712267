import {
  Dialog,
  Box,
  DialogTitle,
  IconButton,
  TextField,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  DialogContent,
  Stack,
  CircularProgress,
  Alert,
  MenuItem,
  Button,
} from '@mui/material'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import DeleteIcon from '@mui/icons-material/Delete'
import CloseIcon from '@mui/icons-material/Close'
import { useEffect } from 'react'
import { IAgentsFormProps } from './types'
import { validationSchema } from './validation'
import { headTableStyle } from '../../styles/generic-styles'
import { IAgentPayload, IAgentsGoal, ICruiseAgent } from '../../types/users'
import { theme } from '../../styles/mui-theme'

const AgentsForm: React.FC<IAgentsFormProps> = ({
  isOpen,
  cruise,
  assignedAgents,
  selectAgent,
  allUsers,
  isLoading,
  isLoadingGetCruiseAgents,
  isLoadingDeleteAgent,
  onClose,
  onSubmit,
  onDelete,
}) => {
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IAgentPayload>({
    resolver: yupResolver(validationSchema),
  })
  const assignedAgentsIds = assignedAgents?.map((item) => item.id) || []

  useEffect(() => {
    reset()
  }, [assignedAgents])

  const onSelectAgent = (agent: ICruiseAgent) => {
    selectAgent(agent)
  }

  const getTotalGoals = (agentGoals: IAgentsGoal[]) =>
    agentGoals.reduce((sum, item) => sum + item.goal, 0)

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          Assign Agents
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Box
          key={`agents-${isOpen}-${assignedAgents?.length}`}
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}>
          <Stack
            direction="row"
            alignItems="flex-start"
            gap={1}
            justifyContent="space-between"
            paddingY={1}>
            <TextField
              id="userId"
              label="Agent"
              size="small"
              select
              fullWidth
              defaultValue=""
              error={!!errors.userId}
              helperText={errors?.userId?.message as string}
              {...register('userId', { required: true })}>
              {allUsers
                ?.filter((agent) => agent.active)
                .map(({ id, first_name, last_name }) =>
                  !assignedAgentsIds?.includes(id || '') ? (
                    <MenuItem key={`user-${id}`} value={id}>
                      {`${first_name} ${last_name}`}
                    </MenuItem>
                  ) : null,
                )}
            </TextField>
            <TextField
              id="code"
              size="small"
              label="SLOC Code"
              error={!!errors.code}
              helperText={errors?.code?.message as string}
              {...register('code', { required: true })}
            />
            <LoadingButton type="submit" variant="contained" loading={isLoading}>
              Assign
            </LoadingButton>
          </Stack>
        </Box>

        <Box maxHeight={300} marginY={3} overflow="auto" textAlign="center">
          {isLoadingGetCruiseAgents && <CircularProgress />}

          {!isLoadingGetCruiseAgents && assignedAgents && assignedAgents.length <= 0 && (
            <Alert severity="info">No agents assigned yet.</Alert>
          )}

          {!isLoadingGetCruiseAgents && assignedAgents && assignedAgents.length > 0 && (
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={headTableStyle}>AGENT</TableCell>
                  <TableCell sx={headTableStyle}>SLOC CODE</TableCell>
                  <TableCell sx={headTableStyle} width={20}>
                    GOALS TOTAL
                  </TableCell>
                  <TableCell sx={headTableStyle} width={20} />
                  <TableCell sx={headTableStyle} width={10} />
                </TableRow>
              </TableHead>
              <TableBody>
                {assignedAgents.map((agent) => (
                  <TableRow key={`table-row-agents-${agent.id}`}>
                    <TableCell>{`${agent.first_name} ${agent.last_name}`}</TableCell>
                    <TableCell>{`${agent.code}`}</TableCell>
                    <TableCell width={20} align="center">
                      {getTotalGoals(agent.goals)}
                    </TableCell>
                    <TableCell>
                      <Button
                        color="secondary"
                        variant="contained"
                        size="small"
                        onClick={() => onSelectAgent(agent)}>
                        GOALS
                      </Button>
                    </TableCell>
                    <TableCell>
                      {isLoadingDeleteAgent ? (
                        <CircularProgress size={theme.spacing(4)} />
                      ) : (
                        <IconButton
                          color="error"
                          size="small"
                          onClick={() =>
                            onDelete({ cruiseId: cruise.cruise_id, userId: agent.id })
                          }>
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default AgentsForm
