import * as yup from 'yup'

export const validationSchema = yup.object().shape({
  quoteBrand: yup.string().required('Brand is required').typeError('Brand is required'),
  guestCategory: yup
    .string()
    .required('Guest Category is required')
    .typeError('Guest Category is required'),
  guestOrigin: yup
    .string()
    .required('Guest Origin is required')
    .typeError('Guest Origin is required'),
  groupQuoteItems: yup.array().of(
    yup.object().shape({
      amount: yup.number(),
      stateroom_number: yup.string(),
    }),
  ),
})
