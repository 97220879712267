import React from 'react'
import TableCell from '@mui/material/TableCell'
import TextField from '@mui/material/TextField'
import { Controller, useFieldArray } from 'react-hook-form'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import DeleteIcon from '@mui/icons-material/Delete'
import Grid from '@mui/material/Grid'
import { INestedOfficeHoursFormProps } from './types'

const NestedOfficeHoursForm: React.FC<INestedOfficeHoursFormProps> = ({
  index,
  control,
  formState,
  setTimes,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: `officeHours.${index}.hours`,
  })

  return (
    <TableCell align="center">
      {fields.map((item, itemIndex) => (
        <Grid
          container
          alignItems="center"
          key={`nested-office-hours-${item.id}`}
          sx={{ marginBottom: itemIndex === fields.length - 1 ? 0 : 2 }}>
          <Grid item xs={10} sm={8}>
            <Grid container rowSpacing={1} columnSpacing={2}>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name={`officeHours.${index}.hours.${itemIndex}.openHour`}
                  defaultValue={item.openHour}
                  render={({ field }) => (
                    <TextField
                      type="time"
                      fullWidth
                      {...field}
                      sx={{ marginRight: { md: 1 } }}
                      error={!!formState.errors.officeHours?.[index]?.hours?.[itemIndex]?.openHour}
                      helperText={
                        formState.errors?.officeHours?.[index]?.hours?.[itemIndex]?.openHour
                          ?.message as string
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name={`officeHours.${index}.hours.${itemIndex}.closeHour`}
                  defaultValue={item.closeHour}
                  render={({ field }) => (
                    <TextField
                      type="time"
                      fullWidth
                      {...field}
                      error={!!formState.errors.officeHours?.[index]?.hours?.[itemIndex]?.closeHour}
                      helperText={
                        formState.errors?.officeHours?.[index]?.hours?.[itemIndex]?.closeHour
                          ?.message as string
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2} sm={4}>
            {itemIndex === 0 ? (
              <Fab
                size="small"
                color="success"
                onClick={() => append({ closeHour: '', openHour: '' })}>
                <AddIcon />
              </Fab>
            ) : (
              <Fab size="small" color="error" onClick={() => remove(itemIndex)}>
                <DeleteIcon />
              </Fab>
            )}
            <Fab size="small" color="primary" sx={{ marginLeft: 1 }}>
              <AccessTimeIcon onClick={() => setTimes(index, itemIndex)} />
            </Fab>
          </Grid>
        </Grid>
      ))}
    </TableCell>
  )
}

export default NestedOfficeHoursForm
