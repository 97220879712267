import moment from 'moment'
import * as yup from 'yup'

export const basicDataValidation = yup.object().shape({
  vessel: yup.number().required('Vessel is required').min(1, 'Vessel is required'),
  product: yup.number().required('Product is required').min(1, 'Product is required'),
  name: yup.string().required('Name is required'),
  voyage_number: yup
    .number()
    .required('Voyage is required')
    .positive('Voyage must be greater than zero')
    .typeError('Voyage must be a number'),
  sailed_bookings: yup
    .number()
    .required('Voyage is required')
    .positive('Voyage must be greater than zero')
    .typeError('Voyage must be a number'),
  penetration_goal: yup
    .number()
    .required('Penetration Goal is required')
    .positive('Penetration Goal must be greater than zero')
    .typeError('Penetration Goal must be a number'),
  target: yup
    .number()
    .required('Target is required')
    .positive('Target must be greater than zero')
    .typeError('Target must be a number'),
  embarkPort: yup.number().required('Embark port is required').min(1, 'Embark port is required'),
  embarkDate: yup.date().required('Embark date is required').typeError('A valid date is required'),
  embarkTime: yup.string().required('Embark time is required'),
  debarkPort: yup.number().required('Debark port is required').min(1, 'Debark port is required'),
  debarkDate: yup
    .date()
    .required('Debark date is required')
    .typeError('A valid date is required')
    .test('min_end_date', 'Debark date must be after embark date', (value, context) => {
      if (value && moment(value) < moment(context.parent.embarkDate)) {
        return false
      }
      return true
    }),
  debarkTime: yup.string().required('Debark time is required'),
})

const itineraryFormValidation = yup.object().shape({
  port: yup.number().required('Port is required').typeError('Port is required'),
  startTime: yup.string().required('Begin time is required'),
  endTime: yup
    .string()
    .required('End time is required')
    .test('min_end_time', 'End time must be after start time', (value, context) => {
      if (value && moment(value, 'HH:mm') < moment(context.parent.startTime, 'HH:mm')) {
        return false
      }
      return true
    }),
})

export const itineraryFormValidationSchema = yup.object({
  itinerary_name: yup.string().required('Itinerary name is required'),
  portSequence: yup.array().of(itineraryFormValidation),
})

const officeHoursFormValidation = yup.object().shape({
  openHour: yup.string().required('Begin time is required'),
  closeHour: yup
    .string()
    .required('End time is required')
    .test('min_end_time', 'End time must be after start time', (value, context) => {
      if (value && moment(value, 'HH:mm') < moment(context.parent.openHour, 'HH:mm')) {
        return false
      }
      return true
    }),
})

export const officeHoursFormValidationSchema = yup.object({
  officeHours: yup.array().of(
    yup.object().shape({
      hours: yup.array().of(officeHoursFormValidation),
    }),
  ),
})

const callbackTimeLimitsFormValidation = yup.object().shape({
  openHour: yup.string().required('Hour is required'),
  callbacksPerHour: yup
    .number()
    .required('Callbacks per hour is required')
    .typeError('Callbacks per hour must be a number'),
})

export const callbackTimeLimitsFormValidationSchema = yup.object({
  callbacks: yup.array().of(
    yup.object().shape({
      hours: yup.array().of(callbackTimeLimitsFormValidation),
    }),
  ),
})
