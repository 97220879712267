import { IInitiativePayload } from './types'
import {
  useCreateEditMutation,
  useGetQuery as useGetInitiatives,
  useRemoveMutation,
} from '../../../redux/api/marketingInitiatives'

export const useMarketingInitiatives = () => {
  const { isLoading, data: initiatives, refetch: refetchInitiatives } = useGetInitiatives()

  const [removeInitiative] = useRemoveMutation()
  const [createEditInitiative] = useCreateEditMutation()

  const createEdit = async (
    initiative: IInitiativePayload,
    onSaved?: () => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      await createEditInitiative(initiative).unwrap()
      if (onSaved) onSaved()
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  const remove = async (
    initiativeId: number,
    onSaved?: (message?: string) => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      const response = await removeInitiative(initiativeId).unwrap()
      if (onSaved) onSaved(response.message)
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  return { isLoading, initiatives, remove, refetchInitiatives, createEdit }
}
