import { Route, Routes } from 'react-router-dom'
import CustomRoute from './components/CustomRoute'
import Appointments from './pages/Appointments'
import Dashboard from './pages/Dashboard'
import Events from './pages/Events'
import Login from './pages/Login'
import NewVoyage from './pages/Settings/Voyages/NewVoyage'
import Settings from './pages/Settings/Settings'
import SettingsMailDrop from './pages/Settings/MailDrop'
import SettingsPorts from './pages/Settings/Ports'
import SettingsProductsVessels from './pages/Settings/ProductsVessels'
import SettingsConversion from './pages/Settings/Conversion'
import SettingsUsers from './pages/Settings/Users'
import SettingsVoyages from './pages/Settings/Voyages'
import VoyageReport from './pages/Settings/VoyageReport'
import VoyageCardReport from './pages/Settings/VoyageCardReport'
import EditVoyage from './pages/Settings/Voyages/EditVoyage'
import SettingsUploadGuestExtraInfo from './pages/Settings/GuestExtraInfo'
import MarketingInitiative from './pages/Settings/MarketingInitiative'

const App = () => (
  <Routes>
    <Route path="/login" element={<Login />} />
    <Route path="/" element={<CustomRoute isProtected component={<Dashboard />} />} />
    <Route
      path="/appointments"
      element={<CustomRoute queueActiveOnly isProtected component={<Appointments />} />}
    />
    <Route path="/events" element={<CustomRoute adminOnly isProtected component={<Events />} />} />
    <Route path="/settings">
      <Route index element={<CustomRoute adminOnly isProtected component={<Settings />} />} />
      <Route
        path="mail-drop"
        element={<CustomRoute adminOnly isProtected component={<SettingsMailDrop />} />}
      />
      <Route
        path="marketing-initiative"
        element={<CustomRoute adminOnly isProtected component={<MarketingInitiative />} />}
      />
      <Route
        path="ports"
        element={<CustomRoute superAdminOnly isProtected component={<SettingsPorts />} />}
      />
      <Route
        path="products-vessels"
        element={<CustomRoute superAdminOnly isProtected component={<SettingsProductsVessels />} />}
      />
      <Route
        path="conversion"
        element={<CustomRoute superAdminOnly isProtected component={<SettingsConversion />} />}
      />
      <Route
        path="users"
        element={<CustomRoute adminOnly isProtected component={<SettingsUsers />} />}
      />
      <Route path="voyages">
        <Route
          index
          element={<CustomRoute adminOnly isProtected component={<SettingsVoyages />} />}
        />
        <Route
          path="new"
          element={<CustomRoute adminOnly isProtected component={<NewVoyage />} />}
        />
        <Route
          path=":id"
          element={<CustomRoute adminOnly isProtected component={<EditVoyage />} />}
        />
        <Route
          path=":id/report"
          element={<CustomRoute adminOnly isProtected component={<VoyageReport />} />}
        />
        <Route
          path=":id/cardReport"
          element={<CustomRoute adminOnly isProtected component={<VoyageCardReport />} />}
        />
      </Route>
      <Route
        path="extraData"
        element={<CustomRoute adminOnly isProtected component={<SettingsUploadGuestExtraInfo />} />}
      />
    </Route>
  </Routes>
)

export default App
