import { toast, ToastOptions } from 'react-toastify'

const toastConfig: ToastOptions = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  pauseOnFocusLoss: false,
  progress: undefined,
}

const info = (message: string = '') =>
  toast(message, {
    ...toastConfig,
    type: 'info',
  })

const success = (message: string = '') =>
  toast(message, {
    ...toastConfig,
    type: 'success',
  })

const warning = (message: string = '') =>
  toast(message, {
    ...toastConfig,
    type: 'warning',
  })

const error = (message: string = '') =>
  toast(message, {
    ...toastConfig,
    type: 'error',
  })

const show = (message: string = '') =>
  toast(message, {
    ...toastConfig,
    type: 'default',
  })

export default {
  info,
  success,
  warning,
  error,
  show,
}
