import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Switch from '@mui/material/Switch'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import ClearIcon from '@mui/icons-material/Clear'
import Box from '@mui/material/Box'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import EmojiFlagsOutlinedIcon from '@mui/icons-material/EmojiFlagsOutlined'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Button from '@mui/material/Button'
import { IAppointmentFiltersProps } from './types'
import { useAppSelector } from '../../redux/hooks'
import { theme } from '../../styles/mui-theme'
import { EUserRole } from '../../types/users'

const AppointmentFilters: React.FC<IAppointmentFiltersProps> = ({
  appointments,
  agents,
  displayFilterByDate,
  displayFilterByType,
  displayHasPendingItems,
  displayManageMultiple,
  displayFilterByAgent,
  keyword,
  onChangeKeyword,
  hasPendingItems,
  onChangeHasPendingItems,
  keywordDate,
  onChangeKeywordDate,
  onChangeType,
  onChangeAgent,
  isFetching,
  onChangeManageMultipleAppointment,
  enableMultipleAppointments,
  openMultipleAppointments,
  showMultipleAppointmentButton,
  inLineTab,
}) => {
  const loggedUser = useAppSelector((state) => state.auth.loggedUser)
  const isAgent = loggedUser?.role === EUserRole.AGENT

  function checkAppointmentsData() {
    let totalQuotes = 0
    let totalBookings = 0
    let totalBookingsBrochure = 0
    let totalBookingsDraft = 0
    let totalBookingsOffered = 0
    let totalBookingsConfirmed = 0
    let totalBookingsCancelled = 0
    let totalOthers = 0
    let totalOthersNoShow = 0
    let totalOthersExistingBooking = 0
    let totalOthersNotRelated = 0
    appointments.forEach((app) => {
      if (app.quotes && app.quotes.length > 0)
        totalQuotes += app.quotes.some((quote) => quote.isFromThisAppointment) ? 1 : 0
      if (app.bookings && app.bookings.length > 0) {
        totalBookingsBrochure += app.bookings.some(
          (booking) =>
            booking.booking_status_future === 'BROCHURE' && booking.isFromThisAppointment,
        )
          ? 1
          : 0
        totalBookingsDraft += app.bookings.some(
          (booking) => booking.booking_status_future === 'DRAFT' && booking.isFromThisAppointment,
        )
          ? 1
          : 0
        totalBookingsOffered += app.bookings.some(
          (booking) => booking.booking_status_future === 'OFFERED' && booking.isFromThisAppointment,
        )
          ? 1
          : 0
        totalBookingsConfirmed += app.bookings.some(
          (booking) =>
            booking.booking_status_future === 'CONFIRMED' && booking.isFromThisAppointment,
        )
          ? 1
          : 0
        totalBookingsCancelled += app.bookings.some(
          (booking) =>
            booking.booking_status_future === 'CANCELLED' && booking.isFromThisAppointment,
        )
          ? 1
          : 0
      }

      totalBookings =
        totalBookingsBrochure +
        totalBookingsDraft +
        totalBookingsOffered +
        totalBookingsConfirmed +
        totalBookingsCancelled

      if (app.others && app.others.length > 0) {
        totalOthersNoShow += app.others.some(
          (other) => other.result === 'NOSHOW' && other.isFromThisAppointment,
        )
          ? 1
          : 0
        totalOthersExistingBooking += app.others.some(
          (other) => other.result === 'EXISTINGBOOKING' && other.isFromThisAppointment,
        )
          ? 1
          : 0
        totalOthersNotRelated += app.others.some(
          (other) => other.result === 'NOTRELATED' && other.isFromThisAppointment,
        )
          ? 1
          : 0
      }
      totalOthers = totalOthersNoShow + totalOthersExistingBooking + totalOthersNotRelated
    })
    return {
      totalQuotes,
      totalBookings,
      totalBookingsBrochure,
      totalBookingsDraft,
      totalBookingsOffered,
      totalBookingsConfirmed,
      totalBookingsCancelled,
      totalOthers,
      totalOthersNoShow,
      totalOthersExistingBooking,
      totalOthersNotRelated,
    }
  }
  const appointmentsData = checkAppointmentsData()

  const appointmentTypes = [
    { name: 'All appointments', total: '' },
    { name: 'Quotes', value: 'QUOTES', type: 'parent', total: appointmentsData.totalQuotes },
    { name: 'Bookings', value: 'BOOKINGS', type: 'parent', total: appointmentsData.totalBookings },
    {
      name: 'Brochure App Booking',
      value: 'BROCHURE',
      type: 'child',
      parent: 'BOOKINGS',
      total: appointmentsData.totalBookingsBrochure,
    },
    {
      name: 'Draft',
      value: 'DRAFT',
      type: 'child',
      parent: 'BOOKINGS',
      total: appointmentsData.totalBookingsDraft,
    },
    {
      name: 'Offered',
      value: 'OFFERED',
      type: 'child',
      parent: 'BOOKINGS',
      total: appointmentsData.totalBookingsOffered,
    },
    {
      name: 'Confirmed',
      value: 'CONFIRMED',
      type: 'child',
      parent: 'BOOKINGS',
      total: appointmentsData.totalBookingsConfirmed,
    },
    {
      name: 'Cancelled',
      value: 'CANCELLED',
      type: 'child',
      parent: 'BOOKINGS',
      total: appointmentsData.totalBookingsCancelled,
    },
    { name: 'Other Results', value: 'OTHERS', type: 'parent', total: appointmentsData.totalOthers },
    {
      name: 'No Show',
      value: 'NOSHOW',
      type: 'child',
      parent: 'OTHERS',
      total: appointmentsData.totalOthersNoShow,
    },
    {
      name: 'Existing Booking',
      value: 'EXISTINGBOOKING',
      type: 'child',
      parent: 'OTHERS',
      total: appointmentsData.totalOthersExistingBooking,
    },
    {
      name: 'Not Related',
      value: 'NOTRELATED',
      type: 'child',
      parent: 'OTHERS',
      total: appointmentsData.totalOthersNotRelated,
    },
  ]

  const agentOptions = [
    {
      name: 'All agents',
      value: '',
    },
  ]

  agents?.forEach((agent) => {
    agentOptions.push({
      name: `${agent.first_name} ${agent.last_name}`,
      value: agent.id,
    })
  })

  return (
    <Box component="form" display="flex" position="relative" justifyContent="flex-end">
      <Stack
        direction="row"
        gap={2}
        justifyContent="flex-end"
        alignItems="center"
        flexWrap="wrap"
        zIndex={5}
        sx={
          inLineTab
            ? {
                position: { xs: 'relative', lg: 'absolute' },
                right: '0',
                top: { xs: '0', lg: '-4px' },
                pb: { xs: 2, lg: 0 },
              }
            : {
                pt: 1,
                pb: 3,
                width: 1,
              }
        }>
        {displayHasPendingItems && (
          <FormGroup sx={{ flexDirection: 'row', flexWrap: 'nowrap', alignItems: 'center' }}>
            <FormControlLabel
              control={
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Checkbox
                    checked={hasPendingItems}
                    onChange={onChangeHasPendingItems}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  <EmojiFlagsOutlinedIcon
                    sx={{
                      color: theme.palette.custom.yellow,
                      mr: 1,
                    }}
                  />
                </Box>
              }
              label="Has pending items"
              sx={{ flexShrink: 0, mr: 1 }}
            />
          </FormGroup>
        )}
        {displayFilterByDate && onChangeKeywordDate && (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              label="Search by date"
              inputFormat="MM/DD/YYYY"
              value={keywordDate}
              onChange={(e) => {
                onChangeKeywordDate(e || null)
              }}
              renderInput={(params) => (
                <Box sx={{ position: 'relative', display: 'inline-block' }}>
                  <TextField {...params} error={false} size="small" />
                  <IconButton
                    sx={{
                      position: 'absolute',
                      top: '0px',
                      margin: 'auto',
                      marginLeft: '-4.5rem',
                    }}
                    onClick={() => onChangeKeywordDate(null)}>
                    <ClearIcon />
                  </IconButton>
                </Box>
              )}
            />
          </LocalizationProvider>
        )}
        {displayFilterByType && onChangeType && (
          <FormControl>
            <Select
              size="small"
              sx={{ width: 230 }}
              defaultValue="All appointments"
              onChange={(e) => {
                const typeName = e.target.value
                const obj = appointmentTypes.find((o) => o.name === typeName)
                if (typeName === 'All appointments') {
                  onChangeType('', '')
                } else if (obj?.type === 'parent' && obj.value !== undefined) {
                  onChangeType(obj.value, '')
                } else if (obj?.type === 'child' && obj.parent !== undefined) {
                  onChangeType(obj.parent, obj.value)
                }
              }}
              renderValue={(selected: string) => {
                const type = appointmentTypes.find((o) => o.name === selected)
                return (
                  <Stack direction="row" gap={1} justifyContent="space-between">
                    <Typography>{selected}</Typography>
                    <Typography color="gray">{type?.total}</Typography>
                  </Stack>
                )
              }}>
              {appointmentTypes.map((item) => (
                <MenuItem
                  sx={{ paddingLeft: item.type === 'child' ? 5 : '' }}
                  key={`type-${item.name}`}
                  value={item.name}>
                  <Stack direction="row" gap={1} justifyContent="space-between" width="100%">
                    <Typography variant="body2">{item.name}</Typography>
                    {!isFetching && (
                      <Typography variant="body2" color="gray">
                        {item.total}
                      </Typography>
                    )}
                  </Stack>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {displayFilterByAgent && onChangeAgent && !isAgent && (
          <FormControl>
            <Select
              size="small"
              sx={{ width: 230 }}
              defaultValue="All agents"
              onChange={(e) => {
                const obj = agentOptions.find((o) => o.name === e.target.value)
                onChangeAgent(obj!.value)
              }}
              renderValue={(selected: string) => (
                <Stack direction="row" gap={1} justifyContent="space-between">
                  <Typography>{selected}</Typography>
                </Stack>
              )}>
              {agentOptions.map((item) => (
                <MenuItem key={item.value} value={item.name}>
                  <Stack direction="row" gap={1} justifyContent="space-between" width="100%">
                    <Typography variant="body2">{item.name}</Typography>
                  </Stack>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <Box sx={{ position: 'relative', display: 'inline-block' }}>
          <TextField
            label="Search by keyword"
            onChange={(e) => {
              onChangeKeyword(e.target.value)
            }}
            variant="outlined"
            value={keyword}
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => onChangeKeyword('')}>
                  <ClearIcon />
                </IconButton>
              ),
            }}
            size="small"
          />
        </Box>
        <Box display="flex">
          {displayManageMultiple && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    onChange={(_, checked) => onChangeManageMultipleAppointment!(checked)}
                    checked={enableMultipleAppointments ?? false}
                  />
                }
                label="Manage multiple appointments"
              />
            </FormGroup>
          )}
          {displayManageMultiple && showMultipleAppointmentButton && (
            <Button variant="contained" color="success" onClick={openMultipleAppointments}>
              Open
            </Button>
          )}
        </Box>
      </Stack>
    </Box>
  )
}

export default AppointmentFilters
