import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Table from '@mui/material/Table'
import Button from '@mui/material/Button'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import Radio from '@mui/material/Radio'
import Tooltip from '@mui/material/Tooltip'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import Stack from '@mui/material/Stack'
import { headTableStyle } from '../../../styles/generic-styles'
import { IGuestsTableProps } from '../type'
import { theme } from '../../../styles/mui-theme'

const GuestsTable: React.FC<IGuestsTableProps> = ({
  persons,
  onSelectPerson,
  onAddUser,
  changeRadio,
  rowSelected,
  isAddUser,
}) => (
  <>
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={headTableStyle} />
            <TableCell sx={headTableStyle}>First Name</TableCell>
            <TableCell sx={headTableStyle}>Last Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {persons.map((person) => (
            <Tooltip
              title={
                Number(person.hasCardApplication) ? 'This guest already has a card application' : ''
              }>
              <TableRow key={`guests-table-${person.person_id}`}>
                <TableCell sx={{ padding: 0 }}>
                  {!Number(person.hasCardApplication) && (
                    <Radio
                      disabled={!!Number(person.hasCardApplication)}
                      checked={person.person_id === rowSelected}
                      onChange={() => changeRadio(person.person_id)}
                      value={person.person_id}
                      name="radio-buttons"
                      onClick={() => onSelectPerson(person)}
                    />
                  )}
                </TableCell>
                <TableCell
                  sx={{
                    color: Number(person.hasCardApplication) ? theme.palette.custom.gray : '',
                  }}>
                  {person.first_name}
                </TableCell>
                <TableCell
                  sx={{
                    color: Number(person.hasCardApplication) ? theme.palette.custom.gray : '',
                  }}>
                  {person.last_name}
                </TableCell>
              </TableRow>
            </Tooltip>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    {!isAddUser && (
      <Stack direction="row" justifyContent="flex-end" sx={{ mt: 1 }}>
        <Button
          variant="contained"
          size="small"
          startIcon={<AddCircleOutlineIcon />}
          onClick={onAddUser}>
          ENTER ANOTHER GUEST
        </Button>
      </Stack>
    )}
  </>
)

export default GuestsTable
