import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  IconButton,
  TextField,
  Tooltip,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import SearchIcon from '@mui/icons-material/Search'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import Stack from '@mui/material/Stack'
import Alert from '@mui/material/Alert'

import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { validationSchema } from './validations'
import { IGuestForm, IGuestFormProps } from './types'
import { theme } from '../../styles/mui-theme'
import { IPersonsByRoomResponse } from '../../types/persons'

const GuestForm: React.FC<IGuestFormProps> = (props) => {
  const {
    onAccept,
    guest: guestInfo,
    onSearchRoom,
    onReject,
    onDelete,
    searchedGuests,
    isFetching,
  } = props
  const [isOpenGuestPicker, setIsOpenGuestPicker] = useState<boolean>(false)

  const radioGroupRef = React.useRef<HTMLElement>(null)
  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue,
    getValues,
  } = useForm<IGuestForm>({
    resolver: yupResolver(validationSchema),
    defaultValues: { ...guestInfo },
  })

  const setDataInForm = (person: IPersonsByRoomResponse) => {
    setValue('firstName', person.first_name)
    setValue('lastName', person.last_name)
    setValue('validated', true)
    setValue('cardId', person.card_id ? `${person.card_id}` : null)
    setValue('cruiseBookingId', person.cruise_booking_id ? `${person.cruise_booking_id}` : null)
    setValue('gateway', person.gateway)
    setValue('guestId', person.guest_id ? `${person.guest_id}` : null)
    setValue('loyaltyLevel', person.loyaltyLevel)
    setValue('nationality', person.nationality)
  }

  const handleOnSearchRoom = () => {
    onSearchRoom(getValues('stateroom'))
    setIsOpenGuestPicker(true)
  }

  const handleDialogRadioGroupChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (searchedGuests) {
      if (searchedGuests?.length > 1) {
        searchedGuests.map((guest) => {
          if (guest.guest_id === (event.target as HTMLInputElement).value) {
            setDataInForm(guest)
          }
          return null
        })
      } else if (searchedGuests.length > 0) {
        setDataInForm(searchedGuests[0])
      }
    }
    setIsOpenGuestPicker(false)
  }

  const handleDialogClose = () => {
    setIsOpenGuestPicker(false)
  }

  useEffect(() => {
    if (searchedGuests) {
      if (searchedGuests.length === 1) {
        setDataInForm(searchedGuests[0])
        setIsOpenGuestPicker(false)
      }
    }
  }, [searchedGuests])

  return (
    <>
      {!isFetching && searchedGuests && searchedGuests?.length > 0 && (
        <Dialog open={isOpenGuestPicker} onClose={handleDialogClose}>
          <DialogTitle>Please select a guest</DialogTitle>
          <DialogContent>
            <RadioGroup
              ref={radioGroupRef}
              aria-label="ringtone"
              name="ringtone"
              onChange={handleDialogRadioGroupChange}>
              {searchedGuests != null &&
                searchedGuests.map((guest) => (
                  <FormControlLabel
                    value={`${guest.guest_id}`}
                    key={guest.guest_id}
                    control={<Radio />}
                    label={`${guest.first_name}  ${guest.last_name}`}
                  />
                ))}
            </RadioGroup>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleDialogClose}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Box component="form" onSubmit={handleSubmit(onAccept)} noValidate>
        <Stack direction="column" rowGap={1}>
          <Stack direction="row" columnGap={2} alignItems="center" justifyContent="space-between">
            <TextField
              autoFocus
              margin="normal"
              fullWidth
              id="stateroom"
              label="Stateroom"
              type="number"
              error={!!errors.stateroom}
              helperText={errors?.stateroom?.message as string}
              sx={{
                maxWidth: '9rem',
              }}
              {...register('stateroom', { required: true })}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  handleOnSearchRoom()
                }
              }}
            />
            <Tooltip title="Looking up stateroom">
              <IconButton
                onClick={handleOnSearchRoom}
                sx={{ marginTop: '0.8rem', height: '2.5rem', width: '2.5rem' }}>
                {isFetching ? <CircularProgress size={theme.spacing(3)} /> : <SearchIcon />}
              </IconButton>
            </Tooltip>
            <TextField
              autoFocus
              margin="normal"
              fullWidth
              id="firstName"
              label="First Name"
              type="text"
              error={!!errors.firstName}
              helperText={errors?.firstName?.message as string}
              {...register('firstName', { required: true })}
            />
            <TextField
              autoFocus
              margin="normal"
              fullWidth
              id="lastName"
              label="Last Name"
              type="text"
              error={!!errors.lastName}
              helperText={errors?.lastName?.message as string}
              {...register('lastName', { required: true })}
            />
            <TextField sx={{ display: 'none' }} id="cardId" type="hidden" {...register('cardId')} />
            <TextField
              sx={{ display: 'none' }}
              id="cruiseBookingId"
              type="hidden"
              {...register('cruiseBookingId')}
            />
            <TextField
              sx={{ display: 'none' }}
              id="gateway"
              type="hidden"
              {...register('gateway')}
            />
            <TextField
              sx={{ display: 'none' }}
              id="guestId"
              type="hidden"
              {...register('guestId')}
            />
            <TextField
              sx={{ display: 'none' }}
              id="loyaltyLevel"
              type="hidden"
              {...register('loyaltyLevel')}
            />
            <TextField
              sx={{ display: 'none' }}
              id="nationality"
              type="hidden"
              {...register('nationality')}
            />
          </Stack>
          <FormControlLabel
            sx={{ maxWidth: '19rem', pl: '5px' }}
            control={<input type="checkbox" {...register('validated')} style={{ width: '20px' }} />}
            label="Guest data has been validated"
          />
          {isOpenGuestPicker && searchedGuests?.length === 0 && (
            <Alert sx={{ mt: '5px', mb: '5px' }} severity="warning">
              Guest information cannot be retrieved. Please validate manually.
            </Alert>
          )}
          <Stack direction="row" justifyContent="space-between">
            <Button
              startIcon={<DeleteForeverIcon fontSize="small" />}
              variant="contained"
              color="error"
              size="small"
              onClick={onDelete}>
              Delete Appointment
            </Button>

            <Stack direction="row" columnGap={2}>
              <Button size="small" onClick={onReject}>
                Cancel
              </Button>
              <Button variant="contained" type="submit" color="success" size="small">
                Save
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </>
  )
}

export default GuestForm
