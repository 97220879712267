import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from './baseQuery'
import { IGenericResponse } from '../../types/generic'
import { IBookingPayload, IGroupBookingPayload } from '../../types/bookings'

export const bookingsApi = createApi({
  reducerPath: 'bookingsApi',
  baseQuery,
  endpoints: (builder) => ({
    create: builder.mutation<IGenericResponse, IBookingPayload>({
      query: ({ appointmentId, ...rest }) => ({
        url: `/bookings/${appointmentId}`,
        method: 'POST',
        body: rest,
      }),
    }),
    createGroup: builder.mutation<IGenericResponse, IGroupBookingPayload>({
      query: ({ appointmentId, ...rest }) => ({
        url: `/bookings/${appointmentId}/group`,
        method: 'POST',
        body: rest,
      }),
    }),
    edit: builder.mutation<IGenericResponse, IBookingPayload>({
      query: ({ bookingId, ...rest }) => ({
        url: `/bookings/${bookingId}`,
        method: 'PUT',
        body: rest,
      }),
    }),
  }),
})

export const { useCreateMutation, useCreateGroupMutation, useEditMutation } = bookingsApi
