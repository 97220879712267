import Autocomplete from '@mui/material/Autocomplete'
import Fab from '@mui/material/Fab'
import Grid from '@mui/material/Grid'
import TableCell from '@mui/material/TableCell'
import TextField from '@mui/material/TextField'
import { Controller, useFieldArray } from 'react-hook-form'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { INestedCallbackTimesFormProps } from './types'
import { MAX_INTEGER } from '../../utils/constants'

const NestedCallbackTimesForm: React.FC<INestedCallbackTimesFormProps> = ({
  control,
  index,
  hoursOptions,
  formState,
  setTimes,
  setCallbacksPerHour,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: `callbacks.${index}.hours`,
  })

  return (
    <TableCell>
      {fields.map((item, itemIndex) => (
        <Grid
          key={`nested-callback-times-${item.id}`}
          alignItems="center"
          sx={{ marginBottom: itemIndex === fields.length - 1 ? 0 : 2 }}
          container
          spacing={2}>
          <Grid item xs={7}>
            <Controller
              control={control}
              name={`callbacks.${index}.hours.${itemIndex}`}
              render={({ field: { onChange, ...field } }) => (
                <Autocomplete
                  options={hoursOptions}
                  getOptionLabel={(option) => `${option.openHour} - ${option.closeHour}`}
                  onChange={(_, option) => {
                    setTimes(index, itemIndex, option?.openHour ?? '', option?.closeHour ?? '')
                  }}
                  defaultValue={
                    item.openHour
                      ? { openHour: item.openHour, closeHour: item.closeHour }
                      : undefined
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.openHour === value.openHour && option.closeHour === value.closeHour
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...field}
                      label="Hour"
                      fullWidth
                      error={!!formState.errors.callbacks?.[index]?.hours?.[itemIndex]?.openHour}
                      helperText={
                        formState.errors?.callbacks?.[index]?.hours?.[itemIndex]?.openHour
                          ?.message as string
                      }
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name={`callbacks.${index}.hours.${itemIndex}.callbacksPerHour`}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Callbacks"
                  fullWidth
                  type="number"
                  value={field.value.toString()}
                  onChange={(event) => {
                    const value = parseInt(event.target.value, 10)
                    setCallbacksPerHour(
                      index,
                      itemIndex,
                      Number.isNaN(value) || value < 0 ? 0 : value,
                    )
                  }}
                  error={
                    !!formState.errors.callbacks?.[index]?.hours?.[itemIndex]?.callbacksPerHour
                  }
                  helperText={
                    formState.errors?.callbacks?.[index]?.hours?.[itemIndex]?.callbacksPerHour
                      ?.message as string
                  }
                  InputProps={{
                    inputProps: { max: MAX_INTEGER, min: 1 },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={1}>
            {itemIndex === 0 ? (
              <Fab
                size="small"
                color="success"
                onClick={() => append({ closeHour: '', openHour: '', callbacksPerHour: 0 })}>
                <AddIcon />
              </Fab>
            ) : (
              <Fab size="small" color="error" onClick={() => remove(itemIndex)}>
                <DeleteIcon />
              </Fab>
            )}
          </Grid>
        </Grid>
      ))}
    </TableCell>
  )
}

export default NestedCallbackTimesForm
