import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { validationSchema } from './validations'
import { IProductFormProps } from './types'
import { IDestinationSimple } from '../../types/destinations'

const ProductForm = (props: IProductFormProps) => {
  const { product, isOpen, isLoading, onAccept, onReject } = props
  const isEdit = !!product?.id

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<IDestinationSimple>({
    resolver: yupResolver(validationSchema),
    defaultValues: { ...product },
  })

  const title = product?.id ? 'Edit Product' : 'New Product'
  let message = 'Please provide the information below.'
  message += product?.id
    ? ` Remember the information must be consistent among all vessels.`
    : ` Remember the ID must be unique and consistent among all vessels.`

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <Box component="form" onSubmit={handleSubmit(onAccept)} noValidate>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
          <TextField
            autoFocus
            margin="normal"
            fullWidth
            id="id"
            label="ID"
            type="number"
            error={!!errors.id}
            helperText={errors?.id?.message as string}
            {...register('id', { required: true })}
            disabled={isEdit}
          />
          <TextField
            margin="normal"
            fullWidth
            id="name"
            label="Name"
            error={!!errors.name}
            helperText={errors?.name?.message as string}
            {...register('name', { required: true })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onReject}>Cancel</Button>
          <LoadingButton type="submit" loading={isLoading}>
            Save
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default ProductForm
