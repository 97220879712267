import { ILoginPayload, ILoginResponse } from './types'
import { useDoLoginMutation } from '../../redux/api/login'
import { setLoginToken } from '../../redux/features/auth'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'

export const useLogin = () => {
  const [doLogin, { isLoading }] = useDoLoginMutation()

  const dispatch = useAppDispatch()

  const token = useAppSelector((state) => state.auth.token)
  const loggedUser = useAppSelector((state) => state.auth.loggedUser)

  const invokeEndpoint = async (
    values: ILoginPayload,
    onError: (message: string) => void,
  ): Promise<void> => {
    const loginResponse: ILoginResponse = await doLogin(values).unwrap()

    if (!loginResponse.success) {
      onError(loginResponse.message)
      return
    }

    dispatch(setLoginToken({ token: loginResponse?.token, csrfToken: loginResponse.csrfToken }))
  }

  return {
    isLoading,
    loggedUser,
    token,
    invokeEndpoint,
  }
}
