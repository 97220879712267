import { useState } from 'react'
import moment from 'moment-timezone'
import { Box, Button, Chip, Fab, Stack, Tooltip, Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteIcon from '@mui/icons-material/Delete'
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import ReplayIcon from '@mui/icons-material/Replay'
import { EConfirmPortType, IConfirmDialogProps } from './types'
import { usePorts } from './hook'
import { CellStyles } from './styles'
import { IPort } from '../../../types/ports'
import SettingsLayout from '../../../components/SettingsLayout'
import DynamicDataGrid from '../../../components/DynamicDataGrid'
import ConfirmDialog from '../../../components/ConfirmDialog'
import PortForm from '../../../components/PortForm'

const SettingsPorts = () => {
  const { ports, isLoading, archive, remove, refetchPorts, createEdit } = usePorts()

  const [port, setPort] = useState<IPort | undefined>()
  const [isOpenPortDialog, setIsOpenPortDialog] = useState(false)
  const [isLoadingPort, setIsLoadingPort] = useState(false)

  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const [confirmProps, setConfirmProps] = useState<IConfirmDialogProps>()

  const onConfirmCompleted = () => {
    setIsConfirmOpen(false)
  }

  const confirmDialogDrivers = (id: number) => ({
    [EConfirmPortType.ARCHIVE_PORT]: {
      title: 'Archive Port',
      message: 'Are you sure you want to archive this port?',
      onAccept: async () => {
        await archive({ port_id: id, isDeprecated: true }, onConfirmCompleted, () =>
          setIsConfirmOpen(false),
        )
        refetchPorts()
      },
    },
    [EConfirmPortType.REMOVE_PORT]: {
      title: 'Delete Port',
      message:
        'Are you sure you want to remove this port? Please notice this action cannot be undone.',
      onAccept: async () => {
        await remove(id, onConfirmCompleted, () => setIsConfirmOpen(false))
        refetchPorts()
      },
    },
    [EConfirmPortType.RESTORE_PORT]: {
      title: 'Restore Port',
      message: 'Are you sure you want to restore this port?',
      onAccept: async () => {
        await archive({ port_id: id, isDeprecated: false }, onConfirmCompleted, () =>
          setIsConfirmOpen(false),
        )
        refetchPorts()
      },
    },
  })

  const createPort = () => {
    setPort(undefined)
    setIsOpenPortDialog(true)
  }

  const editPort = (item: IPort) => {
    setPort(item)
    setIsOpenPortDialog(true)
  }

  const onPortSaved = () => {
    setIsLoadingPort(false)
    setIsOpenPortDialog(false)
  }

  const onPortError = () => {
    setIsLoadingPort(false)
  }

  const onAcceptPort = async (thePort: IPort): Promise<void> => {
    setIsLoadingPort(true)
    await createEdit(
      {
        port_id: thePort.port_id,
        name: thePort.name,
        timezone: thePort.timezone,
        timezoneOffset:
          thePort.timezone !== '' ? moment().tz(thePort.timezone).utcOffset().toString() : '',
        isEdit: !!port,
      },
      onPortSaved,
      onPortError,
    )
    refetchPorts()
  }

  const openConfirmDialog = (option: EConfirmPortType, id: number) => {
    setIsConfirmOpen(true)
    setConfirmProps(confirmDialogDrivers(id)[option])
  }

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 70,
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ id, row }) => <CellStyles disable={row.isDeleted}>{id}</CellStyles>,
    },
    {
      field: 'name',
      headerName: 'PORT NAME',
      flex: 1,
      cellClassName: 'product-cell',
      renderCell: ({ row }) => (
        <>
          <CellStyles m="1rem 0" disable={row.isDeleted}>
            {row.name}
          </CellStyles>
          {row.isDeprecated > 0 && <Chip label="Archived" sx={{ ml: 1 }} />}
          {row.isDeleted > 0 && <Chip label="Deleted" sx={{ ml: 1 }} />}
        </>
      ),
    },
    {
      field: 'timezone',
      headerName: 'TIMEZONE',
      flex: 1,
      renderCell: ({ row }) => <CellStyles disable={row.isDeleted}>{row.timezone}</CellStyles>,
    },
    {
      field: 'actions',
      headerName: '',
      align: 'right',
      width: 200,
      sortable: false,
      renderCell: ({ id, row }) => {
        const displayEditArchive = !row.isDeleted && !row.isDeprecated
        const displayRestore = row.isDeleted || row.isDeprecated
        return (
          <>
            {displayEditArchive && (
              <>
                <Tooltip title="Edit Port" sx={{ mr: 1 }}>
                  <Fab
                    color="secondary"
                    size="small"
                    onClick={() => editPort({ port_id: id, ...row })}>
                    <EditOutlinedIcon />
                  </Fab>
                </Tooltip>
                <Tooltip title="Archive Port" sx={{ mr: 1 }}>
                  <Fab
                    color="secondary"
                    size="small"
                    onClick={() => openConfirmDialog(EConfirmPortType.ARCHIVE_PORT, Number(id))}>
                    <ArchiveOutlinedIcon />
                  </Fab>
                </Tooltip>
                <Tooltip title="Delete Port">
                  <Fab
                    color="error"
                    size="small"
                    onClick={() => openConfirmDialog(EConfirmPortType.REMOVE_PORT, Number(id))}>
                    <DeleteIcon />
                  </Fab>
                </Tooltip>
              </>
            )}
            {displayRestore ? (
              <Tooltip title="Restore Port" sx={{ mr: 1 }}>
                <Fab
                  sx={{ display: 'flex' }}
                  color="inherit"
                  size="small"
                  onClick={() => openConfirmDialog(EConfirmPortType.RESTORE_PORT, Number(id))}>
                  <ReplayIcon />
                </Fab>
              </Tooltip>
            ) : null}
          </>
        )
      },
    },
  ]

  return (
    <SettingsLayout>
      <Stack direction="row" justifyContent="space-between" sx={{ pb: 2 }}>
        <Box>
          <Button
            variant="contained"
            color="success"
            startIcon={<AddCircleOutlineIcon />}
            onClick={createPort}>
            New Port
          </Button>
        </Box>
        <Typography variant="h4" color="primary" sx={{ display: { xs: 'none', md: 'flex' } }}>
          Ports Management
        </Typography>
      </Stack>

      <DynamicDataGrid
        rows={ports ? ports.map((item: IPort) => ({ id: item.port_id, ...item })) : []}
        columns={columns}
        isLoading={isLoading}
        emptyMessage="No ports created yet."
      />

      <PortForm
        key={`form-port-${port?.port_id || isOpenPortDialog}`}
        isOpen={isOpenPortDialog}
        isLoading={isLoadingPort}
        port={port}
        onAccept={onAcceptPort}
        onReject={() => setIsOpenPortDialog(false)}
      />

      <ConfirmDialog
        title={confirmProps?.title!}
        message={confirmProps?.message}
        onAccept={confirmProps?.onAccept!}
        onReject={() => {
          setIsConfirmOpen(false)
        }}
        isOpen={isConfirmOpen}
      />
    </SettingsLayout>
  )
}

export default SettingsPorts
