import { useState } from 'react'
import moment from 'moment-timezone'
import { Box, Button, Fab, Stack, Tooltip, Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteIcon from '@mui/icons-material/Delete'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { EConfirmInitiativeType, IConfirmDialogProps } from './types'
import { useMarketingInitiatives } from './hook'
import { CellStyles } from './styles'
import SettingsLayout from '../../../components/SettingsLayout'
import DynamicDataGrid from '../../../components/DynamicDataGrid'
import ConfirmDialog from '../../../components/ConfirmDialog'
import InitiativeForm from '../../../components/InitiativeForm'
import { IInitiative } from '../../../types/marketingInitiatives'
import { useNewVoyage } from '../Voyages/NewVoyage/hook'
import { useAppSelector } from '../../../redux/hooks'
import { RootState } from '../../../redux/store'

const SettingsInitiatives = () => {
  const { vessels } = useNewVoyage()
  const { initiatives, isLoading, remove, refetchInitiatives, createEdit } =
    useMarketingInitiatives()

  const [initiative, setInitiative] = useState<IInitiative | undefined>()
  const [isOpenInitiativeDialog, setIsOpenInitiativeDialog] = useState(false)
  const [isLoadingInitiative, setIsLoadingInitiative] = useState(false)

  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const [confirmProps, setConfirmProps] = useState<IConfirmDialogProps>()

  const activeCruiseId = useAppSelector((state: RootState) => state.auth.activeCruise?.cruise_id)

  const onConfirmCompleted = () => {
    setIsConfirmOpen(false)
  }

  const confirmDialogDrivers = (id: number) => ({
    [EConfirmInitiativeType.REMOVE_INITIATIVE]: {
      title: 'Delete Initiative',
      message:
        'Are you sure you want to remove this initiative? Please notice this action cannot be undone.',
      onAccept: async () => {
        await remove(id, onConfirmCompleted, () => setIsConfirmOpen(false))
        refetchInitiatives()
      },
    },
  })

  const createInitiative = () => {
    setInitiative(undefined)
    setIsOpenInitiativeDialog(true)
  }

  const editInitiative = (item: IInitiative) => {
    setInitiative(item)
    setIsOpenInitiativeDialog(true)
  }

  const onInitiativeSaved = () => {
    setIsLoadingInitiative(false)
    setIsOpenInitiativeDialog(false)
  }

  const onInitiativeError = () => {
    setIsLoadingInitiative(false)
  }

  const onAcceptInitiative = async (theInitiative: IInitiative): Promise<void> => {
    setIsLoadingInitiative(true)
    await createEdit(
      {
        id: theInitiative.initiative_id,
        type: theInitiative.initiative_type,
        product: theInitiative.product,
        shipId: Number(theInitiative.ship_id),
        date: theInitiative.date,
        isEdit: !!initiative,
        cruiseId: activeCruiseId!,
      },
      onInitiativeSaved,
      onInitiativeError,
    )
    refetchInitiatives()
  }

  const openConfirmDialog = (option: EConfirmInitiativeType, id: number) => {
    setIsConfirmOpen(true)
    setConfirmProps(confirmDialogDrivers(id)[option])
  }

  const columns: GridColDef[] = [
    {
      field: 'initiative_type',
      headerName: 'INITIATIVE',
      flex: 2,
      cellClassName: 'initiative-cell',
      renderCell: ({ row }) => (
        <CellStyles m="1rem 0" disable={false}>
          {row.initiative_type}
        </CellStyles>
      ),
    },
    {
      field: 'product',
      headerName: 'PRODUCT',
      flex: 2,
      renderCell: ({ row }) => (
        <CellStyles m="1rem 0" disable={false}>
          {row.product}
        </CellStyles>
      ),
    },
    {
      field: 'ship_id',
      headerName: 'SHIP',
      flex: 1,
      renderCell: ({ row }) => <CellStyles disable={false}>{row.ship_name}</CellStyles>,
    },
    {
      field: 'date',
      headerName: 'DATE',
      width: 80,
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ row }) => (
        <CellStyles disable={false}>{moment(row.date).format('MMM D')}</CellStyles>
      ),
    },
    {
      field: 'actions',
      headerName: '',
      align: 'right',
      width: 180,
      sortable: false,
      renderCell: ({ id, row }) => (
        <>
          <Tooltip title="Edit Initiative" sx={{ mr: 1 }}>
            <Fab
              color="secondary"
              size="small"
              onClick={() => editInitiative({ initiative_id: id, ...row })}>
              <EditOutlinedIcon />
            </Fab>
          </Tooltip>
          <Tooltip title="Delete Initiative">
            <Fab
              color="error"
              size="small"
              onClick={() =>
                openConfirmDialog(EConfirmInitiativeType.REMOVE_INITIATIVE, Number(id))
              }>
              <DeleteIcon />
            </Fab>
          </Tooltip>
        </>
      ),
    },
  ]

  return (
    <SettingsLayout>
      <Stack direction="row" justifyContent="space-between" sx={{ pb: 2 }}>
        <Box>
          <Button
            variant="contained"
            color="success"
            startIcon={<AddCircleOutlineIcon />}
            onClick={createInitiative}>
            New Initiative
          </Button>
        </Box>
        <Typography variant="h4" color="primary" sx={{ display: { xs: 'none', md: 'flex' } }}>
          Initiatives Management
        </Typography>
      </Stack>

      <DynamicDataGrid
        rows={
          initiatives
            ? initiatives.map((item: IInitiative) => ({ id: item.initiative_id, ...item }))
            : []
        }
        columns={columns}
        isLoading={isLoading}
        hideFooter
        emptyMessage="No initiatives created yet."
      />

      <InitiativeForm
        key={`form-initiative-${initiative?.initiative_id || isOpenInitiativeDialog}`}
        isOpen={isOpenInitiativeDialog}
        isLoading={isLoadingInitiative}
        initiative={initiative}
        vessels={vessels || []}
        onAccept={onAcceptInitiative}
        onReject={() => setIsOpenInitiativeDialog(false)}
      />

      <ConfirmDialog
        title={confirmProps?.title!}
        message={confirmProps?.message}
        onAccept={confirmProps?.onAccept!}
        onReject={() => {
          setIsConfirmOpen(false)
        }}
        isOpen={isConfirmOpen}
      />
    </SettingsLayout>
  )
}

export default SettingsInitiatives
