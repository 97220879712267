import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  TextField,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import moment from 'moment-timezone'
import { validationSchema } from './validations'
import { IPortFormProps } from './types'
import { IPort } from '../../types/ports'

const PortForm = (props: IPortFormProps) => {
  const { port, isOpen, isLoading, onAccept, onReject } = props
  const isEdit = !!port?.port_id

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<IPort>({
    resolver: yupResolver(validationSchema),
    defaultValues: { ...port },
  })

  const title = port?.port_id ? 'Edit Port' : 'New Port'
  let message = 'Please provide the information below.'
  message += port?.port_id
    ? ` Remember the information must be consistent among all vessels.`
    : ` Remember the ID must be unique and consistent among all vessels.`

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <Box component="form" onSubmit={handleSubmit(onAccept)} noValidate>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
          <TextField
            autoFocus
            margin="normal"
            fullWidth
            id="id"
            label="ID"
            type="number"
            error={!!errors.port_id}
            helperText={errors?.port_id?.message as string}
            {...register('port_id', { required: true })}
            disabled={isEdit}
          />
          <TextField
            margin="normal"
            fullWidth
            id="name"
            label="Name"
            error={!!errors.name}
            helperText={errors?.name?.message as string}
            {...register('name', { required: true })}
          />
          <TextField
            select
            margin="normal"
            fullWidth
            id="timezone"
            label="Timezone"
            error={!!errors.timezone}
            defaultValue={port?.timezone || ''}
            helperText={errors?.timezone?.message as string}
            {...register('timezone', { required: false })}>
            <MenuItem value="">- None -</MenuItem>
            {moment.tz.names().map((tz) => (
              <MenuItem key={`key-port-${tz}`} value={tz}>
                {tz}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={onReject}>Cancel</Button>
          <LoadingButton type="submit" loading={isLoading}>
            Save
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default PortForm
