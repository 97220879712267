export interface IBookingPayload {
  appointmentId?: number
  destination_id?: number
  ship_id?: number
  booking_number: string
  booking_name?: string
  amount?: number
  stateroom_category?: string
  sail_date?: string
  status: string
  agent_id?: string
  comment?: string
  origin?: string
  group_name?: string
  reason_booking_cancelled?: string
  non_refundable?: boolean
  used_visa_card?: boolean
  pending_items?: boolean
  bookingId?: number
  booking_type: string
  booking_brand: string
  guest_category: string
  deposit_amount?: number
  deposit_currency?: string
}

export interface IGroupBookingPayload {
  appointmentId?: number
  agent_id: string
  booking_name: string
  comment: string
  destination_id: number
  group_booking_items: IGroupBookingItem[]
  group_shell_number: string
  non_refundable: boolean
  pending_items: boolean
  origin: string
  group_name?: string
  reason_booking_cancelled?: string
  sail_date?: string
  ship_id: number
  status: string
  booking_type: string
  booking_brand: string
  guest_category: string
}

export interface IGroupBookingItem {
  amount: number
  booking_number: string
  stateroom_category: string
}

export enum EBookingType {
  BOOK_NOW = 'Book Now',
  BOOK_LATER = 'Book Later',
  APP_BOOKING = 'App Booking',
}
