import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from './baseQuery'
import { IArchiveShipPayload, IShip, IShipPayload } from '../../types/ships'
import { IGenericResponse } from '../../types/generic'

export const shipsApi = createApi({
  reducerPath: 'shipsApi',
  baseQuery,
  endpoints: (builder) => ({
    createEdit: builder.mutation<IGenericResponse, IShipPayload>({
      query: (payload) => ({
        url: '/ships',
        method: 'POST',
        body: payload,
      }),
    }),
    archive: builder.mutation<IGenericResponse, IArchiveShipPayload>({
      query: ({ id, action }) => ({
        url: `/ships/${id}/${action}`,
        method: 'PUT',
      }),
    }),
    remove: builder.mutation<IGenericResponse, number>({
      query: (id) => ({
        url: `/ships/${id}`,
        method: 'DELETE',
      }),
    }),
    get: builder.query<IShip[], void>({
      query: () => ({
        url: `/ships`,
        method: 'GET',
      }),
    }),
  }),
})

export const { useCreateEditMutation, useArchiveMutation, useRemoveMutation, useGetQuery } =
  shipsApi
