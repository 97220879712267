import { Badge, Box, Stack, Tab } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { ITabsContainerProps } from './types'
import { theme } from '../../styles/mui-theme'
import { tabContainerStyle } from '../../styles/generic-styles'

const TabsContainer: React.FC<ITabsContainerProps> = ({ tabs, selectedTab, onChangeTab }) => {
  const tabsLeftSide = tabs
    .filter((tab) => tab.position === 'left')
    .map((tab, index) => (
      <Tab
        sx={tabContainerStyle}
        key={`tab-left-${tab.title}-${index}`}
        label={
          <Badge
            badgeContent={tab.badge}
            sx={{
              paddingRight: tab.badge ? 2 : 0,
              '.MuiBadge-badge': {
                backgroundColor: tab.badgeColor,
                color: theme.palette.background.paper,
              },
            }}>
            {tab.title}
          </Badge>
        }
        value={tab.title}
      />
    ))

  const tabsRightSide = tabs
    .filter((tab) => tab.position === 'right')
    .map((tab, index) => (
      <Tab
        key={`tab-right-${tab.title}-${index}`}
        label={
          <Badge
            badgeContent={tab.badge}
            sx={{
              paddingRight: tab.badge ? 2 : 0,
              '.MuiBadge-badge': {
                backgroundColor: tab.badgeColor,
                color: theme.palette.background.paper,
              },
            }}>
            {tab.title}
          </Badge>
        }
        sx={{
          marginLeft: 'auto',
        }}
        value={tab.title}
      />
    ))

  return (
    <TabContext value={selectedTab}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Stack direction="row" justifyContent="space-between" width="100%">
          <TabList sx={{ ...tabContainerStyle, width: '100%' }} onChange={onChangeTab}>
            {tabsLeftSide}
            {tabsRightSide}
          </TabList>
        </Stack>
      </Box>
      {tabs.map((tab, index) => (
        <TabPanel
          key={`tab-panel-${tab.title}-${index}`}
          value={tab.title}
          sx={{ padding: '1rem 0px' }}>
          {tab.content}
        </TabPanel>
      ))}
    </TabContext>
  )
}

export default TabsContainer
