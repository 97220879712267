import { useRef, useEffect } from 'react'
import { useIsomorphicLayoutEffect } from 'usehooks-ts'

export const useInterval = (callback: () => void, delay: number | null) => {
  const savedCallback = useRef(callback)

  useIsomorphicLayoutEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    if (!delay && delay !== 0) {
      return
    }

    setInterval(() => savedCallback.current(), delay)
  }, [delay])
}
