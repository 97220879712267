import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  MenuItem,
  Switch,
  TextField,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { useState } from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { validationSchema, editValidationSchema } from './validations'
import { IUser, IUserFormProps } from './types'
import { useAppSelector } from '../../redux/hooks'
import { EUserRole } from '../../types/users'

const UserForm = (props: IUserFormProps) => {
  const { user, isOpen, isLoading, onAccept, onReject } = props
  const [showPassword, setShowPassword] = useState(false)
  const userData = useAppSelector((state) => state.auth.loggedUser)

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<IUser>({
    resolver: yupResolver(!user ? validationSchema : editValidationSchema),
    defaultValues: { ...user, isEnabled: (user?.isEnabled as unknown as number) === 1 },
  })

  const title = user?.id ? 'Edit User' : 'New User'

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <Box component="form" onSubmit={handleSubmit(onAccept)} noValidate>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>Please provide the information below.</DialogContentText>
          <TextField
            autoFocus
            margin="normal"
            fullWidth
            id="firstName"
            label="First Name"
            error={!!errors.firstName}
            helperText={errors?.firstName?.message as string}
            {...register('firstName', { required: true })}
          />
          <TextField
            margin="normal"
            fullWidth
            id="lastName"
            label="Last Name"
            error={!!errors.lastName}
            helperText={errors?.lastName?.message as string}
            {...register('lastName', { required: true })}
          />
          <TextField
            margin="normal"
            fullWidth
            id="username"
            label="User ID"
            error={!!errors.userName}
            helperText={errors?.userName?.message as string}
            {...register('userName', { required: true })}
          />
          {!user && (
            <TextField
              margin="normal"
              fullWidth
              id="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              error={!!errors.password}
              helperText={errors?.password?.message as string}
              {...register('password', { required: true })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={() => setShowPassword(!showPassword)}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
          <TextField
            select
            margin="normal"
            fullWidth
            id="role"
            label="Role"
            error={!!errors.role}
            helperText={errors?.role?.message as string}
            defaultValue={user?.role || ''}
            {...register('role', { required: true })}>
            <MenuItem value="agent">Agent</MenuItem>
            <MenuItem value="admin">Manager</MenuItem>
            {userData?.role === EUserRole.SUPER_ADMIN && (
              <MenuItem value="superadmin">Super Admin</MenuItem>
            )}
          </TextField>
          <FormGroup>
            <Controller
              control={control}
              name="isEnabled"
              render={({ field: { value, ...field } }) => (
                <FormControlLabel
                  control={<Switch id="isEnabled" checked={!!value} {...field} />}
                  label="Is Enabled"
                />
              )}
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={onReject}>Cancel</Button>
          <LoadingButton type="submit" loading={isLoading}>
            Save
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default UserForm
