import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import type { RootState } from '../store'

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers, { getState }) => {
    const authorizationHeader = headers.get('authorization')
    const csrfHeader = headers.get('xsrf-token')
    const { token, csrfToken } = (getState() as RootState).auth
    if (token && !authorizationHeader) headers.set('authorization', `Bearer ${token}`)
    if (csrfToken && !csrfHeader) headers.set('xsrf-token', csrfToken ?? '')
    return headers
  },
})
