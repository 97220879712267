export enum EQueueAction {
  OPEN = 'open',
  CLOSE = 'close',
  ENABLE = 'enable',
  DISABLE = 'disable',
}

export interface IQueueResponse {
  id: number
  name: string
  status: boolean
  active: boolean
}

export interface IUpdateQueuePayload {
  id: number
  action: EQueueAction
}
