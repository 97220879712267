import {
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import { IAttendeesDialogProps } from './types'
import { headTableStyle } from '../../styles/generic-styles'

const AttendeesDialog: React.FC<IAttendeesDialogProps> = ({
  onClose,
  isOpen,
  participants = [],
  onDownloadCSV,
}) => (
  <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
    <DialogTitle>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        Event Attendees
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
    </DialogTitle>
    <DialogContent>
      <Button
        variant="contained"
        color="success"
        startIcon={<GetAppOutlinedIcon />}
        onClick={onDownloadCSV}>
        Export Attendees
      </Button>
      <TableContainer sx={{ marginTop: 2, marginBottom: 2 }}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={headTableStyle}>FIRST NAME</TableCell>
              <TableCell sx={headTableStyle}>LAST NAME</TableCell>
              <TableCell sx={headTableStyle} align="center">
                STATE ROOM
              </TableCell>
              <TableCell sx={headTableStyle}>FOLIO</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {participants.map((participant) => (
              <TableRow key={`cell-participant-${participant.passenger_first_name}`}>
                <TableCell sx={{ textTransform: 'capitalize' }}>
                  {participant.passenger_first_name.toLowerCase()}
                </TableCell>
                <TableCell sx={{ textTransform: 'capitalize' }}>
                  {participant.passenger_last_name.toLowerCase()}
                </TableCell>
                <TableCell align="center">
                  {participant.passenger_stateRoom ? (
                    participant.passenger_stateRoom
                  ) : (
                    <Typography color="error">UNK</Typography>
                  )}
                </TableCell>
                <TableCell>{participant.folioNumberFromCard}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Alert color="info">
        UNK: There is no guest room information received from guest validation webservice.
      </Alert>
    </DialogContent>
  </Dialog>
)

export default AttendeesDialog
