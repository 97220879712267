import { useGetQuery } from '../../../../redux/api/destinations'
import { useGetQuery as useGetVesselsQuery } from '../../../../redux/api/ships'
import { useGetQuery as useGetPortsQuery } from '../../../../redux/api/ports'
import { useCreateCruiseMutation } from '../../../../redux/api/cruises'
import { ICreateCruisePayload } from '../../../../types/cruises'

export const useNewVoyage = () => {
  const { data: productsFromQuery } = useGetQuery()
  const { data: vesselsFromQuery } = useGetVesselsQuery()
  const { data: portsFromQuery } = useGetPortsQuery()
  const [createCruise, { isLoading }] = useCreateCruiseMutation()

  const products = productsFromQuery
    ?.filter((product) => !product.isDeleted && !product.isDeprecated)
    .sort((a, b) => {
      if (a.name > b.name) return 1
      if (a.name < b.name) return -1
      return 0
    })

  const vessels = vesselsFromQuery
    ?.filter((vessel) => !vessel.isDeleted && !vessel.isDeprecated)
    .sort((a, b) => {
      if (a.name > b.name) return 1
      if (a.name < b.name) return -1
      return 0
    })

  const ports = portsFromQuery
    ?.filter((port) => !port.isDeleted && !port.isDeprecated)
    .sort((a, b) => {
      if (a.name > b.name) return 1
      if (a.name < b.name) return -1
      return 0
    })

  const create = async (data: ICreateCruisePayload, onSuccess: () => void) => {
    try {
      await createCruise(data).unwrap()
      onSuccess()
    } catch (error) {
      console.error(error)
    }
  }

  return {
    products,
    vessels,
    ports,
    isLoading,
    create,
  }
}
