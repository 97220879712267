import { styled, Typography, Button } from '@mui/material'

export const InfoStyle = styled(Typography)(() => ({
  fontSize: '0.9rem',
  lineHeight: '1rem',
}))

export const ActionsStyle = styled(Button)(() => ({
  fontSize: '1rem',
  textTransform: 'capitalize',
  padding: '5px 10px',
}))

export const DataGuestStyle = styled('div')(() => ({
  display: 'flex',
  alignItems: 'baseline',
}))

export const TitleGuestStyle = styled(Typography)(() => ({
  fontSize: '0.8rem',
  fontWeight: 'bold',
  marginRight: '4px',
  textWrap: 'nowrap',
}))

export const ValueGuestStyle = styled(Typography)(() => ({
  fontSize: '0.8rem',
}))
