import { Box, Button, Chip, Container, Fab, Stack, Tooltip, Typography } from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { GridColDef } from '@mui/x-data-grid'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteIcon from '@mui/icons-material/Delete'
import moment from 'moment'
import FaceIcon from '@mui/icons-material/Face'
import { useState } from 'react'
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined'
import downloadCSV from 'json-to-csv-export'
import { useEvents } from './hook'
import DynamicDataGrid from '../../components/DynamicDataGrid'
import AttendeesDialog from '../../components/AttendeesDialog/index'
import EventForm from '../../components/EventForm'
import { IEvent, IParticipant } from '../../types/events'
import ConfirmDialog from '../../components/ConfirmDialog'
import { useAppSelector } from '../../redux/hooks'

const Events = () => {
  const cruiseId = useAppSelector((state) => state.auth.activeCruise?.cruise_id || 0)
  const { events, isFetching, destinations, users, remove, create, edit, refetch } = useEvents()

  const [selectedEvent, setSelectedEvent] = useState<IEvent | undefined>()
  const [isOpenEventDialog, setIsOpenEventDialog] = useState(false)
  const [isLoadingEvent, setIsLoadingEvent] = useState(false)

  const [isOpenAttendeesDialog, setIsOpenAttendeesDialog] = useState(false)
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)

  const onOpenAttendeesDialog = (event: IEvent): void => {
    setSelectedEvent(event)
    setIsOpenAttendeesDialog(true)
  }

  const onActionCompleted = (): void => {
    setIsConfirmOpen(false)
    refetch()
  }

  const createEvent = () => {
    setSelectedEvent(undefined)
    setIsOpenEventDialog(true)
  }

  const editEvent = (thisEvent: IEvent) => {
    const newEvent = { ...thisEvent }
    const name = newEvent.name as unknown as string
    setSelectedEvent({ ...thisEvent, name: name.split(', ') })
    setIsOpenEventDialog(true)
  }

  const onEventSaved = () => {
    setIsLoadingEvent(false)
    setIsOpenEventDialog(false)
    refetch()
  }

  const onEventError = () => {
    setIsLoadingEvent(false)
  }

  const onAcceptEvent = async (event: IEvent): Promise<void> => {
    setIsLoadingEvent(true)
    const name = event.name.join(', ')
    const eventPayload = {
      id: event.id,
      cruiseId,
      name,
      description: event.description,
      date: moment.utc(event.date.toDateString()).toISOString(),
      presenterId: event.presenterId,
    }
    if (!event.id) {
      await create(eventPayload, onEventSaved, onEventError)
    } else {
      await edit(eventPayload, onEventSaved, onEventError)
    }
  }

  const onRemoveEvent = async (): Promise<void> => {
    await remove(selectedEvent?.id!, onActionCompleted, () => setIsConfirmOpen(false))
  }

  const onDownloadCSV = (participantsData: IParticipant[], eventId: number) => {
    const fileName = `${`${moment().format('YYYY-MM-DD_hh:mm:ss_ms')}_attendees_${eventId}`}.csv`

    const participantsToConvert = participantsData.map((item) => ({
      stateRoom: item.passenger_stateRoom,
      name: `${item.passenger_first_name} ${item.passenger_last_name}`,
      cardId: item.passenger_card_id,
      validated: item.passenger_validated ? 'Yes' : 'No',
    }))

    const dataToConvert = {
      data: participantsToConvert,
      filename: fileName,
      delimiter: ',',
      headers: ['State Room', 'Name', 'Card Id', 'Validated'],
    }

    downloadCSV(dataToConvert)
  }

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'PRODUCTS',
      flex: 1,
    },
    {
      field: 'date',
      headerName: 'DATE',
      flex: 1,
    },
    {
      field: 'participants',
      headerName: 'ATTENDEES',
      align: 'center',
      headerAlign: 'center',
      width: 150,
      minWidth: 150,
      renderCell: ({ row, id }) =>
        row.participants.length > 0 ? (
          <Chip
            color="primary"
            icon={<FaceIcon />}
            label={`${row.participants.length} Attendee${row.participants.length === 1 ? '' : 's'}`}
            onClick={() => {
              onOpenAttendeesDialog({ ...row, eventId: id })
            }}
          />
        ) : (
          'None'
        ),
    },
    {
      field: 'actions',
      headerName: '',
      width: 200,
      align: 'right',
      renderCell: ({ id, row }) => (
        <>
          {row.participants.length > 0 && (
            <Tooltip title="Download Attendees List" sx={{ mr: 1 }}>
              <Fab
                color="success"
                size="small"
                onClick={() => {
                  onDownloadCSV(row.participants, Number(id))
                }}>
                <GetAppOutlinedIcon />
              </Fab>
            </Tooltip>
          )}
          <Tooltip title="Edit Event" sx={{ mr: 1 }}>
            <Fab color="secondary" size="small" onClick={() => editEvent(row)}>
              <EditOutlinedIcon />
            </Fab>
          </Tooltip>
          <Tooltip title="Delete Event">
            <Fab
              color="error"
              size="small"
              onClick={() => {
                setSelectedEvent({ ...row, eventId: id })
                setIsConfirmOpen(true)
              }}>
              <DeleteIcon />
            </Fab>
          </Tooltip>
        </>
      ),
    },
  ]

  return (
    <Container sx={{ padding: 2 }} maxWidth="xl">
      <Stack direction="row" justifyContent="space-between" sx={{ pb: 2 }}>
        <Box>
          <Button
            variant="contained"
            color="success"
            startIcon={<AddCircleOutlineIcon />}
            onClick={createEvent}>
            New Event
          </Button>
        </Box>
        <Typography variant="h4" color="primary" sx={{ display: { xs: 'none', md: 'flex' } }}>
          Events Management
        </Typography>
      </Stack>

      <DynamicDataGrid
        isLoading={isFetching}
        emptyMessage="No events in this voyage yet."
        rows={events?.map((event) => ({
          ...event,
          id: event.id,
          date: moment.utc(event.date).format('dddd, MMMM DD, YYYY'),
        }))}
        columns={columns}
        hideFooter
      />

      <AttendeesDialog
        isOpen={isOpenAttendeesDialog}
        onClose={() => setIsOpenAttendeesDialog(false)}
        participants={selectedEvent?.participants}
        onDownloadCSV={() => onDownloadCSV(selectedEvent?.participants!, selectedEvent?.id!)}
      />

      <ConfirmDialog
        title="Delete Event"
        message="Are you sure you want to remove this event? Please notice this action cannot be undone."
        onAccept={onRemoveEvent}
        onReject={() => {
          setIsConfirmOpen(false)
        }}
        isOpen={isConfirmOpen}
      />

      <EventForm
        key={`form-event-${selectedEvent?.id || isOpenEventDialog}`}
        isOpen={isOpenEventDialog}
        isLoading={isLoadingEvent}
        agents={users ?? []}
        destinations={destinations ?? []}
        onAccept={onAcceptEvent}
        onReject={() => setIsOpenEventDialog(false)}
        event={selectedEvent}
      />
    </Container>
  )
}

export default Events
