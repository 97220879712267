import { useState } from 'react'
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import numeral from 'numeral'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import TableContainer from '@mui/material/TableContainer'
import moment from 'moment'
import { ICruiseStatsTableProps, IExpandableRowProps } from './types'
import {
  cellBorderRight,
  headTableDarkGreenStyle,
  headTableGreenStyle,
  headTableStyle,
  headTableTangerineStyle,
} from '../../../styles/generic-styles'
import { theme } from '../../../styles/mui-theme'
import PortStatsTable from '../PortStatsTable'

const ExpandableRow: React.FC<IExpandableRowProps> = ({ stat, isHighlighted = false }) => {
  const [open, setOpen] = useState(false)

  const cellStyles = isHighlighted
    ? {
        fontWeight: 'bold',
        background: theme.palette.divider,
      }
    : null

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell sx={cellStyles}>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left" sx={{ ...cellStyles, ...cellBorderRight }}>
          {stat.voyageNumber}
        </TableCell>
        <TableCell align="left" sx={{ ...cellStyles, ...cellBorderRight }}>
          {stat.endDate ? moment.utc(stat.endDate).format('MM/DD/YYYY') : ''}
        </TableCell>
        <TableCell align="left" sx={{ ...cellStyles, ...cellBorderRight }}>
          {stat.itineraryName}
        </TableCell>
        <TableCell align="center" sx={{ ...cellStyles, ...cellBorderRight }}>
          {stat.qualifiedAppointments}
        </TableCell>
        <TableCell align="center" sx={{ ...cellStyles, ...cellBorderRight }}>
          {stat.appointments}
        </TableCell>
        <TableCell align="center" sx={{ ...cellStyles, ...cellBorderRight }}>
          {stat.conversion ? numeral(stat.conversion).format('0,0.00%') : 0}
        </TableCell>
        <TableCell align="center" sx={{ ...cellStyles, ...cellBorderRight }}>
          {stat.voyageTarget}
        </TableCell>
        <TableCell align="center" sx={{ ...cellStyles, ...cellBorderRight }}>
          {stat.bookingsStandardNow}
        </TableCell>
        <TableCell align="center" sx={{ ...cellStyles, ...cellBorderRight }}>
          {stat.bookingsStandardLater}
        </TableCell>
        <TableCell align="center" sx={{ ...cellStyles, ...cellBorderRight }}>
          {stat.bookingsStandard}
        </TableCell>
        <TableCell align="center" sx={{ ...cellStyles, ...cellBorderRight }}>
          {stat.bookingsSupplemental}
        </TableCell>
        <TableCell align="center" sx={{ ...cellStyles, ...cellBorderRight }}>
          {stat.attainment ? numeral(stat.attainment).format('0,0%') : 0}
        </TableCell>
        <TableCell align="center" sx={{ ...cellStyles, ...cellBorderRight }}>
          {stat.penetration ? numeral(stat.penetration).format('0,0.00%') : 0}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={14}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {stat.portStats && stat.portStats.length > 1 ? (
                <PortStatsTable stats={stat.portStats} />
              ) : (
                <div>No stats to display for this voyage</div>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

const CruiseStatsTable: React.FC<ICruiseStatsTableProps> = ({ stats }) => (
  <TableContainer>
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell rowSpan={2} />
          <TableCell rowSpan={2} sx={{ ...cellBorderRight }}>
            Voyage
          </TableCell>
          <TableCell rowSpan={2} sx={{ ...cellBorderRight }}>
            End Date
          </TableCell>
          <TableCell rowSpan={2} sx={{ ...cellBorderRight }}>
            Itinerary
          </TableCell>
          <TableCell rowSpan={2} sx={{ ...headTableStyle, ...cellBorderRight }} align="center">
            Qualified Traffic
          </TableCell>
          <TableCell rowSpan={2} sx={{ ...headTableStyle, ...cellBorderRight }} align="center">
            Overall Traffic
          </TableCell>
          <TableCell rowSpan={2} sx={{ ...headTableStyle, ...cellBorderRight }} align="center">
            Conversion
          </TableCell>
          <TableCell rowSpan={2} sx={{ ...headTableStyle, ...cellBorderRight }} align="center">
            Booking Goal
          </TableCell>
          <TableCell colSpan={2} sx={{ ...headTableGreenStyle, ...cellBorderRight }} align="center">
            Actuals
          </TableCell>
          <TableCell
            rowSpan={2}
            sx={{ ...headTableDarkGreenStyle, ...cellBorderRight }}
            align="center">
            TOTAL
          </TableCell>
          <TableCell
            rowSpan={2}
            sx={{ ...headTableTangerineStyle, ...cellBorderRight }}
            align="center">
            SUPPLEMENTAL BOOKINGS
          </TableCell>
          <TableCell
            rowSpan={2}
            sx={{ ...headTableDarkGreenStyle, ...cellBorderRight }}
            align="center">
            Attainment
          </TableCell>
          <TableCell
            rowSpan={2}
            sx={{ ...headTableDarkGreenStyle, ...cellBorderRight }}
            align="center">
            Penetration
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="center" sx={{ ...headTableGreenStyle, ...cellBorderRight }}>
            Book Now
          </TableCell>
          <TableCell align="center" sx={headTableGreenStyle}>
            Book Later
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {stats.map((stat, index) => (
          <ExpandableRow
            key={`row-${index}`}
            stat={stat}
            isHighlighted={index === stats.length - 1}
          />
        ))}
      </TableBody>
    </Table>
  </TableContainer>
)
export default CruiseStatsTable
