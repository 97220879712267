import * as yup from 'yup'

export const getSchemaValidation = (enableGroup?: boolean): yup.AnyObjectSchema => {
  const schema = yup.object().shape({
    bookingBrand: yup.string().required('Brand is required'),
    guestCategory: yup.string().required('Guest Category is required'),
    status: yup.string().required('Status is required'),
    bookingOrigin: yup.string().required('Booking origin is required'),
    bookingNumber: yup
      .number()
      .max(9999999, 'Booking number should be less or equal than 7 digits')
      .required('Booking number is required')
      .typeError('Booking number must be a number'),
  })

  if (enableGroup) {
    return yup.object().shape({
      bookingBrand: yup.string().required('Brand is required'),
      guestCategory: yup.string().required('Guest Category is required'),
      productId: yup.string().required('Product is required'),
      vesselId: yup.string().required('Vessel is required'),
      status: yup.string().required('Status is required'),
      bookingOrigin: yup.string().required('Booking Origin is required'),
      numberOfBookings: yup.string().required('Number of Bookings is required'),
      groupShellNumber: yup.string().required('Group Shell Number is required'),
    })
  }

  return schema
}
