import GroupRemoveIcon from '@mui/icons-material/GroupRemove'
import Tooltip from '@mui/material/Tooltip'
import { IActionsColumnProps } from './types'

const ActionsColumn: React.FC<IActionsColumnProps> = ({ appointment, openDialog }) => (
  <Tooltip title="Check appointment as No Show">
    <GroupRemoveIcon
      sx={{ cursor: 'pointer' }}
      onClick={() => openDialog(appointment)}
      color="error"
      fontSize="medium"
    />
  </Tooltip>
)

export default ActionsColumn
