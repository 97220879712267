import { Controller, useFieldArray, useForm } from 'react-hook-form'
import TableContainer from '@mui/material/TableContainer'
import Box from '@mui/material/Box'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import InfoIcon from '@mui/icons-material/Info'
import ErrorIcon from '@mui/icons-material/Error'
import moment from 'moment'
import Button from '@mui/material/Button'
import { yupResolver } from '@hookform/resolvers/yup'
import Fab from '@mui/material/Fab'
import Tooltip from '@mui/material/Tooltip'
import Grid from '@mui/material/Grid'
import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { IItineraryFormData, IItineraryFormProps } from './types'
import { itineraryFormValidationSchema } from './validation'
import { headTableStyle } from '../../styles/generic-styles'
import { IPort } from '../../types/ports'

const ItineraryForm: React.FC<IItineraryFormProps> = ({
  ports,
  itinerary_name,
  itinerary,
  goBack,
  onSubmit,
  editPort,
}) => {
  const location = useLocation()

  const [editMode] = useState<boolean>(!location.pathname.includes('new'))

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    reset,
    getValues,
  } = useForm<IItineraryFormData>({
    defaultValues: {
      itinerary_name,
      portSequence: itinerary,
    },
    resolver: yupResolver(itineraryFormValidationSchema),
  })

  useEffect(() => {
    reset({ portSequence: itinerary })
  }, [itinerary])

  const { fields } = useFieldArray({
    control,
    name: 'portSequence',
  })

  const onChangePort = (port: IPort | null, index: number) => {
    if (port?.name === 'At Sea') {
      setValue(`portSequence.${index}.startTime`, '00:00', { shouldValidate: true })
      setValue(`portSequence.${index}.endTime`, '23:59', { shouldValidate: true })
    }
    if (!port) {
      setValue(`portSequence.${index}.startTime`, '')
      setValue(`portSequence.${index}.endTime`, '')
    }
    if (editMode) {
      setValue(`portSequence.${index}.timezone`, port?.timezone)
      reset({ portSequence: getValues('portSequence') })
    }
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} sx={{ marginBottom: 2 }}>
        <Grid item xs={6}>
          <Controller
            control={control}
            name="itinerary_name"
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Itinerary Name"
                fullWidth
                error={!!errors.itinerary_name}
                helperText={errors?.itinerary_name?.message as string}
              />
            )}
          />
        </Grid>
      </Grid>
      <TableContainer sx={{ marginBottom: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ ...headTableStyle, width: '30%' }}>Port</TableCell>
              <TableCell align="center" sx={headTableStyle}>
                Date
              </TableCell>
              <TableCell align="center" sx={headTableStyle}>
                Begin
              </TableCell>
              <TableCell align="center" sx={headTableStyle}>
                End
              </TableCell>
              <TableCell sx={headTableStyle} />
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((item, index) => (
              <TableRow key={`new-voyage-grid-${item.id}`}>
                <TableCell>
                  {!item.canModify ? (
                    <Grid container alignItems="center" sx={{ flexWrap: 'nowrap' }}>
                      {item.hasAppointments && (
                        <Grid item>
                          <Tooltip
                            title={`There are ${item.numberOfAppointments} appointments in ${
                              ports.filter((port) => port.port_id === item.port)[0].name
                            }. Be careful when changing this port because these appointments will be changed as well`}
                            sx={{ marginRight: 1 }}>
                            <InfoIcon color="disabled" />
                          </Tooltip>
                        </Grid>
                      )}
                      <Grid item>{ports.filter((port) => port.port_id === item.port)[0].name}</Grid>
                    </Grid>
                  ) : (
                    <Controller
                      control={control}
                      name={`portSequence.${index}.port`}
                      render={({ field: { onChange, ...field } }) => (
                        <Autocomplete
                          options={ports}
                          getOptionLabel={(port) => port.name}
                          defaultValue={
                            ports.filter((port) => port.port_id === item.port)[0] ?? undefined
                          }
                          onChange={(_, port) => {
                            onChange(port?.port_id)
                            onChangePort(port, index)
                          }}
                          renderOption={(props, port) => (
                            <Box {...props} component="li" key={port.port_id} value={port.port_id}>
                              {port.name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              {...field}
                              label="Port"
                              fullWidth
                              error={!!errors.portSequence?.[index]?.port}
                              helperText={errors?.portSequence?.[index]?.port?.message as string}
                            />
                          )}
                        />
                      )}
                    />
                  )}
                </TableCell>
                <TableCell align="center">
                  <Grid container justifyContent="center" sx={{ flexWrap: 'nowrap' }}>
                    {!Number.isNaN(item.port) && !item.timezone && editMode && (
                      <Grid item>
                        <Tooltip
                          title={`${
                            ports.filter((port) => port.port_id === item.port)[0].name
                          } has no timezone. Click here to configure this.`}
                          sx={{ marginRight: 1, cursor: 'pointer' }}
                          onClick={editPort ? () => editPort(item.port) : () => {}}>
                          <ErrorIcon color="warning" />
                        </Tooltip>
                      </Grid>
                    )}
                    <Grid item>
                      {moment(item.date, 'YYYY-MM-DD').format('YYYY-MM-DD') === item.date
                        ? moment(item.date, 'YYYY-MM-DD').format('MM/DD/YYYY')
                        : moment(item.date).format('MM/DD/YYYY')}
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align="center">
                  <Controller
                    control={control}
                    name={`portSequence.${index}.startTime`}
                    defaultValue={item.startTime}
                    render={({ field }) => (
                      <TextField
                        type="time"
                        {...field}
                        error={!!errors.portSequence?.[index]?.startTime}
                        helperText={errors?.portSequence?.[index]?.startTime?.message as string}
                      />
                    )}
                  />
                </TableCell>
                <TableCell align="center">
                  <Controller
                    control={control}
                    name={`portSequence.${index}.endTime`}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        type="time"
                        {...field}
                        error={!!errors.portSequence?.[index]?.endTime}
                        helperText={errors?.portSequence?.[index]?.endTime?.message as string}
                      />
                    )}
                  />
                </TableCell>
                <TableCell align="center">
                  <Fab
                    size="small"
                    color="primary"
                    onClick={() => {
                      setValue(`portSequence.${index}.startTime`, '00:00')
                      setValue(`portSequence.${index}.endTime`, '23:59')
                    }}>
                    <AccessTimeIcon />
                  </Fab>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button type="submit" variant="contained" sx={{ alignSelf: 'flex-start', marginRight: 1 }}>
        CONTINUE
      </Button>
      <Button type="button" onClick={goBack}>
        BACK
      </Button>
    </Box>
  )
}

export default ItineraryForm
