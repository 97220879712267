import {
  Alert,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  Box,
  Grid,
  Menu,
} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import moment from 'moment'
import PersonOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined'
import { IAppointmentModalProps } from './types'
import { ActionsStyle, InfoStyle, DataGuestStyle, TitleGuestStyle, ValueGuestStyle } from './styles'
import TDCCard from './TDCCard'
import BookingCard from './BookingCard'
import QuoteCard from './QuoteCard'
import OtherCard from './OtherCard'
import EventCard from './EventCard'
import MailDropCard from './MailDropCard'
import { theme } from '../../styles/mui-theme'
import {
  EAppointmentDBType,
  EAppointmentDetailsTab,
  EStatusAppointment,
} from '../../types/appointments'
import ConfirmDialog from '../ConfirmDialog/index'
import GuestForm from '../GuestForm'
import { IGuestForm } from '../GuestForm/types'
import { formatTime } from '../../utils/time'
import { useInterval } from '../../utils/useInterval'
import { getStatusName } from '../../utils/utils'
import { ITab } from '../TabsContainer/types'
import TabsContainer from '../TabsContainer'
import AppointmentLogs from '../AppointmentLogs'
import Notify from '../../utils/notify'
import BookingForm from '../BookingForm'
import CardForm from '../CardForm'
import { useAppointment, useBooking, useCard } from '../../pages/Appointments/hook'
import { IBookingValues } from '../BookingForm/types'
import { ICardValues } from '../CardForm/types'
import { IGenericResponse } from '../../types/generic'
import { EBookingType, IGroupBookingItem } from '../../types/bookings'
import { useAppSelector } from '../../redux/hooks'
import { RootState } from '../../redux/store'
import { useNewVoyage } from '../../pages/Settings/Voyages/NewVoyage/hook'

const AppointmentModal: React.FC<IAppointmentModalProps> = (props) => {
  const {
    isOpen,
    onClose,
    agents,
    onOpenMoveAppointment,
    onChangeStatus,
    appointment,
    onLookingUpStateRoom,
    onSelectAgent,
    onNoShowAppointment,
    onUpdatePerson,
    onRestoreAppointment,
    isLoadingAssignedAgents,
    openAppointmentCallbackModal,
    onDeleteAppointment,
    openAppointmentQuoteModal,
    onOpenOtherForm,
    handleDelete,
    isFetchingEvents,
    events,
    onChangeDetailTab,
    selectedApptDetailsTab,
    getEventsByRoomTrigger,
    getMaildropByRoomTrigger,
    maildrop,
    isFetchingMaildrop,
    openEditQuote,
    appointments,
    removeAppointmentFromSelectedAppointments,
    changeAppointmentSelected,
    isLoadingAppointment,
    searchedGuests,
    isFetchingSearchedGuests,
    setIsOpenUpdateNoshow,
    isOpenUpdateNoshow,
  } = props
  const {
    getAppointmentByIdTrigger,
    appointmentById,
    isFetchingGetAll,
    getGuestByRoomTrigger,
    getGuestByCruiseTrigger,
  } = useAppointment(0, '')
  const activeCruiseId = useAppSelector((state: RootState) => state.auth.activeCruise?.cruise_id)
  const [statusColor, setStatusColor] = useState<string | undefined>()
  const [isEditGuest, setIsEditGuest] = useState<boolean>(false)
  const [isOpenConfirm, setIsOpenConfirm] = useState<boolean>(false)
  const [time, setTime] = useState((appointmentById || appointment).visitTime ?? 0)
  const [isOpenBookingForm, setIsOpenBookingForm] = useState<boolean>(false)
  const [bookType, setBookType] = useState<string>('')
  const [isOpenCardForm, setIsOpenCardForm] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [bookingToEdit, setBookingToEdit] = useState<IBookingValues>()

  const { products, vessels } = useNewVoyage()
  const { createBooking, createBookingGroup, editBooking, isLoading } = useBooking()
  const { createCard, editCard } = useCard()
  const loggedUser = useAppSelector((state) => state.auth.loggedUser)

  const handleAddBooking = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  useEffect(() => {
    setTime(appointment.visitTime ?? 0)

    const appBooking =
      appointment.bookings &&
      appointment.bookings.find(
        (book) =>
          book.booking_type === EBookingType.APP_BOOKING &&
          !book.booking_number &&
          appointment.appointment_id === book.appointment_id,
      )

    if (appBooking) {
      setBookingToEdit({
        bookingId: appBooking.booking_id,
        appointmentId: appBooking.appointment_id,
        status: appBooking.booking_status?.toLowerCase() || '',
        bookingOrigin: appBooking.booking_origin || '',
        bookingType: appBooking.booking_type,
        bookingBrand: appBooking.booking_brand,
        depositAmount: appBooking.deposit_amount,
        depositCurrency: appBooking.deposit_currency,
      })
    } else {
      setBookingToEdit(undefined)
    }
  }, [appointment])

  const showWarning = appointment
    ? moment(appointment.queued_on, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD') !==
      moment(appointment.port!.arrival_date, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD')
    : false
  const onChangeStatusToggle = (
    _event: React.MouseEvent<HTMLElement>,
    newStatusToggle: string | null,
  ) => {
    const newStatus = newStatusToggle as EStatusAppointment
    if (onChangeStatus && !!newStatusToggle) {
      onChangeStatus(newStatus)
    }
  }

  const onHandleLookingUpStateRoom = (stateroom: number) => {
    if (onLookingUpStateRoom) {
      onLookingUpStateRoom(stateroom)
    }
  }

  const onOpenEditGuest = () => {
    if (
      appointment.status !== EStatusAppointment.PENDING &&
      appointment.status !== EStatusAppointment.IN_PROGRESS
    ) {
      Notify.error(
        'Sorry, To change or delete an appointment you must first move it to In Line or In Progress status',
      )
      return
    }
    setIsEditGuest(true)
    onHandleLookingUpStateRoom(0)
  }

  const onCloseEditGuest = () => {
    setIsEditGuest(false)
    onHandleLookingUpStateRoom(0)
  }

  const onHandleSelectAgent = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onSelectAgent) {
      onSelectAgent(event.target.value)
    }
  }

  const onAssignToMe = (agentId: string) => {
    if (onSelectAgent) {
      onSelectAgent(agentId)
    }
  }

  const onConfirmDeleteAppointment = () => {
    if (appointment.status !== EStatusAppointment.PENDING) {
      Notify.error('Sorry, To delete an appointment you must first move it to In Line status')
      return
    }

    setIsOpenConfirm(true)
  }

  useEffect(() => {
    switch (appointment.status) {
      case EStatusAppointment.PENDING:
        setStatusColor(theme.palette.success.light)
        break
      case EStatusAppointment.IN_PROGRESS:
        setStatusColor(theme.palette.primary.light)
        break
      case EStatusAppointment.DELETED:
        setStatusColor(theme.palette.error.main)
        break
      default:
        setStatusColor(theme.palette.error.main)
        break
    }
  }, [appointment])

  useInterval(() => {
    setTime((prevTime) => (prevTime || 0) + 1)
  }, 1000)

  useEffect(() => {
    if (selectedApptDetailsTab === EAppointmentDetailsTab.EVENTS)
      getEventsByRoomTrigger({ cruiseId: activeCruiseId!, stateRoom: appointment.person?.room! })
    if (selectedApptDetailsTab === EAppointmentDetailsTab.MAILDROP)
      getMaildropByRoomTrigger({ cruiseId: activeCruiseId!, stateRoom: appointment.person?.room! })
  }, [selectedApptDetailsTab])

  const disableAllActions =
    appointment.status === EStatusAppointment.FINISHED || appointment.isDeleted === 1
  const buildCurrentStatus = () =>
    getStatusName(appointment.isDeleted === 1 ? 'DELETED' : appointment.status ?? '')

  const onCreateBooking = async (
    booking: IBookingValues,
    isSaveAndNew?: boolean,
    isGroup?: boolean,
    openCardForm?: boolean,
  ) => {
    const reasonWhy = booking.reasonWhy ? ` [Reason Why: ${booking.reasonWhy}] ` : ''
    const casinoBooking = booking.casinoBooking
      ? ` [Casino Booking: ${booking.casinoBooking}] `
      : ''
    const travelAgency = booking.travelAgencyChange
      ? `[Travel Agency Change: ${booking.travelAgencyChange}] `
      : ''
    const commentContent = `${booking.comment} ${reasonWhy} ${casinoBooking} ${travelAgency}`

    if (!isGroup) {
      await createBooking({
        appointmentId: appointment.appointment_id,
        destination_id: booking.productId ? Number(booking.productId) : undefined,
        ship_id: booking.vesselId ? Number(booking.vesselId) : undefined,
        booking_number: booking.bookingNumber?.toString() || '',
        booking_name: booking.bookingName || '',
        booking_type: booking.bookingType,
        booking_brand: booking.bookingBrand,
        guest_category: booking.guestCategory || '',
        amount: Number(booking.amount || 0),
        stateroom_category: booking.stateRoomCategory?.toString(),
        sail_date: booking.sailDate ? booking.sailDate : undefined,
        status: booking.status.toUpperCase(),
        agent_id: appointment.agent?.agent_id,
        comment: commentContent,
        origin: booking.bookingOrigin,
        group_name: booking.groupName,
        reason_booking_cancelled: booking.reasonBookingCancelled,
        non_refundable: booking.nonRefutable,
        used_visa_card: booking.usedRCVisaCard,
        pending_items: booking.pendingItems,
        deposit_amount: booking.depositAmount || 0,
        deposit_currency: booking.depositCurrency || '',
      })
    } else if (booking.groupBookingItems) {
      const bookingItems: IGroupBookingItem[] = booking.groupBookingItems.map(
        ({ error, ...item }) => ({
          amount: Number(item.amount),
          booking_number: item.bookingNumber,
          stateroom_category: item.stateRoomCategory,
        }),
      )

      await createBookingGroup({
        appointmentId: appointment.appointment_id,
        destination_id: Number(booking.productId),
        ship_id: Number(booking.vesselId),
        booking_name: booking.bookingName || '',
        sail_date: booking.sailDate ? booking.sailDate : undefined,
        status: booking.status.toUpperCase(),
        agent_id: appointment.agent?.agent_id!,
        comment: commentContent,
        origin: booking.bookingOrigin,
        group_name: booking.groupName,
        reason_booking_cancelled: booking.reasonBookingCancelled,
        non_refundable: booking.nonRefutable || false,
        pending_items: booking.pendingItems || false,
        group_booking_items: bookingItems,
        group_shell_number: booking.groupShellNumber || '',
        booking_type: booking.bookingType,
        booking_brand: booking.bookingBrand,
        guest_category: booking.guestCategory || '',
      })
    }

    if (!isSaveAndNew) setIsOpenBookingForm(false)
    if (openCardForm) setIsOpenCardForm(true)

    if (appointment?.appointment_id) {
      await getAppointmentByIdTrigger(appointment.appointment_id)
    }
  }

  const onAcceptedBooking = () => {
    setIsOpenBookingForm(false)
  }

  const onUpdateBooking = async (booking: IBookingValues) => {
    const reasonWhy = booking.reasonWhy ? ` [Reason Why: ${booking.reasonWhy}] ` : ''
    const casinoBooking = booking.casinoBooking
      ? ` [Casino Booking: ${booking.casinoBooking}] `
      : ''
    const travelAgency = booking.travelAgencyChange
      ? `[Travel Agency Change: ${booking.travelAgencyChange}] `
      : ''
    const commentContent = `${booking.comment} ${reasonWhy} ${casinoBooking} ${travelAgency}`

    await editBooking(
      {
        appointmentId: booking?.appointmentId,
        bookingId: booking.bookingId,
        destination_id: booking.productId ? Number(booking.productId) : undefined,
        ship_id: booking.vesselId ? Number(booking.vesselId) : undefined,
        booking_number: booking.bookingNumber?.toString() || '',
        booking_name: booking.bookingName || '',
        amount: Number(booking.amount || 0),
        stateroom_category: booking.stateRoomCategory?.toString() || '',
        sail_date: booking.sailDate ? moment.utc(booking.sailDate).format('YYYY-MM-DD') : undefined,
        status: booking.status.toUpperCase(),
        comment: commentContent,
        origin: booking.bookingOrigin,
        group_name: booking.groupName,
        reason_booking_cancelled: booking.reasonBookingCancelled,
        non_refundable: booking.nonRefutable,
        used_visa_card: booking.usedRCVisaCard,
        pending_items: booking.pendingItems,
        booking_type: booking.bookingType,
        booking_brand: booking.bookingBrand,
        guest_category: booking.guestCategory || '',
        deposit_amount: booking.depositAmount || 0,
        deposit_currency: booking.depositCurrency || '',
      },
      onAcceptedBooking,
    )
    if (appointment?.appointment_id) {
      await getAppointmentByIdTrigger(appointment.appointment_id)
    }
  }
  // manage bookings end

  const onAcceptedSomething = (response: IGenericResponse) => {
    if (!response.success) {
      Notify.error(response.message)
    }
  }

  const onAcceptedCreateCard = async (response: IGenericResponse) => {
    if (!response.success) {
      Notify.error(response.message)
      return
    }
    setIsOpenCardForm(false)
    if (appointment?.appointment_id) {
      await getAppointmentByIdTrigger(appointment.appointment_id)
      await getGuestByRoomTrigger(appointment.person?.room || 0)
      await getGuestByCruiseTrigger({
        cruiseId: appointment.cruise_id || 0,
        room: appointment.person?.room || 0,
      })
    }
  }

  // manage card start
  const onCreateCard = async (card: ICardValues) => {
    await createCard(
      {
        appointmentId: appointment.appointment_id,
        status: card.status.toUpperCase(),
        comment: card.comment,
        transferredCard: card.transferredCard,
        agent_id: appointment.agent?.agent_id,
        room: card.room,
        first_name: card.first_name,
        last_name: card.last_name,
        cruise_id: appointment.cruise_id,
      },
      onAcceptedCreateCard,
    )
  }

  const openCreateCardModal = () => {
    setIsOpenCardForm(true)
  }

  const onUpdateCard = async (card: ICardValues) => {
    await editCard(
      {
        cardId: card?.cardId,
        appointmentId: card?.appointmentId,
        status: card.status.toUpperCase(),
        comment: card.comment,
        transferredCard: card.transferredCard,
        person_id: card.personId,
      },
      onAcceptedSomething,
    )

    setIsOpenCardForm(false)
    if (appointment?.appointment_id) {
      await getAppointmentByIdTrigger(appointment.appointment_id)
    }
  }
  // manage card end

  const handleOpenBookingForm = (type: string) => {
    setBookType(type)
    setAnchorEl(null)
    setIsOpenBookingForm(true)
  }

  const onCloseBookingMenu = () => {
    setAnchorEl(null)
  }

  const apptDetailsTab: ITab[] = [
    {
      title: EAppointmentDetailsTab.CARD,
      content: (
        <TDCCard
          cards={appointment.cards || []}
          portTimeZone={appointment.port?.timezone || ''}
          appointmentId={appointment.appointment_id!}
          onEditCard={onUpdateCard}
          isLoading={isLoading || isFetchingGetAll}
        />
      ),
      badge: appointment.cards!.length.toString() ?? '',
      position: 'left',
      badgeColor: theme.palette.secondary.main!,
    },
    {
      title: EAppointmentDetailsTab.BOOKINGS,
      content: (
        <BookingCard
          bookings={appointment.bookings || []}
          person={appointment.person || undefined}
          other_guests={appointment.other_guests || undefined}
          portTimeZone={appointment.port?.timezone || ''}
          appointmentId={appointment.appointment_id!}
          products={products || []}
          vessels={vessels || []}
          onEditBooking={onUpdateBooking}
          isLoading={isLoading || isFetchingGetAll}
          haveCards={(appointment.cards && appointment.cards.length > 0) || false}
        />
      ),
      badge: appointment.bookings!.length.toString() ?? '',
      position: 'left',
      badgeColor: theme.palette.primary.light,
    },
    {
      title: EAppointmentDetailsTab.QUOTES,
      content: (
        <QuoteCard
          quotes={appointment.quotes || []}
          appointmentId={appointment.appointment_id!}
          portTimeZone={appointment.port?.timezone || ''}
          openEditQuote={openEditQuote}
        />
      ),
      badge: appointment.quotes!.length.toString() ?? '',
      position: 'left',
      badgeColor: theme.palette.custom.yellow!,
    },
    {
      title: EAppointmentDetailsTab.OTHERS,
      content: (
        <OtherCard
          others={appointment.others || []}
          appointmentId={appointment.appointment_id!}
          portTimeZone={appointment.port?.timezone || ''}
          handleDelete={handleDelete!}
        />
      ),
      badge: appointment.others!.length.toString() ?? '',
      position: 'left',
      badgeColor: theme.palette.error.main,
    },
    {
      title: EAppointmentDetailsTab.EVENTS,
      content: <EventCard events={events} isFetchingEvents={isFetchingEvents} />,
      badge: '',
      position: 'left',
      badgeColor: '',
    },
    {
      title: EAppointmentDetailsTab.MAILDROP,
      content: (
        <MailDropCard
          maildrop={maildrop}
          isFetchingMaildrop={isFetchingMaildrop}
          stateRoom={appointment.person?.room || 0}
          getMaildropByRoomTrigger={getMaildropByRoomTrigger}
          selectedApptDetailsTab={selectedApptDetailsTab || ''}
        />
      ),
      badge: '',
      position: 'left',
      badgeColor: '',
    },
    {
      title: EAppointmentDetailsTab.LOG,
      content: <AppointmentLogs logs={appointment.logs || []} />,
      badge: '',
      position: 'right',
      badgeColor: '',
    },
  ]

  const agentSelectorAgentRole = appointment?.agent?.agent_id ? (
    <Typography>
      <b> Assigned to:</b>
      {` ${appointment?.agent?.first_name} ${appointment?.agent?.last_name}`}
    </Typography>
  ) : (
    <Button
      variant="contained"
      color="warning"
      startIcon={<PersonOutlinedIcon />}
      onClick={() => onAssignToMe(loggedUser?.userId!)}
      sx={{
        textTransform: 'capitalize',
        '&.MuiButton-root': {
          backgroundColor: theme.palette.custom.yellow,
        },
      }}>
      Assign to me
    </Button>
  )

  const agentSelector =
    loggedUser?.role === 'agent' ? (
      agentSelectorAgentRole
    ) : (
      <>
        <TextField
          select
          size="small"
          sx={{ width: '12.5rem' }}
          onChange={onHandleSelectAgent}
          value={appointment?.agent?.agent_id || 'Unassigned Agent'}
          disabled={!!appointment.isDeleted}>
          <MenuItem value="Unassigned Agent">Unassigned Agent</MenuItem>
          {agents.map((agent) => (
            <MenuItem
              key={`agent-${agent.id}`}
              value={agent.id}>{`${agent.first_name} ${agent.last_name}`}</MenuItem>
          ))}
        </TextField>
        <IconButton onClick={() => onClose(time || 0)}>
          <CloseIcon />
        </IconButton>
      </>
    )

  return (
    <Box>
      <Dialog
        open={isOpen}
        onClose={() => onClose(time || 0)}
        fullWidth
        maxWidth="md"
        sx={{ minWidth: 900 }}>
        <DialogContent sx={{ padding: '20px 24px 0' }}>
          {isLoadingAppointment && (
            <Stack alignItems="center" justifyContent="center" sx={{ padding: '5rem' }}>
              <CircularProgress variant="indeterminate" size="5rem" />
            </Stack>
          )}
          {!isLoadingAppointment && appointments && appointments.length > 1 && (
            <Grid container spacing={2} sx={{ marginBottom: '1rem' }}>
              {appointments.map((appt, index) => (
                <Grid
                  item
                  key={appt.appointment_id}
                  sx={{
                    cursor: 'pointer',
                    borderBottom:
                      appointment.appointment_id === appt.appointment_id ? '2px solid' : '',
                    borderBottomColor:
                      appointment.appointment_id === appt.appointment_id ? 'primary.main' : '',
                  }}>
                  <Stack direction="row" alignItems="center">
                    <Typography
                      sx={{
                        fontWeight:
                          appointment.appointment_id === appt.appointment_id ? '700' : '400',
                      }}
                      onClick={() => changeAppointmentSelected!(index, time)}>
                      {`${appt.person?.room} - ${appt.person?.first_name}`}
                    </Typography>
                    <IconButton
                      onClick={() => removeAppointmentFromSelectedAppointments!(appt, time)}>
                      <CloseIcon />
                    </IconButton>
                  </Stack>
                </Grid>
              ))}
            </Grid>
          )}
          {!isLoadingAppointment && (
            <>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography sx={{ color: statusColor, fontWeight: 'bold', fontSize: '1.5rem' }}>
                  {buildCurrentStatus()}
                </Typography>
                <Stack direction="row" alignItems="center">
                  {isLoadingAssignedAgents ? (
                    <CircularProgress variant="indeterminate" size="1.2rem" />
                  ) : (
                    agentSelector
                  )}
                </Stack>
              </Stack>
              <Stack direction="column" justifyContent="center" paddingBottom={2}>
                <Stack direction="row" alignItems="center">
                  <InfoStyle>{`${appointment.port?.name ?? ''} - ${
                    appointment.port?.arrival_date
                      ? moment(appointment.port.arrival_date, 'YYYY-MM-DDTHH:mm:ss').format(
                          'MMM DD',
                        )
                      : ''
                  }`}</InfoStyle>
                  {showWarning && (
                    <Tooltip
                      title={`Warning: The appointment date (${moment(
                        appointment.queued_on,
                        'YYYY-MM-DDTHH:mm:ss',
                      ).format('MMM DD')}) is different to the port date (${
                        appointment.port!.name
                      } - ${moment(appointment.port!.arrival_date, 'YYYY-MM-DDTHH:mm:ss').format(
                        'MMM DD',
                      )})`}>
                      <InfoIcon
                        sx={{
                          color: theme.palette.custom.yellow,
                          mr: 0,
                          ml: 1,
                        }}
                        fontSize="small"
                      />
                    </Tooltip>
                  )}
                  <IconButton onClick={onOpenMoveAppointment}>
                    <EditOutlinedIcon fontSize="small" />
                  </IconButton>
                </Stack>
                <InfoStyle>{`Queued on ${moment(
                  appointment.queued_on,
                  'YYYY-MM-DDTHH:mm:ss',
                ).format('MMM Do ')}`}</InfoStyle>
              </Stack>
              <Divider sx={{ mb: 2 }} />
              <Stack direction="column" alignItems="center">
                <ToggleButtonGroup
                  value={appointment.status}
                  exclusive
                  onChange={onChangeStatusToggle}
                  disabled={!!appointment.isDeleted}
                  sx={{
                    '.Mui-selected': {
                      backgroundColor: statusColor,
                      color: 'white',
                      fontWeight: 700,
                    },
                    '.Mui-selected:hover': { backgroundColor: statusColor },
                    '.MuiToggleButton-root': {
                      fontSize: '1rem',
                      textTransform: 'capitalize',
                      lineHeight: '1.5rem',
                      padding: '8px',
                    },
                  }}>
                  <ToggleButton
                    value={EStatusAppointment.PENDING}
                    disabled={appointment.status === EStatusAppointment.PENDING}>
                    In Line
                  </ToggleButton>
                  <ToggleButton
                    value={EStatusAppointment.IN_PROGRESS}
                    disabled={appointment.status === EStatusAppointment.IN_PROGRESS}>
                    In Progress
                  </ToggleButton>
                  <ToggleButton
                    value={EStatusAppointment.FINISHED}
                    disabled={appointment.status === EStatusAppointment.FINISHED}>
                    Finalized
                  </ToggleButton>
                </ToggleButtonGroup>
              </Stack>
              <Divider sx={{ mt: 2, mb: 2 }} />
              {isEditGuest ? (
                <Card>
                  <CardContent>
                    <GuestForm
                      onAccept={(guest: IGuestForm) => {
                        onUpdatePerson(guest)
                        onCloseEditGuest()
                      }}
                      onReject={onCloseEditGuest}
                      isFetching={isFetchingSearchedGuests || false}
                      searchedGuests={searchedGuests || []}
                      guest={{
                        cardId: appointment.person?.card_id ?? null,
                        cruiseBookingId: appointment.person?.cruiseBookingId ?? null,
                        firstName: appointment.person!.first_name,
                        gateway: appointment.person?.gateway ?? null,
                        guestId: appointment.person?.guestId ?? null,
                        lastName: appointment.person!.last_name,
                        loyaltyLevel: appointment.person?.loyaltyLevel ?? null,
                        nationality: appointment.person?.nationality ?? null,
                        stateroom: appointment.person!.room,
                        validated: !!appointment.person!.validated,
                      }}
                      onDelete={onConfirmDeleteAppointment}
                      onSearchRoom={onHandleLookingUpStateRoom}
                    />
                  </CardContent>
                </Card>
              ) : (
                <Stack direction="column">
                  <Stack direction="row" justifyContent="space-between">
                    <Typography
                      sx={{
                        fontSize: '1.714rem',
                        fontWeight: 'bold',
                      }}>{`${appointment.person!.room} - ${appointment.person!.first_name} ${
                      appointment.person!.last_name
                    }`}</Typography>
                    {appointment.isDeleted ? (
                      <Button
                        size="small"
                        color="success"
                        variant="contained"
                        onClick={() => onRestoreAppointment(appointment?.appointment_id!)}>
                        Restore
                      </Button>
                    ) : (
                      <Button
                        startIcon={<EditOutlinedIcon fontSize="small" />}
                        size="small"
                        onClick={onOpenEditGuest}>
                        Edit
                      </Button>
                    )}
                  </Stack>
                  {(appointment.person?.guestId || appointment.person?.cabinCategoryCode) && (
                    <Stack direction="row" justifyContent="space-between">
                      <Stack flex={1}>
                        <DataGuestStyle>
                          <TitleGuestStyle>Guest Id:</TitleGuestStyle>
                          <ValueGuestStyle>{appointment.person!.guestId || 'None'}</ValueGuestStyle>
                        </DataGuestStyle>
                        <DataGuestStyle>
                          <TitleGuestStyle>Cruise Booking Id:</TitleGuestStyle>{' '}
                          <ValueGuestStyle>
                            {appointment.person!.cruiseBookingId || 'None'}
                          </ValueGuestStyle>
                        </DataGuestStyle>
                        <DataGuestStyle>
                          <TitleGuestStyle>Loyalty Level:</TitleGuestStyle>{' '}
                          <ValueGuestStyle>
                            {appointment.person!.loyaltyLevel || 'None'}
                          </ValueGuestStyle>
                        </DataGuestStyle>
                        <DataGuestStyle>
                          <TitleGuestStyle>Loyalty Number:</TitleGuestStyle>{' '}
                          <ValueGuestStyle>
                            {appointment.person!.loyaltyNumber || 'None'}
                          </ValueGuestStyle>
                        </DataGuestStyle>
                      </Stack>
                      <Stack flex={1}>
                        <DataGuestStyle>
                          <TitleGuestStyle>Nationality:</TitleGuestStyle>{' '}
                          <ValueGuestStyle>
                            {appointment.person!.nationality || 'None'}
                          </ValueGuestStyle>
                        </DataGuestStyle>
                        <DataGuestStyle>
                          <TitleGuestStyle>Gateway:</TitleGuestStyle>{' '}
                          <ValueGuestStyle>{appointment.person!.gateway || 'None'}</ValueGuestStyle>
                        </DataGuestStyle>
                        <DataGuestStyle>
                          <TitleGuestStyle>Cabin Category:</TitleGuestStyle>{' '}
                          <ValueGuestStyle>
                            {appointment.person!.cabinCategoryCode || 'None'}
                          </ValueGuestStyle>
                        </DataGuestStyle>
                        <DataGuestStyle>
                          <TitleGuestStyle>FCC Amount:</TitleGuestStyle>{' '}
                          <ValueGuestStyle>
                            {appointment.person!.futureCruiseCnt || 0}
                          </ValueGuestStyle>
                        </DataGuestStyle>
                      </Stack>
                      <Stack flex={1}>
                        <DataGuestStyle>
                          <TitleGuestStyle>RCI Card Holder Flag:</TitleGuestStyle>{' '}
                          <ValueGuestStyle>
                            {appointment.person!.rciCardHolderFlag
                              ? appointment.person!.rciCardHolderFlag
                              : 'None'}
                          </ValueGuestStyle>
                        </DataGuestStyle>
                        <DataGuestStyle>
                          <TitleGuestStyle>Cel Card Holder Flag:</TitleGuestStyle>{' '}
                          <ValueGuestStyle>
                            {appointment.person!.celCardHolderFlag
                              ? appointment.person!.celCardHolderFlag
                              : 'None'}
                          </ValueGuestStyle>
                        </DataGuestStyle>
                        <DataGuestStyle>
                          <TitleGuestStyle>Past Onboard Flag:</TitleGuestStyle>{' '}
                          <ValueGuestStyle>
                            {appointment.person!.pastOnboardFlag
                              ? appointment.person!.pastOnboardFlag
                              : 'None'}
                          </ValueGuestStyle>
                        </DataGuestStyle>
                        <DataGuestStyle>
                          <TitleGuestStyle>Past Onboard Bkng Cnt:</TitleGuestStyle>{' '}
                          <ValueGuestStyle>
                            {appointment.person!.pastOnboardBkngCnt || 0}
                          </ValueGuestStyle>
                        </DataGuestStyle>
                      </Stack>
                    </Stack>
                  )}
                  {!appointment.person?.guestId &&
                    !appointment.person?.cabinCategoryCode &&
                    (appointment.person!.room !== 0 ? (
                      <Alert sx={{ mt: 1 }} severity="warning">
                        There is no guest information received from guest validation web service.
                      </Alert>
                    ) : (
                      <Typography sx={{ fontSize: '0.75rem', lineHeight: '1.4285em' }}>
                        * No data found on web service for folio {appointment.person!.card_id}
                      </Typography>
                    ))}
                  {!!appointment.isDeleted && (
                    <Alert sx={{ mt: 1 }} severity="warning">
                      If you want to keep working on this appointment please restore it.
                    </Alert>
                  )}
                  {!appointment.agent?.agent_id && !appointment.isDeleted && (
                    <Alert sx={{ mt: 1 }} severity="error">
                      Assign appointment to an agent to start working
                    </Alert>
                  )}
                </Stack>
              )}
              {bookingToEdit &&
                appointment.status !== EStatusAppointment.PENDING &&
                appointment.type === EAppointmentDBType.CRUISELATER && (
                  <>
                    <Divider sx={{ mt: 2, mb: 2 }} />
                    <Box>
                      <ActionsStyle
                        variant="contained"
                        color="warning"
                        disabled={disableAllActions}
                        onClick={() => handleOpenBookingForm('App Booking')}>
                        Complete App Booking
                      </ActionsStyle>
                    </Box>
                  </>
                )}
              {appointment.status !== EStatusAppointment.PENDING &&
                appointment.type !== EAppointmentDBType.CRUISELATER && (
                  <>
                    <Divider sx={{ mt: 2, mb: 2 }} />
                    <Box
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(6, 1fr)',
                        gridColumnGap: '0.1rem',
                      }}>
                      <Box
                        sx={{
                          display: 'grid',
                          position: 'relative',
                        }}>
                        <ActionsStyle
                          variant="contained"
                          color="primary"
                          disabled={disableAllActions}
                          onClick={handleAddBooking}>
                          Add a Booking
                        </ActionsStyle>
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={onCloseBookingMenu}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}>
                          <MenuItem
                            sx={{ width: '140px' }}
                            onClick={() => handleOpenBookingForm('Book Now')}>
                            Book Now
                          </MenuItem>
                          <Divider />
                          <MenuItem
                            sx={{ width: '140px' }}
                            onClick={() => handleOpenBookingForm('Book Later')}>
                            Book Later
                          </MenuItem>
                        </Menu>
                      </Box>
                      <ActionsStyle
                        variant="contained"
                        color="warning"
                        disabled={disableAllActions}
                        onClick={openAppointmentQuoteModal}>
                        Add a Quote
                      </ActionsStyle>
                      <ActionsStyle
                        variant="contained"
                        sx={{
                          backgroundColor: theme.palette.custom.cyan,
                          ':hover': {
                            backgroundColor: theme.palette.custom.cyan,
                          },
                        }}
                        disabled={disableAllActions}
                        onClick={openAppointmentCallbackModal}>
                        Call / Appt
                      </ActionsStyle>
                      <ActionsStyle
                        variant="contained"
                        color="error"
                        disabled={disableAllActions}
                        onClick={() => setIsOpenUpdateNoshow(true)}>
                        No Show
                      </ActionsStyle>
                      <ActionsStyle
                        variant="contained"
                        onClick={onOpenOtherForm}
                        sx={{
                          backgroundColor: theme.palette.custom.yellow,
                          '&:hover': {
                            backgroundColor: theme.palette.custom.yellow,
                          },
                        }}
                        disabled={disableAllActions}>
                        Other
                      </ActionsStyle>
                      <ActionsStyle
                        variant="contained"
                        sx={{
                          backgroundColor: theme.palette.secondary.main,
                          '&:hover': {
                            backgroundColor: theme.palette.secondary.main,
                          },
                        }}
                        disabled={disableAllActions}
                        onClick={openCreateCardModal}>
                        Add Card
                      </ActionsStyle>
                    </Box>
                  </>
                )}
              <Divider sx={{ mt: 2, mb: 2 }} />
              <TabsContainer
                tabs={apptDetailsTab}
                selectedTab={selectedApptDetailsTab}
                onChangeTab={onChangeDetailTab}
              />
            </>
          )}
        </DialogContent>
        {!isLoadingAppointment && (
          <DialogActions>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: '100%' }}>
              <Typography sx={{ paddingLeft: '1rem' }}>
                Total visit time: {formatTime(time || 0)}
              </Typography>
              <Button onClick={() => onClose(time || 0)}>Cancel</Button>
            </Stack>
          </DialogActions>
        )}
      </Dialog>
      <ConfirmDialog
        isOpen={isOpenConfirm}
        title="Are you sure you want to delete this appointment?"
        onAccept={onDeleteAppointment}
        onReject={() => setIsOpenConfirm(false)}
      />
      <ConfirmDialog
        title="Check appointment as No Show"
        message="Are you sure you want to mark this appointment as no show?"
        onAccept={onNoShowAppointment}
        onReject={() => setIsOpenUpdateNoshow(false)}
        isOpen={isOpenUpdateNoshow}
      />
      {isOpenBookingForm && (
        <BookingForm
          type={bookType}
          person={appointment.person || undefined}
          other_guests={appointment.other_guests || undefined}
          isOpen={isOpenBookingForm}
          onAccept={bookingToEdit ? onUpdateBooking : onCreateBooking}
          onClose={() => setIsOpenBookingForm(false)}
          products={products || []}
          vessels={vessels || []}
          booking={bookingToEdit || undefined}
          haveCards={(appointment.cards && appointment.cards.length > 0) || false}
        />
      )}
      {isOpenCardForm && (
        <CardForm
          room={appointment.person?.room}
          current_cruise_id={appointment.cruise_id}
          isOpen={isOpenCardForm}
          onAccept={onCreateCard}
          onClose={() => setIsOpenCardForm(false)}
        />
      )}
    </Box>
  )
}

export default AppointmentModal
