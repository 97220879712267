import { Alert, Box, Stack, Typography } from '@mui/material'
import { IToggleControlProps } from './types'
import LoadingSwitch from '../LoadingSwitch'

const ToggleControl: React.FC<IToggleControlProps> = ({
  title,
  onLabel,
  offLabel,
  isLoading,
  isChecked,
  onChange,
  message,
  color,
}) => (
  <Stack direction={{ xs: 'column', lg: 'row' }} paddingY={1}>
    <Box textAlign="center" width={{ lg: 250 }}>
      <Typography variant="h6" color="secondary">
        {title}
      </Typography>
      <Stack direction="row" alignItems="center" justifyContent="center">
        <Typography variant="button">{offLabel}</Typography>
        <LoadingSwitch
          isLoading={isLoading}
          isChecked={isChecked}
          onChange={onChange}
          color={color}
        />
        <Typography variant="button">{onLabel}</Typography>
      </Stack>
    </Box>
    <Box display="flex" alignItems="end" width="100%">
      <Alert severity="info" sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Box>
  </Stack>
)

export default ToggleControl
