import { createApi } from '@reduxjs/toolkit/query/react'
import moment from 'moment'
import { baseQuery } from './baseQuery'
import { IGenericResponse } from '../../types/generic'
import {
  IAppointment as IAppointmentById,
  IAppointmentQueueResponse,
  IAppointmentAgentPayload,
  IGetAppointmentsResponse,
  IGetAppointmentsPayload,
  IAppointment,
  IVisitTimePayload,
  IAppointmentStatusUpdatePayload,
  IAppointmentSimple,
  IChangePortPayload,
  IAppointmentOtherPayload,
  IAppointmentOtherResponse,
  IMarkAppointmentAsCallBackPayload,
  IAppointmentOtherEditPayload,
} from '../../types/appointments'

export const appointmentsApi = createApi({
  reducerPath: 'appointmentsApi',
  baseQuery,
  endpoints: (builder) => ({
    appointmentQueue: builder.mutation<IAppointmentQueueResponse, IAppointment>({
      query: (payload) => ({
        url: '/appointments/queue',
        method: 'POST',
        body: payload,
      }),
    }),
    appointmentAgent: builder.mutation<IAppointmentQueueResponse, IAppointmentAgentPayload>({
      query: ({ appointmentId, agent: { id } }) => ({
        url: `/appointments/${appointmentId}/agent/${id}`,
        method: 'POST',
      }),
    }),
    getAppointments: builder.query<IGetAppointmentsResponse, IGetAppointmentsPayload>({
      query: ({ cruise_id, queue_id, status, agent_id, ...payload }) => {
        let url = `/appointments?cruise_id=${cruise_id}&queue_id=${queue_id}&status=${status}`
        if (agent_id) url += `&agent_id=${agent_id}`
        if (status === 'DELETED') url += `&deleted=1`
        if (payload.has_pending_items === true) url += `&has_pending_items=true`
        if (payload.keywordsDate) url += `&keywordsDate=${payload.keywordsDate}`
        if (payload.keywords) url += `&keywords=${payload.keywords}`
        if (payload.filter_type) url += `&filter_type=${payload.filter_type}`
        if (payload.filter_status) url += `&filter_status=${payload.filter_status}`
        return {
          url,
          method: 'GET',
        }
      },
      transformResponse: (response: IGetAppointmentsResponse) => {
        const appointments = response.rows
        if (appointments.length > 0 && appointments[0].status === 'FINISHED') {
          appointments.sort(
            (a, b) => moment(b.queued_on!).valueOf() - moment(a.queued_on!).valueOf(),
          )
        } else {
          appointments.sort(
            (a, b) =>
              a.order_in_line! - b.order_in_line! ||
              moment(b.queued_on!).valueOf() - moment(a.queued_on!).valueOf(),
          )
        }
        return {
          ...response,
          rows: appointments,
        }
      },
    }),
    appointmentCallback: builder.mutation<IAppointmentQueueResponse, IAppointment>({
      query: (payload) => ({
        url: '/appointments/callback',
        method: 'POST',
        body: payload,
      }),
    }),
    getAppointmentById: builder.query<IAppointmentById, number>({
      query: (id) => ({
        url: `/appointments/${id}`,
        method: 'GET',
      }),
    }),
    appointmentVisitTime: builder.mutation<IAppointmentById, IVisitTimePayload>({
      query: ({ id, time }) => ({
        url: `/appointments/${id}/visit-time/${time}`,
        method: 'POST',
      }),
    }),
    appointmentStatus: builder.mutation<IAppointmentQueueResponse, IAppointmentStatusUpdatePayload>(
      {
        query: ({ appointmentId, status }) => ({
          url: `/appointments/${appointmentId}/status/${status}`,
          method: 'PUT',
        }),
      },
    ),
    moveAppointment: builder.mutation<IGenericResponse, IAppointmentSimple[]>({
      query: (payload) => ({
        url: '/appointments/move',
        method: 'POST',
        body: {
          appointments: payload,
        },
      }),
    }),
    noShowAppointment: builder.mutation<IGenericResponse, number>({
      query: (id) => ({
        url: `/appointments/${id}/noshow`,
        method: 'POST',
      }),
    }),
    changeAppointmentPort: builder.mutation<IAppointmentById, IChangePortPayload>({
      query: ({ id, portId }) => ({
        url: `/appointments/${id}/port/${portId}`,
        method: 'PATCH',
      }),
    }),
    delete: builder.mutation<IGenericResponse, number>({
      query: (id) => ({
        url: `/appointments/${id}`,
        method: 'DELETE',
      }),
    }),
    appointmentRestore: builder.mutation<IGenericResponse, number>({
      query: (appointmentId) => ({
        url: `/appointments/${appointmentId}`,
        method: 'PUT',
      }),
    }),
    createOtherResult: builder.mutation<IAppointmentOtherResponse, IAppointmentOtherPayload>({
      query: ({
        appointmentId,
        result,
        comment,
        generalQuestion,
        existingBooking,
        noBookReason,
      }) => ({
        url: `/appointments/${appointmentId}/other`,
        method: 'POST',
        body: { result, comment, generalQuestion, existingBooking, noBookReason },
      }),
    }),

    markAppointmentAsCallback: builder.mutation<
      IGenericResponse,
      IMarkAppointmentAsCallBackPayload
    >({
      query: ({
        appointment_id,
        callback_date,
        callback_time,
        comment,
        inPersonCallback,
        agent_id,
      }) => ({
        url: `/appointments/${appointment_id}/callback`,
        method: 'PATCH',
        body: {
          callback_date,
          callback_time,
          comment,
          inPersonCallback,
          agent_id,
        },
      }),
    }),
    deleteOtherResult: builder.mutation<IAppointmentOtherResponse, IAppointmentOtherEditPayload>({
      query: ({ appointmentId, otherId }) => ({
        url: `/appointments/${appointmentId}/other/${otherId}`,
        method: 'DELETE',
      }),
    }),
    completePendingTask: builder.mutation<IGenericResponse, number>({
      query: (appointmentId) => ({
        url: `/appointments/${appointmentId}/complete-pending-tasks`,
        method: 'POST',
      }),
    }),
  }),
})

export const {
  useAppointmentQueueMutation,
  useAppointmentCallbackMutation,
  useAppointmentAgentMutation,
  useLazyGetAppointmentsQuery,
  useLazyGetAppointmentByIdQuery,
  useAppointmentVisitTimeMutation,
  useAppointmentStatusMutation,
  useMoveAppointmentMutation,
  useNoShowAppointmentMutation,
  useChangeAppointmentPortMutation,
  useDeleteMutation,
  useAppointmentRestoreMutation,
  useCreateOtherResultMutation,
  useMarkAppointmentAsCallbackMutation,
  useDeleteOtherResultMutation,
  useCompletePendingTaskMutation,
} = appointmentsApi
