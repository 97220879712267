export const MAX_INTEGER = 2147483647

export const MAIN_NAV_HEIGHT = 5
export const VOYAGE_BAR_HEIGHT = 5
export const FOOTER_BAR_HEIGHT_PX = 88
export const VOYAGE_INFO_BAR_HEIGHT_PX = 91
export const QUEUE_ID = 1
export const WAIT_MINUTES_BAD = 10
export const WAIT_MINUTES_TOO_BAD = 15

export const bookingBrandData = ['Celebrity', 'Royal', 'Silversea']

export const bookingOriginsData = [
  'Future Cruise Office',
  'The Retreat Lounge',
  'Other Remote Location',
  'Booking Form',
  'Brochure App',
  'Celebrity App',
  'Other',
]

export const guestCategoryData = [
  'Traditional Guest',
  'Casino Trade/Direct​',
  'Casino - Casino',
  'Internal',
  'Crew',
  'Incentive / Other Group',
  'Charter',
  'Ship Tour',
  'Recreate booking prior voyage​',
]

export const stateroomOptions = [
  'Iconic Suite',
  'Reflection Suite',
  'Penthouse Suite',
  'Edge Villa',
  'Royal Suite',
  'Signature Suite',
  'Celebrity Family Suite',
  'Celebrity Suite',
  'Sky Family Suite',
  'Sky Suite',
  'Xpedition Royal Suite - XR',
  'Xpedition Suite - XS',
  'Xpedition Junior Suite - XJ',
  'Aqua Class',
  'Concierge Class',
  'Edge Stateroom',
  'Aqua Class Suites',
  'Sunset Suites',
  'Edge Single Stateroom - ES',
  'Family Veranda',
  'Sunset Veranda',
  'Balcony',
  'Panoramic Ocean View  - IO',
  'Ocean View Stateroom',
  'Elite Ocean View - XE',
  'Xpedition Premium - XP',
  'Xpedition Deluxe - XD',
  'Xpedition Ocean View - XO',
  'Interior Stateroom',
]

export const reasonsWhyData = [
  'Guest not ready to plan ship/sailing date',
  'Guest wanted to wait for New Deployment',
  'Guest wanted to wait for a better price/promotion',
  'No guest interaction / turned in booking form / Used app',
  "Booking for Guest's friends and/or family",
  'Additional booking for Guest',
]

export const currencyOptions = ['USD', 'Euro', 'GBP', 'CAD', 'MXN', 'AUD', 'Other']

export const cardStatusOptions = ['APPROVED', 'PENDING', 'DENIED']

export const reasonOptions = [
  'Price',
  'No availability​',
  'Not Comfortable with NRD',
  'Need to talk to family​',
  'Other',
]

export const reasonBookingCancelled = [
  'Deposit not taken - automatic at voyage termination for all offered bookings',
  'Guest Changed Mind​',
  'Guest not ready to put a deposit​',
  'Rebooked – Wrong currency or deposit​',
]

export const marketingInitiativesTypes = [
  'Celebrity Today Front page card',
  'Celebrity Today Inside Page Card​',
  'Celebrity today insert​',
  'Celebrity Today Takeover​',
  'Celebrity APP',
]
