import { createApi } from '@reduxjs/toolkit/query/react'
import { Buffer } from 'buffer'
import { baseQuery } from './baseQuery'
import { IConfigResponse, ICsrfTokenResponse, IWizardResponse } from '../../types/utils'

export const utilsApi = createApi({
  reducerPath: 'utilsApi',
  baseQuery,
  endpoints: (builder) => ({
    getConfig: builder.query<IConfigResponse, void>({
      query: () => ({
        url: `/utils/config`,
        method: 'GET',
      }),
    }),
    getWizard: builder.query<IWizardResponse, void>({
      query: () => ({
        url: `/utils/wizard`,
        method: 'GET',
      }),
    }),
    getCSRFToken: builder.query<ICsrfTokenResponse, void>({
      query: () => ({
        headers: {
          authorization: `Basic ${Buffer.from(
            `${process.env.REACT_APP_CLIENT_ID}:${process.env.REACT_APP_CLIENT_SECRET}`,
          ).toString('base64')}`,
        },
        url: '/utils/c-token',
        method: 'GET',
      })
    })
  }),
})

export const { useGetConfigQuery, useGetWizardQuery, useGetCSRFTokenQuery } = utilsApi
