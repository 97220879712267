import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from './baseQuery'
import { IArchivePortPayload, IPortPayload } from '../../pages/Settings/Ports/types'
import { IGenericResponse } from '../../types/generic'
import { IPort } from '../../types/ports'

export const portsApi = createApi({
  reducerPath: 'portsApi',
  baseQuery,
  endpoints: (builder) => ({
    createEdit: builder.mutation<IPortPayload[], IPortPayload>({
      query: (payload) => ({
        url: '/ports',
        method: 'POST',
        body: payload,
      }),
    }),
    archive: builder.mutation<IGenericResponse, IArchivePortPayload>({
      query: (payload) => ({
        url: `/ports`,
        method: 'PUT',
        body: payload,
      }),
    }),
    remove: builder.mutation<IGenericResponse, number>({
      query: (id) => ({
        url: `/ports/${id}`,
        method: 'DELETE',
      }),
    }),
    get: builder.query<IPort[], void>({
      query: () => ({
        url: `/ports`,
        method: 'GET',
      }),
    }),
  }),
})

export const { useCreateEditMutation, useArchiveMutation, useRemoveMutation, useGetQuery } =
  portsApi
