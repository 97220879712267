import { useUpdateMutation, useGetByIdQuery } from '../../../redux/api/queues'
import { useCountQuery, useLazyDownloadQuery } from '../../../redux/api/evidences'
import { useGetConfigQuery, useGetCSRFTokenQuery } from '../../../redux/api/utils'
import { IEvidencesDownloadResponse } from '../../../types/evidences'
import { QUEUE_ID } from '../../../utils/constants'
import { EQueueAction } from '../../../types/queues'
import { useAppSelector } from '../../../redux/hooks'

export const UseSettings = () => {
  const user = useAppSelector((state) => state.auth.loggedUser)
  const { data, isLoading } = useGetConfigQuery()
  const { data: counterData, isLoading: isLoadingCounter } = useCountQuery(undefined, { skip: !user })
  const { data: csrfToken, isLoading: isLoadingGetCsrf } = useGetCSRFTokenQuery();
  const [downloadEvidencesTrigger, { isLoading: isDownloading }] = useLazyDownloadQuery()

  const {
    data: queueData,
    isLoading: isLoadingQueue,
    refetch,
  } = useGetByIdQuery(QUEUE_ID, { skip: !user })
  const [invokeUpdateQueue] = useUpdateMutation()

  const downloadEvidences = async (): Promise<IEvidencesDownloadResponse | undefined> => {
    try {
      return await downloadEvidencesTrigger().unwrap()
    } catch (error) {
      console.error(error)
    }
    return undefined
  }

  const updateQueue = async (
    action: EQueueAction,
    onSuccess?: (message: string) => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      const response = await invokeUpdateQueue({ id: QUEUE_ID, action }).unwrap()
      await refetch()
      if (onSuccess) onSuccess(response.message)
    } catch (error) {
      console.error(error)
      if (onError) onError()
    }
  }

  return {
    counterData,
    csrfToken,
    data,
    isDownloading,
    isLoading,
    isLoadingCounter,
    isLoadingGetCsrf,
    isLoadingQueue,
    queueData,
    downloadEvidences,
    updateQueue,
  }
}
