import moment from 'moment'
import { Container, Stack, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import MyLocationIcon from '@mui/icons-material/MyLocation'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { useNavigate } from 'react-router-dom'
import { TimeLocationContainer } from './styles'
import { useGetActiveQuery } from '../../redux/api/cruises'
import { setActiveCruise, setWizard } from '../../redux/features/auth'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { useInterval } from '../../utils/useInterval'
import { ISocketVoyageResponse } from '../../pages/Settings/Voyages/types'
import { SocketContext } from '../../utils/socket'
import notify from '../../utils/notify'
import { useGetWizardQuery } from '../../redux/api/utils'
import { EUserRole } from '../../types/users'
import { theme } from '../../styles/mui-theme'

const TimeAndLocationBar: React.FC = () => {
  const dispatch = useAppDispatch()
  const loggedUser = useAppSelector((state: any) => state.auth.loggedUser)
  const isAgent = loggedUser?.role === EUserRole.AGENT
  const { data: activeCruise, refetch: refetchActiveCruise } = useGetActiveQuery()
  const { data: wizardData, refetch: refetchWizard } = useGetWizardQuery(undefined, {
    skip: isAgent,
  })
  const voyageEnded = moment().diff(moment(activeCruise?.end_date, 'YYYY-MM-DD hh:mm:ss')) > 0
  const voyageNotStarted =
    moment().diff(moment(activeCruise?.start_date, 'YYYY-MM-DD hh:mm:ss')) < 0
  const socket = useContext(SocketContext)

  const navigate = useNavigate()

  useEffect(() => {
    dispatch(setActiveCruise(activeCruise))
  }, [activeCruise])

  const [liveDate, setLiveDate] = useState(moment().format('DD/MMM/YYYY hh:mm:ss A'))

  useInterval(() => {
    setLiveDate(moment().format('hh:mm:ss A - MMM DD'))
  }, 1000)

  const onSocketActiveCruise = (socketData: ISocketVoyageResponse) => {
    if (socketData.userId === loggedUser?.userId) return
    notify.info(socketData.message)
    if (!isAgent) refetchWizard()
    if (!socketData.isActive) {
      dispatch(setActiveCruise(undefined))
      dispatch(setWizard(wizardData))
      navigate('/')
    }
    refetchActiveCruise()
    dispatch(setActiveCruise(activeCruise))
    navigate('/')
  }

  useEffect(() => {
    socket.on('activeCruise', onSocketActiveCruise)
    return () => {
      socket.off('activeCruise', onSocketActiveCruise)
    }
  }, [])

  if (!activeCruise) return null

  return (
    <Container maxWidth="xl" sx={{ paddingTop: 2 }}>
      <TimeLocationContainer elevation={2}>
        <Stack direction="row" justifyContent="space-between" flexWrap="wrap">
          <Stack direction="row" flexWrap="wrap">
            <Stack direction="row" sx={{ pb: 1 }} alignItems="center">
              <DirectionsBoatIcon fontSize="large" sx={{ color: theme.palette.secondary.main }} />
              <Typography
                fontSize={{ xs: '1rem', xl: '1.1rem' }}
                variant="body2"
                noWrap
                sx={{ ml: 1, mr: 2 }}>
                {activeCruise.ship.name} - {activeCruise?.destination.name}
              </Typography>
            </Stack>
            <Stack direction="row" sx={{ pb: 1 }} alignItems="center" flexWrap="wrap">
              <CalendarMonthIcon fontSize="large" sx={{ color: theme.palette.secondary.main }} />
              <Typography
                fontSize={{ xs: '1rem', xl: '1.1rem' }}
                variant="body2"
                noWrap
                sx={{ ml: 1, mr: 2 }}>
                {`${moment.utc(activeCruise.start_date).format('DD/MMM/YYYY')} - ${moment
                  .utc(activeCruise.end_date)
                  .format('DD/MMM/YYYY')}`}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            justifyContent={{ xs: 'flex-start', lg: 'space-between' }}
            flexWrap="wrap">
            {voyageEnded && (
              <Stack direction="row" sx={{ pb: 1 }} alignItems="center">
                <ErrorOutlineIcon fontSize="large" sx={{ color: theme.palette.secondary.main }} />
                <Typography
                  fontSize={{ xs: '1rem', xl: '1.1rem' }}
                  variant="body2"
                  noWrap
                  sx={{ ml: 1, mr: 2 }}>
                  The Voyage has ended
                </Typography>
              </Stack>
            )}
            {voyageNotStarted && (
              <Stack direction="row" sx={{ pb: 1 }} alignItems="center">
                <ErrorOutlineIcon fontSize="large" sx={{ color: theme.palette.secondary.main }} />
                <Typography
                  fontSize={{ xs: '1rem', xl: '1.1rem' }}
                  variant="body2"
                  noWrap
                  sx={{ ml: 1, mr: 2 }}>
                  The Voyage has not started
                </Typography>
              </Stack>
            )}
            {!voyageEnded && !voyageNotStarted && (
              <Stack direction="row" sx={{ pb: 1 }} alignItems="center">
                <MyLocationIcon fontSize="large" sx={{ color: theme.palette.secondary.main }} />
                <Typography
                  fontSize={{ xs: '1rem', xl: '1.1rem' }}
                  variant="body2"
                  noWrap
                  sx={{ ml: 1, mr: 2 }}>
                  {activeCruise.current_port.port_name}
                </Typography>
              </Stack>
            )}
            <Stack direction="row" sx={{ pb: 1 }} alignItems="center">
              <AccessTimeIcon fontSize="large" sx={{ color: theme.palette.secondary.main }} />
              <Typography
                fontSize={{ xs: '1rem', xl: '1.1rem' }}
                variant="body2"
                noWrap
                sx={{ ml: 1 }}>
                {liveDate}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </TimeLocationContainer>
    </Container>
  )
}

export default TimeAndLocationBar
