import { useEffect, useState } from 'react'
import {
  Box,
  Table,
  Typography,
  Paper,
  Stack,
  Divider,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Alert,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import b64ToBlob from 'b64-to-blob'
import fileSaver from 'file-saver'
import moment from 'moment'
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined'
import SettingsIcon from '@mui/icons-material/Settings'
import { useLocation, useNavigate } from 'react-router-dom'
import { UseSettings } from './hook'
import SettingsLayout from '../../../components/SettingsLayout'
import { headTableStyle, secondaryTableRowStyle } from '../../../styles/generic-styles'
import {
  extractHostFromUrl,
  extractPortFromUrl,
  extractProtocolFromUrl,
} from '../../../utils/utils'
import ToggleControl from '../../../components/ToggleControl'
import GridSpinner from '../../../components/GridSpinner'
import { EQueueAction } from '../../../types/queues'
import Notify from '../../../utils/notify'

const Settings = () => {
  // Variable to check location and error messages by redirection
  const location = useLocation()
  const navigate = useNavigate()

  const {
    data,
    isLoading,
    counterData,
    isLoadingCounter,
    downloadEvidences,
    isDownloading,
    queueData,
    isLoadingQueue,
    updateQueue,
  } = UseSettings()

  const [isOpening, setIsOpening] = useState(false)
  const [isEnabling, setIsEnabling] = useState(false)

  const onOpeningClosing = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsOpening(true)
    updateQueue(
      event.target.checked ? EQueueAction.OPEN : EQueueAction.CLOSE,
      () => {
        setIsOpening(false)
      },
      () => {
        setIsOpening(false)
      },
    )
  }

  const onEnablingDisabling = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsEnabling(true)
    updateQueue(
      event.target.checked ? EQueueAction.ENABLE : EQueueAction.DISABLE,
      () => {
        setIsEnabling(false)
      },
      () => {
        setIsEnabling(false)
      },
    )
  }

  const onDownload = async () => {
    const downloadedData = await downloadEvidences()
    if (!downloadedData) return

    const blob = b64ToBlob(downloadedData.zipAsBase64, 'application/zip')
    fileSaver.saveAs(blob, `evidences-${moment.utc().format('YYYY-MM-DD')}.zip`)
  }

  useEffect(() => {
    if (location && location.state && location.state.errorMessage) {
      Notify.error(location.state.errorMessage)
      // Clean state to avoid display error message if user refresh page
      navigate(location.pathname, { replace: true })
    }
  }, [location])

  return (
    <SettingsLayout>
      <Paper>
        <Stack direction="row" alignItems="center" padding={2}>
          <SettingsIcon color="primary" />
          <Typography variant="h5" color="primary" marginLeft={1}>
            Queue/Line Control
          </Typography>
        </Stack>
        <Divider />

        {isLoadingQueue ? (
          <GridSpinner />
        ) : (
          <Box padding={2}>
            <ToggleControl
              title="Status"
              onLabel="Open"
              offLabel="Close"
              onChange={onOpeningClosing}
              isLoading={isOpening}
              isChecked={queueData?.status ?? false}
              color="success"
              message="OPEN allows guests to get in line and ask callbacks. CLOSED allows callbacks only."
            />
            <ToggleControl
              title="Active"
              onLabel="Enabled"
              offLabel="Disabled"
              isChecked={queueData?.active ?? false}
              onChange={onEnablingDisabling}
              isLoading={isEnabling}
              color="success"
              message="DISABLED stops all kind of appointments."
            />
          </Box>
        )}
      </Paper>

      <Paper sx={{ marginTop: 4 }}>
        <Stack direction="row" alignItems="center" padding={2}>
          <SettingsIcon color="primary" />
          <Typography variant="h5" color="primary" marginLeft={1}>
            Book Later - Digital Copies
          </Typography>
        </Stack>
        <Divider />
        <Box padding={2}>
          {isLoadingCounter && <GridSpinner />}

          {!isLoadingCounter && counterData && counterData.count > 0 ? (
            <>
              <Typography marginBottom={1}>
                Download here digital copies of information provided by users in Celebrity brochure
                app.
              </Typography>
              <LoadingButton
                variant="contained"
                color="secondary"
                onClick={onDownload}
                loading={isDownloading}
                startIcon={<GetAppOutlinedIcon />}>
                Download
              </LoadingButton>
            </>
          ) : (
            <Alert severity="info">There are no digital copies to download at this time.</Alert>
          )}
        </Box>
      </Paper>

      <Paper sx={{ marginTop: 4 }}>
        <Stack direction="row" alignItems="center" padding={2}>
          <SettingsIcon color="primary" />
          <Typography variant="h5" color="primary" marginLeft={1}>
            System Information
          </Typography>
        </Stack>
        <Divider />
        <Box padding={2}>
          {isLoading ? (
            <GridSpinner />
          ) : (
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={headTableStyle} colSpan={2}>
                    WEB SETTINGS
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={secondaryTableRowStyle} width={200}>
                    Version
                  </TableCell>
                  <TableCell>{process.env.REACT_APP_VERSION}</TableCell>
                </TableRow>
              </TableBody>

              <TableHead>
                <TableRow>
                  <TableCell sx={headTableStyle} colSpan={2}>
                    API SETTINGS
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={secondaryTableRowStyle}>URL</TableCell>
                  <TableCell>
                    <a href={process.env.REACT_APP_API_URL!}>{process.env.REACT_APP_API_URL}</a>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={secondaryTableRowStyle}>Version</TableCell>
                  <TableCell>{data?.apiVersion}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={secondaryTableRowStyle}>Guest Validation Endpoint</TableCell>
                  <TableCell>{data?.guestValidationEndpoint}</TableCell>
                </TableRow>
              </TableBody>

              <TableHead>
                <TableRow>
                  <TableCell sx={headTableStyle} colSpan={2}>
                    DATABASE SETTINGS
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={secondaryTableRowStyle}>Host</TableCell>
                  <TableCell>{data?.databaseHost}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={secondaryTableRowStyle}>Port</TableCell>
                  <TableCell>{data?.databasePort}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={secondaryTableRowStyle}>Schema</TableCell>
                  <TableCell>{data?.databaseSchema}</TableCell>
                </TableRow>
              </TableBody>

              <TableHead>
                <TableRow>
                  <TableCell sx={headTableStyle} colSpan={2}>
                    KIOSK SETTINGS (For iPads)
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={secondaryTableRowStyle}>Server IP/Host</TableCell>
                  <TableCell>{extractHostFromUrl(process.env.REACT_APP_API_URL!)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={secondaryTableRowStyle}>Port</TableCell>
                  <TableCell>{extractPortFromUrl(process.env.REACT_APP_API_URL!)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={secondaryTableRowStyle}>Protocol</TableCell>
                  <TableCell>
                    {extractProtocolFromUrl(process.env.REACT_APP_API_URL!)
                      ?.replace(':', '')
                      .toUpperCase()}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </Box>
      </Paper>
    </SettingsLayout>
  )
}

export default Settings
