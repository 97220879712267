/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface GeneralState {
  isLoading: boolean
}

const initialState: GeneralState = { isLoading: false }

const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<Pick<GeneralState, 'isLoading'>>) => {
      state.isLoading = action.payload.isLoading
    },
  },
})

export const { setIsLoading } = generalSlice.actions
export default generalSlice.reducer
