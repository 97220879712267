export enum EConfirmType {
  ARCHIVE_PRODUCT,
  REMOVE_PRODUCT,
  RESTORE_PRODUCT,
  ARCHIVE_VESSEL,
  REMOVE_VESSEL,
  RESTORE_VESSEL,
}

export interface IConfirmDialogProps {
  title: string
  message: string
  onAccept: () => void
}

export enum EArchiveOperation {
  ARCHIVE = 'archive',
  RESTORE = 'restore',
}
