import { Navigate } from 'react-router-dom'
import { ProtectedRouteProps } from './types'
import { useAppSelector } from '../../redux/hooks'

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const loginToken: string = useAppSelector((state) => state.auth.token) ?? ''

  if (!loginToken) {
    return <Navigate to="/login" />
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}
