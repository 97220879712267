import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { IVesselFormProps } from './types'
import { validationSchema } from './validations'
import { IShipSimple } from '../../types/ships'

const VesselForm = (props: IVesselFormProps) => {
  const { vessel, isOpen, isLoading, onAccept, onReject } = props
  const isEdit = !!vessel?.id

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<IShipSimple>({
    resolver: yupResolver(validationSchema),
    defaultValues: { ...vessel },
  })

  const title = vessel?.id ? 'Edit Vessel' : 'New Vessel'
  let message = 'Please provide the information below.'
  message += vessel?.id
    ? `Remember the information must be consistent among all vessels.`
    : `Remember the ID must be unique and consistent among all vessels.`

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <Box component="form" onSubmit={handleSubmit(onAccept)} noValidate>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
          <TextField
            autoFocus
            margin="normal"
            fullWidth
            id="id"
            label="ID"
            type="number"
            error={!!errors.id}
            helperText={errors?.id?.message as string}
            {...register('id')}
            disabled={isEdit}
          />
          <TextField
            margin="normal"
            fullWidth
            id="name"
            label="Name"
            error={!!errors.name}
            helperText={errors?.name?.message as string}
            {...register('name', { required: true })}
          />
          <TextField
            margin="normal"
            fullWidth
            id="code"
            label="Code"
            inputProps={{ maxLength: 3 }}
            error={!!errors.code}
            helperText={errors?.code?.message as string}
            {...register('code', { required: true })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onReject}>Cancel</Button>
          <LoadingButton type="submit" loading={isLoading}>
            Save
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default VesselForm
