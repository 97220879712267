import { Paper, styled } from '@mui/material'

export const TimeLocationContainer = styled(Paper)(({ theme }) => ({
  color: theme.palette.custom.darkGray,
  marginBottom: theme.spacing(4),
  marginTop: theme.spacing(8),
  paddingBottom: theme.spacing(2),
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  width: '100%',
  zIndex: 999,
}))
