import {
  useCreateEditMutation as useCreateEditVesselMutation,
  useArchiveMutation as useArchiveVesselMutation,
  useRemoveMutation as useRemoveVesselMutation,
  useGetQuery as useGetVesselsQuery,
} from '../../../redux/api/ships'

import {
  useCreateEditMutation,
  useArchiveMutation,
  useRemoveMutation,
  useGetQuery,
} from '../../../redux/api/destinations'
import { IArchiveShipPayload, IShipPayload } from '../../../types/ships'
import { IArchiveProductPayload } from '../Voyages/types'
import { IDestinationPayload } from '../../../types/destinations'

export const useVessel = () => {
  const [createEditVessel] = useCreateEditVesselMutation()
  const [archiveVessel] = useArchiveVesselMutation()
  const [removeVessel] = useRemoveVesselMutation()
  const {
    isLoading: isLoadingVessels,
    data: vessels,
    refetch: refetchVessels,
  } = useGetVesselsQuery()

  const createEdit = async (
    vessel: IShipPayload,
    onSaved?: (message: string) => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      const response = await createEditVessel(vessel).unwrap()
      await refetchVessels()
      if (onSaved) onSaved(response.message)
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  const archive = async (
    vesselArchive: IArchiveShipPayload,
    onSaved?: (message?: string) => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      const response = await archiveVessel(vesselArchive).unwrap()
      if (onSaved) onSaved(response.message)
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  const remove = async (
    vesselId: number,
    onSaved?: (message?: string) => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      const response = await removeVessel(vesselId).unwrap()
      if (onSaved) onSaved(response.message)
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  return {
    createEdit,
    archive,
    remove,
    isLoadingVessels,
    vessels,
    refetchVessels,
  }
}

export const useProduct = () => {
  const [createEditProduct] = useCreateEditMutation()
  const [archiveProduct] = useArchiveMutation()
  const [removeProduct] = useRemoveMutation()
  const {
    isLoading: isLoadingGetProducts,
    data: products,
    refetch: refetchProducts,
  } = useGetQuery()

  const createEdit = async (
    payload: IDestinationPayload,
    onSaved?: (message: string) => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      const response = await createEditProduct(payload).unwrap()
      await refetchProducts()
      if (onSaved) onSaved(response.message)
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  const archive = async (
    archiveProductPayload: IArchiveProductPayload,
    onSaved?: (message?: string) => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      const response = await archiveProduct(archiveProductPayload).unwrap()
      if (onSaved) onSaved(response.message)
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  const remove = async (
    productId: number,
    onSaved?: (message?: string) => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      const response = await removeProduct(productId).unwrap()
      if (onSaved) onSaved(response.message)
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  return {
    createEdit,
    archive,
    remove,
    products,
    isLoadingGetProducts,
    refetchProducts,
  }
}
