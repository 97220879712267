import {
  Dialog,
  Box,
  DialogTitle,
  IconButton,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TableContainer,
  DialogContent,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useForm, Controller, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import CloseIcon from '@mui/icons-material/Close'
import moment from 'moment'
import { LoadingButton } from '@mui/lab'
import { useEffect, useState } from 'react'
import { IAgentsGoalFormProps, IAgentsGoalFormData } from './types'
import { validationSchema } from './validation'
import { headTableStyle } from '../../styles/generic-styles'
import { MAX_INTEGER } from '../../utils/constants'

const AgentsDailyGoalForm: React.FC<IAgentsGoalFormProps> = ({
  isOpen,
  agent,
  goals,
  isLoading,
  onClose,
  onSubmit,
}) => {
  const { handleSubmit, control, setValue } = useForm<IAgentsGoalFormData>({
    defaultValues: {
      agentGoals: goals,
    },
    resolver: yupResolver(validationSchema),
  })

  const watchedGoals = useWatch({ control, name: 'agentGoals' })

  const [total, setTotal] = useState(() =>
    Object.values(watchedGoals).reduce((sum, item) => sum + (Number(item.goal) || 0), 0),
  )

  useEffect(() => {
    const totalGoals = Object.values(watchedGoals).reduce(
      (sum, item) => sum + (Number(item.goal) || 0),
      0,
    )
    setTotal(totalGoals)
  }, [watchedGoals])

  // Update form values on mount
  useEffect(() => {
    goals.forEach((item, index) => {
      setValue(`agentGoals.${index}.agent_port_id`, item.agent_port_id)
      setValue(`agentGoals.${index}.port_id`, item.port_id)
      setValue(`agentGoals.${index}.arrival_date`, item.arrival_date)
    })
  }, [goals, setValue])

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          Set Daily Goals
          <Typography
            variant="h6"
            color="secondary">{`${agent.first_name} ${agent.last_name}`}</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
          <TableContainer sx={{ marginBottom: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center" sx={headTableStyle}>
                    Date
                  </TableCell>
                  <TableCell align="left" sx={headTableStyle}>
                    Port
                  </TableCell>
                  <TableCell
                    colSpan={2}
                    align="center"
                    sx={{
                      ...headTableStyle,
                      paddingRight: '18%',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}>
                    Goals
                    <Typography fontSize="16"> Total: {total}</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {goals.map((item, index) => (
                  <TableRow key={`${item.port_id} ${item.arrival_date}`}>
                    <TableCell align="center">
                      {moment(item.arrival_date).format('MM/DD/YYYY')}
                    </TableCell>
                    <TableCell align="left">{item.port_name}</TableCell>
                    <TableCell align="left">
                      <Controller
                        control={control}
                        name={`agentGoals.${index}.goal`}
                        defaultValue={item.goal || undefined}
                        render={({ field }) => (
                          <TextField
                            type="number"
                            InputProps={{
                              inputProps: { max: MAX_INTEGER, min: 0 },
                            }}
                            size="small"
                            {...field}
                          />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack direction="row" justifyContent="flex-end">
            <LoadingButton type="submit" variant="contained" loading={isLoading}>
              Save
            </LoadingButton>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default AgentsDailyGoalForm
