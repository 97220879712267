import { useParams } from 'react-router-dom'
import { saveAs } from 'file-saver'
import {
  useGetCruiseCardReportQuery,
  useLazyGetExcelCardReportQuery,
} from '../../../redux/api/reports'

export const useCardReport = () => {
  const { id } = useParams()
  const { isLoading, data, refetch: refetchGetReport } = useGetCruiseCardReportQuery(Number(id))
  const [getReport, { isFetching: isFetchingDownload }] = useLazyGetExcelCardReportQuery()

  const onDownloadExcel = async (cruiseId: number): Promise<void> => {
    const response = await getReport(cruiseId).unwrap()
    saveAs(`data:application/vnd.ms-excel;base64,${response.file}`, response.fileName)
  }

  return { isLoading, data, onDownloadExcel, isFetchingDownload, refetchGetReport }
}
