import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from './baseQuery'
import { IArchiveProductPayload } from '../../pages/Settings/Voyages/types'
import { IGenericResponse } from '../../types/generic'
import { IDestination, IDestinationPayload } from '../../types/destinations'

export const destinationsApi = createApi({
  reducerPath: 'destinationsApi',
  baseQuery,
  endpoints: (builder) => ({
    createEdit: builder.mutation<IGenericResponse, IDestinationPayload>({
      query: (payload) => ({
        url: '/destinations',
        method: 'POST',
        body: payload,
      }),
    }),
    archive: builder.mutation<IGenericResponse, IArchiveProductPayload>({
      query: ({ id, action }) => ({
        url: `/destinations/${id}/${action}`,
        method: 'PUT',
      }),
    }),
    remove: builder.mutation<IGenericResponse, number>({
      query: (id) => ({
        url: `/destinations/${id}`,
        method: 'DELETE',
      }),
    }),
    get: builder.query<IDestination[], void>({
      query: () => ({
        url: `/destinations`,
        method: 'GET',
      }),
    }),
  }),
})

export const { useCreateEditMutation, useArchiveMutation, useRemoveMutation, useGetQuery } =
  destinationsApi
