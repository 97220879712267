export interface IConfirmDialogProps {
  title: string
  message: string
  onAccept: () => void
}

export interface IPortPayload {
  port_id: number
  name: string
  timezone: string
  timezoneOffset: string
  isEdit: boolean
}

export interface IArchivePortPayload {
  port_id: number
  isDeprecated: boolean
}

export enum EConfirmPortType {
  ARCHIVE_PORT,
  REMOVE_PORT,
  RESTORE_PORT,
}
