import { Box, CircularProgress, Typography } from '@mui/material'
import { IGridSpinnerProps } from './types'

const GridSpinner: React.FC<IGridSpinnerProps> = ({ spinnerSize }) => (
  <Box
    sx={{
      minHeight: 300,
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
    <CircularProgress variant="indeterminate" size={spinnerSize ?? '6rem'} />
    <Typography
      variant="button"
      component="div"
      color="text.secondary"
      sx={{
        marginTop: '1rem',
        textAlign: 'center',
      }}>
      Loading
    </Typography>
  </Box>
)

export default GridSpinner
