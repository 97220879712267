import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ILoginPayload, ILoginResponse } from '../../pages/Login/types'

export const loginApi = createApi({
  reducerPath: 'loginApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  endpoints: (builder) => ({
    doLogin: builder.mutation<ILoginResponse, ILoginPayload>({
      query: (payload) => ({
        url: '/users/login',
        headers: {
          "xsrf-token": payload.csrfToken,
        },
        method: 'POST',
        body: {
          username: payload.username,
          password: payload.password,
        },
      }),
    }),
  }),
})

export const { useDoLoginMutation } = loginApi
