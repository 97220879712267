import { Navigate } from 'react-router-dom'
import { CustomRouteProps } from './types'
import Layout from '../Layout'
import { ProtectedRoute } from '../ProtectedRoute'
import { EUserRole } from '../../types/users'
import { useAppSelector } from '../../redux/hooks'
import { UseSettings } from '../../pages/Settings/Settings/hook'

const CustomRoute: React.FC<CustomRouteProps> = ({
  adminOnly,
  component,
  isProtected,
  queueActiveOnly = false,
  superAdminOnly,
}) => {
  const user = useAppSelector((state) => state.auth.loggedUser)
  const { queueData } = UseSettings()
  const isAgent = user && ![EUserRole.SUPER_ADMIN, EUserRole.ADMIN].includes(user?.role)
  const isManager = user && EUserRole.ADMIN === user?.role

  if (isAgent && (adminOnly || superAdminOnly)) {
    return (
      <Navigate
        to="/appointments"
        replace
        state={{
          errorMessage: "You don't have access to enter to this page. Contact an administrator.",
        }}
      />
    )
  }

  if (isManager && superAdminOnly) {
    return (
      <Navigate
        to="/settings"
        state={{
          errorMessage:
            "You don't have access to enter to this page. Contact a super administrator.",
        }}
        replace
      />
    )
  }

  if (queueData && !queueData?.active && queueActiveOnly) {
    if (!isAgent) {
      return (
        <Navigate
          to="/settings"
          replace
          state={{
            errorMessage: 'Please active queue.',
          }}
        />
      )
    }
    return (
      <Navigate
        to="/"
        replace
        state={{
          errorMessage: 'Queue is not enabled. Please contact an administrator.',
        }}
      />
    )
  }

  const element = isProtected ? (
    <ProtectedRoute>
      <Layout component={component} isProtected={isProtected} />
    </ProtectedRoute>
  ) : (
    <Layout component={component} isProtected={false} />
  )
  return element
}

export default CustomRoute
