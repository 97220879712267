import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import Switch from '@mui/material/Switch'
import { ILoadingSwitchProps } from './types'

const LoadingSwitch: React.FC<ILoadingSwitchProps> = ({
  isLoading,
  isChecked,
  isDisabled = false,
  onChange,
  color,
}) => (
  <Box width={60} height={40} display="flex" justifyContent="center" alignItems="center">
    {isLoading ? (
      <CircularProgress size={20} />
    ) : (
      <Switch checked={isChecked} onChange={onChange} disabled={isDisabled} color={color} />
    )}
  </Box>
)

export default LoadingSwitch
