import { combineReducers, configureStore, isRejectedWithValue, Middleware } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/dist/query'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { pokemonApi } from './api/pokemon'
import { usersApi } from './api/users'
import { loginApi } from './api/login'
import GeneralReducer from './features/general'
import AuthReducer from './features/auth'
import { utilsApi } from './api/utils'
import { destinationsApi } from './api/destinations'
import { portsApi } from './api/ports'
import { mailDropApi } from './api/mailDrop'
import { shipsApi } from './api/ships'
import { cruisesApi } from './api/cruises'
import { statsApi } from './api/stats'
import { reportsApi } from './api/reports'
import { queuesApi } from './api/queues'
import { evidencesApi } from './api/evidences'
import { eventsApi } from './api/events'
import { personsApi } from './api/persons'
import { appointmentsApi } from './api/appointments'
import { bookingsApi } from './api/bookings'
import { cardsApi } from './api/cards'
import { quotesApi } from './api/quotes'
import { marketingInitiativesApi } from './api/marketingInitiatives'
import { conversionSettingsApi } from './api/conversionSettings'
import Notify from '../utils/notify'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
}

const rootReducer = combineReducers({
  general: GeneralReducer,
  auth: AuthReducer,
  [loginApi.reducerPath]: loginApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [pokemonApi.reducerPath]: pokemonApi.reducer,
  [destinationsApi.reducerPath]: destinationsApi.reducer,
  [portsApi.reducerPath]: portsApi.reducer,
  [shipsApi.reducerPath]: shipsApi.reducer,
  [cruisesApi.reducerPath]: cruisesApi.reducer,
  [statsApi.reducerPath]: statsApi.reducer,
  [mailDropApi.reducerPath]: mailDropApi.reducer,
  [reportsApi.reducerPath]: reportsApi.reducer,
  [utilsApi.reducerPath]: utilsApi.reducer,
  [queuesApi.reducerPath]: queuesApi.reducer,
  [evidencesApi.reducerPath]: evidencesApi.reducer,
  [eventsApi.reducerPath]: eventsApi.reducer,
  [personsApi.reducerPath]: personsApi.reducer,
  [appointmentsApi.reducerPath]: appointmentsApi.reducer,
  [cardsApi.reducerPath]: cardsApi.reducer,
  [bookingsApi.reducerPath]: bookingsApi.reducer,
  [quotesApi.reducerPath]: quotesApi.reducer,
  [marketingInitiativesApi.reducerPath]: marketingInitiativesApi.reducer,
  [conversionSettingsApi.reducerPath]: conversionSettingsApi.reducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const {
      payload: { data },
    } = action

    if (!data) {
      Notify.error(`Unexpected server error.`)
    } else {
      const { success, message } = data
      if (!success && message) Notify.error(message)
      else Notify.error(`Unexpected server error. No technical details.`)
    }
  }

  return next(action)
}

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      rtkQueryErrorLogger,
      pokemonApi.middleware,
      loginApi.middleware,
      usersApi.middleware,
      destinationsApi.middleware,
      portsApi.middleware,
      shipsApi.middleware,
      cruisesApi.middleware,
      statsApi.middleware,
      mailDropApi.middleware,
      reportsApi.middleware,
      utilsApi.middleware,
      queuesApi.middleware,
      evidencesApi.middleware,
      eventsApi.middleware,
      cruisesApi.middleware,
      personsApi.middleware,
      appointmentsApi.middleware,
      bookingsApi.middleware,
      cardsApi.middleware,
      quotesApi.middleware,
      marketingInitiativesApi.middleware,
      conversionSettingsApi.middleware,
    ]),
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export const persistor = persistStore(store)
