import { useCreateMutation, useGetQuery, useRemoveMutation } from '../../../redux/api/mailDrop'
import { useAppSelector } from '../../../redux/hooks'
import { IGenericResponse } from '../../../types/generic'

export const useMailDrop = () => {
  const cruiseId = useAppSelector((state) => state.auth.activeCruise?.cruise_id || 0)

  const { data, isLoading, refetch } = useGetQuery(cruiseId, { skip: !cruiseId })
  const [invokeRemoveMailDrop] = useRemoveMutation()
  const [invokeCreateMailDrop] = useCreateMutation()

  const removeMailDrop = async (
    fileName: string,
    onSaved?: (message: string) => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      const response: IGenericResponse = await invokeRemoveMailDrop({
        fileName,
        cruiseId,
      }).unwrap()
      if (onSaved) onSaved(response.message)
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  const createMailDrop = async (
    mailDropData: any,
    onSaved?: (message: string) => void,
    onError?: () => void,
  ): Promise<void> => {
    try {
      const response: IGenericResponse = await invokeCreateMailDrop(mailDropData).unwrap()
      if (onSaved) onSaved(response.message)
    } catch (error) {
      console.error('Exception invoking API', error)
      if (onError) onError()
    }
  }

  return { isLoading, data, removeMailDrop, refetch, createMailDrop }
}
