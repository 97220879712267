import * as yup from 'yup'

export const validationSchema = yup.object().shape({
  result: yup.string().required('Result is required'),
  generalQuestion: yup
    .string()
    .test('generalQuestion-required', 'Option is required', (generalQuestion, context) => {
      const { result } = context.parent
      return (
        result !== 'GENERALFUTURECRUISEQUESTION' ||
        (result === 'GENERALFUTURECRUISEQUESTION' && generalQuestion !== '')
      )
    }),
  existingBooking: yup
    .string()
    .test('existingBooking-required', 'Option is required', (existingBooking, context) => {
      const { result } = context.parent
      return (
        result !== 'EXISTINGBOOKING' || (result === 'EXISTINGBOOKING' && existingBooking !== '')
      )
    }),
})
