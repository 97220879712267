import Stack from '@mui/material/Stack'
import Chip from '@mui/material/Chip'
import { IAppointment } from '../../types/appointments'
import { theme } from '../../styles/mui-theme'

const PreviousColumn: React.FC<{ appointment: IAppointment }> = ({ appointment }) => {
  const appointmentId = appointment.appointment_id

  const bookingsCounter: number = appointment.bookings
    ? appointment.bookings.filter((item) => item.appointment_id !== appointmentId).length
    : 0
  const quotesCounter = appointment.quotes
    ? appointment.quotes.filter((item) => item.appointment_id !== appointmentId).length
    : 0

  return (
    <Stack direction="row" spacing={1}>
      {bookingsCounter > 0 ? (
        <Chip
          label={bookingsCounter}
          sx={{ backgroundColor: theme.palette.primary.light, color: 'white' }}
        />
      ) : null}
      {quotesCounter > 0 ? (
        <Chip
          label={quotesCounter}
          sx={{ backgroundColor: theme.palette.custom.yellow, color: 'white' }}
        />
      ) : null}
      {appointment.events && appointment.events > 0 ? (
        <Chip
          label={appointment.events}
          sx={{ backgroundColor: theme.palette.success.main, color: 'white' }}
        />
      ) : null}
      {appointment.mailDrop && appointment.mailDrop > 0 ? (
        <Chip
          label={appointment.mailDrop}
          sx={{ backgroundColor: theme.palette.error.main, color: 'white' }}
        />
      ) : null}
      {appointment.cards && appointment.cards.length > 0 ? (
        <Chip label="V" sx={{ backgroundColor: theme.palette.secondary.main, color: 'white' }} />
      ) : null}
    </Stack>
  )
}

export default PreviousColumn
