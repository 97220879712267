import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { IConfirmDialogProps } from './types'

const ConfirmDialog: React.FC<IConfirmDialogProps> = ({
  title,
  message,
  acceptLabel = 'Yes',
  rejectLabel = 'No',
  isOpen = false,
  onAccept,
  onReject,
}) => (
  <Dialog open={isOpen}>
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    {message && (
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
      </DialogContent>
    )}
    <DialogActions>
      <Button onClick={onReject}>{rejectLabel}</Button>
      <Button onClick={onAccept} autoFocus>
        {acceptLabel}
      </Button>
    </DialogActions>
  </Dialog>
)

export default ConfirmDialog
